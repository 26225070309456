import React, { useState } from "react";
import Navbar from "../components/Navbar";
import MobileMenu from "../components/MobileMenu";
import Sidebar from "../components/Sidebar";

const DashboardLayout = ({ children }) => {
  const colour = { colour: "#FE4747", backgroundcolour: "#FF6B6B29" };
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);
  return (
    <div className="flex w-full">
     
      {isSidebarOpen && (
        <div
          className={`h-fit bg-transparent z-30 ${
            isSidebarOpen
              ? "block absolute left-0 top-5 lg:top-0 lg:relative w-full md:w-[45%] lg:w-[15%]"
              : "hidden absolute left-0 top-20 lg:relative w-full lg:w-[15%]"
          }`}
        >
          <MobileMenu toggleSidebar={toggleSidebar} colour={colour} />
        </div>
      )}

      <div
        className={`h-full bg-white hidden lg:block absolute left-0 top-0 lg:top-0 lg:relative w-full md:w-[15%]`}
      >
        <Sidebar colour={colour} />
      </div>

      <div
        className={`w-full lg:w-[85%]  h-screen overflow-y-auto bg-[#F3F3F3] ${
          isSidebarOpen ? "" : "ml-0"
        }`}
      >
        <Navbar toggleSidebar={toggleSidebar} />
        {children}
      </div>
    </div>
  );
};

export default DashboardLayout;
