import React, { useState } from "react";
import { GoArrowUp } from "react-icons/go";
import { Link } from "react-router-dom";
import { FaSquareFacebook } from "react-icons/fa6";
import { IoLogoLinkedin } from "react-icons/io5";
import { RiInstagramFill } from "react-icons/ri";
import { FaArrowRight } from "react-icons/fa6";
import { toast } from "react-toastify";
import axios from "axios";
import { base_URL } from "../../config/config";

const Footer = ({ onScrollDownClick }) => {
  const [email, setEmail] = useState("");

  const handleSubscribe = async () => {
    if (email.trim() === "") {
      toast.error("Please enter a valid email.");
      return;
    }
    try {
      const response = await axios.post(`${base_URL}/api/newsletter`, {
        email,
      });
      if (response.data.success) {
        toast.success("Subscribed successfully! Thank you for signing up.");
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again later.");
    }

    // Clear the input field
    setEmail("");
  };

  return (
    <div className="bg-[#222222] sm:p-16 p-4 py-20 ">
      <div className="text-white flex justify-between md:flex-row flex-col gap-10 items-center">
        <div>
          <h1 className="sm:text-3xl text-2xl mb-2">Newsletter Signup</h1>
          <p>Get the latest news and offers with our Registration</p>
        </div>
        <div className=" sm:pl-5 pl-2 sm:text-base text-xs flex lg:flex-1 lg:w-full md:w-[65%] w-full items-center justify-between bg-[#505050]">
          <input
            type="text"
            className=" bg-transparent lg:w-[35%] w-[50%] outline-none border-b pb-2 border-[#ababab] placeholder:text-gray-400"
            placeholder="yourname@email.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)} // Update state when the user types
          />
          <button
            onClick={handleSubscribe} // Handle subscribe click
            className="flex items-center gap-2 lg:w-[30%} w-[40%] lg:px-0 px-3 py-4 text-center sm:whitespace-normal whitespace-nowrap justify-center bg-[#727272]"
          >
            Subscribe Now <FaArrowRight className="w-10" />
          </button>
        </div>
      </div>
      <div className="flex lg:flex-row flex-col lg:gap-0 mt-12 gap-10 justify-between items-start">
        <div className="lg:w-[35%] w-full text-white">
          <Link to="/home">
            <img src="/footerha.svg" alt="" className="w-40" />
          </Link>
          <div className="flex flex-col items-start gap-8">
            <div className="mt-8 space-y-3">
              <hr className="w-20" />
              <p>You can reach us at :</p>
              <p className="cursor-pointer">
                <a
                  href="mailto:support@findmyvenue.com"
                  className="text-blue-500 underline"
                >
                  support@findmyvenue.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="lg:w-[60%] w-full grid sm:grid-cols-4 grid-cols-2 text-white gap-10">
          <div>
            <h1 className="text-lg font-medium">Users</h1>
            <p className="mt-2">Explore Venues</p>
            <p className="mt-2">How it works?</p>
            <Link to="/login">
              <p className="mt-2">User Login</p>
            </Link>
            <div className="mt-2">
              <Link to="/faqs">FAQs</Link>
            </div>
          </div>
          <div>
            <h1 className="text-lg font-medium">Venues</h1>
            <Link to={"/list-venues"} className="mt-2">
              Why list with us
            </Link>
            <Link to={"/login"}>
              <p className="mt-2">Venue Login</p>
            </Link>
            <div className="mt-2">
              <Link to="/faqs">Venue FAQs</Link>
            </div>
          </div>
          <div>
            <h1 className="text-lg font-medium">Findmyvenue</h1>
            <div className="block mt-2">About Us</div>
            <div className="block mt-2">
              <Link to="/terms&conditions">Terms & Conditions</Link>
            </div>
            <div className="block mt-2">
              <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
            <div className="block mt-2">Blogs</div>
            <div className="block mt-2">Careers</div>
            <div className="block mt-2">
              <Link className="block" to={"/contact"}>
                Contact Us
              </Link>
            </div>
          </div>

          <div>
            <h1 className="text-lg font-medium">Follow Us On</h1>
            <div className="flex flex-col gap-3 mt-2 text-white ">
              <Link to="https://www.facebook.com/61566486906523">
                <div className="flex gap-x-3">
                  <FaSquareFacebook className="text-xl" />
                  Facebook
                </div>
              </Link>
              <Link to="https://www.instagram.com/findmyvenuehq">
                <div className="flex gap-x-3">
                  <RiInstagramFill className="text-xl" />
                  Instagram
                </div>
              </Link>
              <Link to="https://www.linkedin.com/company/find-my-venue-uae">
                <div className="flex gap-x-3">
                  <IoLogoLinkedin className="text-xl" />
                  LinkedIn
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-20 gap-4">
        <p className="text-white font-thin ">
          © 2024 Find My Venue. All rights reserved. |{" "}
          <Link to={"/privacy-policy"}>Privacy</Link> |{" "}
          <Link to={"/terms&conditions"}>T & C</Link> |{" "}
          <Link to={"/cancellation-policy"}>Cancellation Policy</Link>
        </p>
        <div
          onClick={onScrollDownClick}
          className="p-3 border cursor-pointer rounded-md text-white"
        >
          <GoArrowUp className="text-white" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
