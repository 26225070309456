/* eslint-disable no-unused-vars */
"use client";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "../../../layout/dashboard-layout";
import CurrentSubscription from "./CurrentSubscription";
import SubscriptionsPlans from "./SubscriptionsPlans";
import BillingInformation from "./BillingInformation";
import { fetchLoggedUser, fetchUserById } from "../../../api/loggedUser";
import ModifyMyVenues from "../../myVenues/ModifyMyVenues";
import { useUser } from "../../../context/user";

export default function MySubscriptions() {
  // const [showVenues, setShowVenues] = useState(false);
  // const [editBilling, setEditBilling] = useState(false);
  const { editBilling, setEditBilling, showVenues, setShowVenues } = useUser();

  const [user, setuser] = useState(null);
  const [userAmount, setUserAmount] = useState(0);
  // for superadmin user vendorId directly
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");

  // invited by is super admin basically
  const fetchUser = async () => {
    try {
      let response;
      if (invitedBy) {
        // Fetch the super admin based on invitedBy ID
        response = await fetchUserById(vendorId);
      } else {
        // Fetch the logged-in vendor as the super admin
        response = await fetchLoggedUser();
      }
      setuser(response.vendor || response.user);
      setUserAmount(response?.vendor?.userAmount);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchUser();
  }, []);

  // Check if onBoardingDetails exists before accessing its properties
  const { onBoardingDetails, history } = user || {};

  // Count the occurrences of "Restaurant w/Alcohol" and "Restaurant w/o Alcohol"
  const countWithAlcohol = history?.filter((entry) =>
    entry.includes("Restaurant w/Alcohol")
  ).length;
  const countWithoutAlcohol = history?.filter((entry) =>
    entry.includes("Restaurant w/o Alcohol")
  ).length;

  const withAlcoholNumber = onBoardingDetails?.withAlcoholNumber || 0;
  const withoutAlcoholNumber = onBoardingDetails?.withoutAlcoholNumber || 0;

  const items = onBoardingDetails?.items || [];

  // Decrement the original numbers based on counts from history
  const adjustedWithAlcoholNumber =
    Number(withAlcoholNumber) - Number(countWithAlcohol);
  const adjustedWithoutAlcoholNumber =
    Number(withoutAlcoholNumber) - Number(countWithoutAlcohol);

  // Pricing logic for "with alcohol"
  let rateWithAlcohol = 0;
  if (adjustedWithAlcoholNumber === 1) {
    rateWithAlcohol = 1500;
  } else if (adjustedWithAlcoholNumber >= 2 && adjustedWithAlcoholNumber <= 5) {
    rateWithAlcohol = 1300;
  } else if (adjustedWithAlcoholNumber > 5) {
    rateWithAlcohol = 1200;
  }

  // Pricing logic for "without alcohol"
  let rateWithoutAlcohol = 0;
  if (adjustedWithoutAlcoholNumber === 1) {
    rateWithoutAlcohol = 1000;
  } else if (
    adjustedWithoutAlcoholNumber >= 2 &&
    adjustedWithoutAlcoholNumber <= 5
  ) {
    rateWithoutAlcohol = 900;
  } else if (adjustedWithoutAlcoholNumber > 5) {
    rateWithoutAlcohol = 800;
  }

  // Pricing logic for "items"
  let totalItems = 0;
  if (Array.isArray(items)) {
    if (
      user?.onBoardingDetails?.selectedChips?.length === 1 &&
      user?.onBoardingDetails?.selectedChips[0] === "Kids Play Area"
    ) {
      // Charge 1000 for each item when only "Kids Play Area" is selected, regardless of 'yes' or 'no'
      totalItems = items.reduce((acc, item) => {
        return acc + 1000 * item.amount;
      }, 0);
    } else {
      // Apply the existing pricing logic if multiple items or not "Kids Play Area"
      totalItems = items.reduce((acc, item) => {
        const rate = item.answer == "yes" ? 2000 : 1000; // Multi-case (yes) or single-case (no)
        return acc + rate * item.amount;
      }, 0);
    }
  }

  // Add logic for hotel + stays if applicable
  let hotelStaysTotal = 0;
  if (user?.onBoardingDetails?.type === "HotelStayandVenue") {
    hotelStaysTotal = 2000;
  }

  // Calculate totals (ensure that undefined or null values are treated as 0)
  const totalWithAlcohol = rateWithAlcohol * adjustedWithAlcoholNumber || 0;
  const totalWithoutAlcohol =
    rateWithoutAlcohol * adjustedWithoutAlcoholNumber || 0;
  const subTotal =
    totalWithAlcohol + totalWithoutAlcohol + totalItems + hotelStaysTotal;
  const vat = subTotal * 0.05;
  const grandTotalinitial = subTotal + vat;

  // Apply user's special discount if available
  const discountPercentage = user?.specialDiscount || 0;
  const discountAmount = (grandTotalinitial * discountPercentage) / 100;
  const grandTotal = grandTotalinitial - discountAmount;

  const handleMainSub = () => {
    setShowVenues(false);
    setEditBilling(false);
  };
  return (
    <DashboardLayout>
      <div className="flex w-full py-4 justify-center font-outfit">
        <div className="w-[90%] flex flex-col gap-5">
          {/* Breadcrumb Navigation */}
          <div className="flex w-full justify-between items-center">
            <div className="flex flex-col gap-3">
              <h2 className="text-xl font-medium md:text-2xl md:font-semibold">
                Manage Subscription
              </h2>
              <div className="flex items-center gap-2">
                <Link to="/dashboard" className="text-[#FE4747]">
                  Dashboard
                </Link>{" "}
                {">"}{" "}
                <Link
                  to="/mysubscriptions"
                  onClick={handleMainSub}
                  className="text-[#FE4747]"
                >
                  Subscription
                </Link>{" "}
                {editBilling && (
                  <p>
                    <span>{">"}</span> Billing Information
                  </p>
                )}
              </div>
            </div>
          </div>
          {showVenues && <ModifyMyVenues refetchUser={fetchUser} />}

          {/* dont show below if modify plan is active */}
          {!showVenues && (
            <div className="flex flex-col">
              {editBilling ? (
                <>
                  <div className="w-full xl:w-[939px] mx-auto">
                    {/* Billing Information Section */}
                    <h3 className="text-[22px] lg:text-[32px] font-semibold mb-3">
                      Billing Information
                    </h3>
                    <BillingInformation
                      user={user}
                      setEditBilling={setEditBilling}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="w-full xl:w-[939px] mx-auto">
                    {/* Current Subscription Section */}
                    <h3 className="text-[22px] lg:text-[32px] font-semibold mb-3">
                      Current Subscription
                    </h3>
                    <CurrentSubscription
                      setEditBilling={setEditBilling}
                      user={user}
                      grandTotal={grandTotal}
                      refetchUser={fetchUser}
                      setShowVenues={setShowVenues}
                    />

                    {/* Subscription Plans or previous plan Section */}
                    <h3 className="text-[22px] lg:text-[32px] font-semibold mb-2 mt-6">
                      {user?.paymentStatus === "Cancelled" ? (
                        <>Previous Plan</>
                      ) : user?.isModified ? (
                        <>Modified Plan</>
                      ) : (
                        <>Subscription Plans</>
                      )}
                    </h3>
                    <SubscriptionsPlans
                      user={user}
                      discountPercentage={discountPercentage}
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}
