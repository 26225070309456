/* eslint-disable no-unused-vars */
export const validateBasicForm = (formData, setFormData) => {
  const basicDetails = formData?.basicDetails || {};
  const errors = {};

  // Basic Details Validation
  if (!basicDetails?.venueName.trim()) {
    errors.venueName = "Venue name is required";
  }

  if (!basicDetails?.hotelName.trim()) {
    errors.hotelName = "Required";
  }
  const text = basicDetails?.venueDescription;
  const words = text.trim().split(/\s+/); // Regular expression to handle multiple spaces
  const wordCount = words.length;

  // Check if the word count is within the valid range

  if (!basicDetails?.venueDescription || wordCount < 100) {
    errors.venueDescription = "Write atleast 100 words";
  }
  if (wordCount > 600) {
    errors.venueDescription = "Maximum word count exceeded";
  }

  // if (wordCount < 100 || wordCount > 600) {
  //   errors.venueDescription = "Venue description must be between 100 and 600 words";
  // }
  // Update the formData with validation errors
  setFormData((prevData) => ({
    ...prevData,
    basicDetails: {
      ...prevData?.basicDetails,
      errors,
    },
  }));

  return Object.keys(errors).length === 0;
};

export const validateAdditionForm = (formData, setFormData) => {
  const additionalDetails = formData?.additionalDetails || {};
  const errors = {};

  // Validate Venue Style
  const venueView = additionalDetails.venueView || {};
  const totalVenue = Object.values(venueView).flat().length;

  if (totalVenue < 1) {
    errors.venueView = "You must select at least 1 venue View .";
  }

  const venueStyle = additionalDetails.venueStyle || {};
  const totalVenueStyles = Object.values(venueStyle).flat().length;

  if (totalVenueStyles < 1) {
    errors.venueStyle = "You must select at least 1 venue styles.";
  }
  // venue setting
  const venueSetting = additionalDetails.venueSetting || {};
  const totalVenueSetting = Object.values(venueSetting).flat().length;

  if (totalVenueSetting < 1) {
    errors.venueSetting = "You must select at least 1 venue setting.";
  }
  // Validate Venue Hire
  const venueHire = additionalDetails.venueHire || "";
  if (!venueHire) {
    errors.venueHire = "You must select a venue hire option.";
  }

  // Update the form data with errors
  setFormData((prevData) => ({
    ...prevData,
    additionalDetails: {
      ...prevData.additionalDetails,
      errors,
    },
  }));

  return Object.keys(errors).length === 0;
};

export const valiVenuedateTypeForm = (formData, setFormData) => {
  const additionalDetails = formData?.venueType || {};
  const errors = {};

  //   venueType dropdown
  const dropDown = additionalDetails?.venueRepresent;
  // Validate Venue Style
  const venueType1 = additionalDetails.venueType || {};
  const totalVenue = Object.values(venueType1).flat().length;

  if (totalVenue < 1) {
    errors.venueType = "You must select at least 1 venue Type .";
  }
  if (dropDown == "") {
    errors.primaryType = "You must select at least 1 primary Type .";
  }

  // Update the form data with errors
  setFormData((prevData) => ({
    ...prevData,
    venueType: {
      ...prevData.venueType,
      errors,
    },
  }));

  return Object.keys(errors).length === 0;
};

export const validateEventTypeForm = (formData, setFormData) => {
  const eventDetails = formData?.eventType || {};
  const errors = {};

  const isEventDetailsValid = (eventDetails) => {
    // Check if eventDetails is an empty object or null/undefined
    if (!eventDetails || Object.keys(eventDetails).length === 0) {
      return false;
    }

    // Check if any of the object's properties have non-empty values
    for (const key in eventDetails) {
      const value = eventDetails[key];
      if (key === "errors") continue;

      if (
        value !== null &&
        value !== undefined &&
        value !== "" && // Check for empty string
        !(Array.isArray(value) && value.length === 0) // Check for empty array
      ) {
        return true; // Return true if any property is non-empty
      }
    }

    return false; // Return false if all properties are empty
  };


  if (!isEventDetailsValid(eventDetails)) {
    errors.eventType = "Please select at least one event type.";
    setFormData((prevData) => ({
      ...prevData,
      eventType: {
        ...prevData.eventType,
        errors,
      },
    }));
    return false;
  }

  errors.eventType = "";
  setFormData((prevData) => ({
    ...prevData,
    eventType: {
      ...prevData.eventType,
      errors,
    },
  }));
  return true;
};



export const validVenueEventTypeForm = (formData, setFormData) => {
  const additionalDetails = formData?.eventType || {};
  const errors = {};

  const conference = additionalDetails["Conference/Seminars"] || [];
  const corporateEvents = additionalDetails["Corporate Events"] || [];
  const weddings = additionalDetails["Weddings"] || [];
  const dining = additionalDetails["Dining"] || [];
  const banquet = additionalDetails["Special Big Events"] || [];
  const socialParties = additionalDetails["Performance & Exhibitions"] || [];
  const clubbingNightOut = additionalDetails["Milestone Birthdays"] || [];
  const drinksReceptions = additionalDetails?.parties?.drinksReceptions || [];
  const christmasParties = additionalDetails?.parties?.christmasParties || [];
  const babyShowerTeaParty = additionalDetails["Tea Party/Showers"] || [];
  const childrenTeen = additionalDetails["Teen/Children"] || [];
  const genericTags = additionalDetails?.parties?.genericTags || [];
  const filmingAndPhotography =
    additionalDetails["Filming, Photography & Media"] || [];
  const cultural = additionalDetails["Cultural"] || [];
  const artSpaces = additionalDetails["Art Spaces"] || [];
  const performancesExhibitions =
    additionalDetails?.artAndCultural?.performancesExhibitions || [];

  // General check: ensure at least one event type has a selection
  const isAnySelected =
    additionalDetails?.business?.conferenceSeminar?.length > 0 ||
    additionalDetails?.business?.corporateEvents?.length > 0 ||
    additionalDetails?.weddingAndDining?.weddings?.length > 0 ||
    additionalDetails?.weddingAndDining?.dining?.length > 0 ||
    additionalDetails?.weddingAndDining?.banquet?.length > 0 ||
    additionalDetails?.parties?.socialParties?.length > 0 ||
    additionalDetails?.parties?.clubbingNightOut?.length > 0 ||
    additionalDetails?.parties?.drinksReceptions?.length > 0 ||
    additionalDetails?.parties?.christmasParties?.length > 0 ||
    additionalDetails?.parties?.babyShowerTeaParty?.length > 0 ||
    additionalDetails?.parties?.childrenTeen?.length > 0 ||
    additionalDetails?.parties?.genericTags?.length > 0 ||
    additionalDetails?.artAndCultural?.filmingAndPhotography?.length > 0 ||
    additionalDetails?.artAndCultural?.cultural?.length > 0 ||
    additionalDetails?.artAndCultural?.artSpaces?.length > 0 ||
    additionalDetails?.artAndCultural?.performancesExhibitions?.length > 0;


  // If no event type is selected, show a general error
  if (!isAnySelected) {
    errors.general = "Please select at least one chip from Event Type";
  }

  // Update the form data with errors
  setFormData((prevData) => ({
    ...prevData,
    errors: {
      ...(prevData.errors || {}), // Ensure errors object exists
      eventType: errors,
    },
  }));

  // Return false if errors exist
  return Object.keys(errors).length === 0;
};

export const validateLocationForm = (formData, setFormData) => {
  const locationDetails = formData?.location || {};
  const errors = {};

  if (locationDetails.addressLine1.trim() === "") {
    errors.addressLine1 = "Address Line 1 is required";
  }
  if (!locationDetails.city) {
    errors.city = "City is required";
  }
  if (!locationDetails.area) {
    errors.area = "Area is required";
  }
  if (!locationDetails.mapLink) {
    errors.mapLink = "Map Link is required";
  }
  // if (!locationDetails.mapLink) {
  // 	errors.mapLink = 'Map Link is required';
  // }

  setFormData((prevData) => ({
    ...prevData,
    location: {
      ...prevData.location,
      errors,
    },
  }));

  return Object.keys(errors).length === 0;
};

export const validateCapacityTypeForm = (formData, setFormData) => {
  const capacity = formData?.capacity || {};
  const errors = {};

  // Check if required fields are present
  if (!capacity.venueSize.trim()) {
    errors.venueSize = "Venue Size is required";
  }
  if (!capacity.sitting) {
    errors.sitting = "Sitting capacity is required";
  }
  if (!capacity.standing) {
    errors.standing = "Standing capacity is required";
  }

  // Validate additional layout fields
  const additionalLayout = capacity.additionalLayout || {};
  Object.keys(additionalLayout).forEach((layoutType) => {
    const layout = additionalLayout[layoutType];
    if (layout.enabled && !layout.value) {
      errors[layoutType] = `${
        layoutType.charAt(0).toUpperCase() + layoutType.slice(1)
      } layout value is required`;
    }
  });

  setFormData((prevData) => ({
    ...prevData,
    capacity: {
      ...prevData.capacity,
      errors,
    },
  }));
  return Object.keys(errors).length === 0;
};

export const validateAudioForm = (formData, setFormData) => {
  const audio = formData?.audio || {};
  const errors = {};

  // Validate indoor music allowed time only if indoor music is allowed
  if (audio.indoorMusicAllowed && audio.indoorMusicAllowedTime === "") {
    errors.indoorMusicAllowedTime = "Indoor music allowed time is required.";
  }

  // Validate outdoor music allowed time only if outdoor music is allowed
  if (audio.outdoorMusicAllowed && audio.outdoorMusicAllowedTime === "") {
    errors.outdoorMusicAllowedTime = "Outdoor music allowed time is required.";
  }

  // Ensure the existing form data is not overwritten and errors are properly set
  setFormData((prevData) => ({
    ...prevData,
    audio: {
      ...prevData.audio, // Spread the existing audio data
      errors: { ...prevData.audio?.errors, ...errors }, // Merge new and old errors
    },
  }));


  return Object.keys(errors).length === 0;
};

export const validateFacilitiesForm = (formData, setFormData) => {
  const facilities = formData?.facilities || {};
  const errors = {};

  // Validate activitiesForKidsIncludes if activitiesForKids is true
  if (
    facilities.activitiesForKids &&
    facilities.activitiesForKidsIncludes.length === 0
  ) {
    errors.activitiesForKidsIncludes =
      "Please provide at least one activity for kids.";
  }

  // Validate that at least one value inside facilities.facilities is true
  const facilityValues = Object.values(facilities.facilities || {});
  const atLeastOneTrue = facilityValues.some((value) => value === true);

  if (!atLeastOneTrue) {
    errors.facilities = "At least one facility option must be selected.";
  }

  // Update the form data with errors, ensuring errors are defined even if empty
  setFormData((prevData) => ({
    ...prevData,
    facilities: {
      ...prevData.facilities,
      errors: errors, // This ensures that errors are always defined
    },
  }));


  return Object.keys(errors).length === 0;
};

export const validateCateringForm = (formData, setFormData) => {
  const cateringAndDrinks = formData?.cateringAndDrinks || {};
  const errors = {};
  // Check if the venue provides in-house catering

  if(cateringAndDrinks.alcoholLiquorLicense){
    if(!cateringAndDrinks.alcoholServingTime){
      errors.alcoholServingTime = "Required";
    }
  }

  const venueProvideInHouseCatering =
    cateringAndDrinks.venueProvideInHouseCatering;
  if (venueProvideInHouseCatering) {
    // Validate Venue Menu
    // const v1 = cateringAndDrinks?.availableMenu || {};
    // const availableMenu = Object.values(v1).flat().length;

    // if (availableMenu < 1) {
    //   errors.availableMenu = "You must select at least 1 available menu.";
    // }

    // Validate Cuisine
    const venueStyle = cateringAndDrinks.cuisine || {};
    const totalVenueStyles = Object.values(venueStyle).flat().length;

    if (totalVenueStyles < 1) {
      errors.cuisine = "You must select at least 1 cuisine.";
    }
    // Validate Serving Style
    const venueStyle2 = cateringAndDrinks.servingStyle || {};
    const totalVenueStyles2 = Object.values(venueStyle2).flat().length;
    if (totalVenueStyles2 < 1) {
      errors.servingStyle = "You must select at least 1 serving style.";
    }
  }

  setFormData((prevData) => ({
    ...prevData,
    cateringAndDrinks: {
      ...prevData.cateringAndDrinks,
      errors,
    },
  }));
  return Object.keys(errors).length === 0;
};

export const validateSpaceTypeForm = (formData, setFormData) => {
  const spaceRules = formData?.spaceRules || {};
  const errors = {};

  setFormData((prevData) => ({
    ...prevData,
    spaceRules: {
      ...prevData.spaceRules,
      errors,
    },
  }));

  return Object.keys(errors).length === 0;
};

export const validatUploadForm = (formData, setFormData) => {
  const UploadDocuments = formData.UploadDocuments || {};
  const errors = {};

  // Define documents with "required" status for the first two
  const documents = [
    { name: "floorPlans", optional: false, multiple: true },
    { name: "tradeLicense", optional: false, multiple: false },
    { name: "sampleMenus", optional: true, multiple: true },
    { name: "brochure", optional: true, multiple: true },
  ];

  // Validate each selected document
  documents.forEach((doc) => {
    const documentFiles = UploadDocuments[doc.name] || [];
    // Check if required documents are uploaded
    if (!doc.optional && documentFiles.length === 0) {
      errors[doc.name] = `${doc.name} is required`;
    }
  });
  // Update the formData with validation errors
  setFormData((prevData) => ({
    ...prevData,
    UploadDocuments: {
      ...prevData.UploadDocuments,
      errors,
    },
  }));

  // Return true if no errors, otherwise false
  return Object.keys(errors).length === 0;
};

export const validatePhotoVideoForm = (formData, setFormData) => {
  const photoVideo = formData?.imagesAndVideos; // Default to empty arrays if undefined
  const errors = {};

  if (!photoVideo?.images.length) {
    errors.images = "At least one image is required";
  }

  setFormData((prevData) => ({
    ...prevData,
    imagesAndVideos: {
      ...prevData.imagesAndVideos,
      errors,
    },
  }));
  return Object.keys(errors).length === 0;
};

// Validation for indicating that this step is not fully completed

// export const validateCateringAndDrinks = (cateringAndDrinks) => {
//   const requiredFields = [
//     'availableMenu',
//     'cuisine',
//     'servingStyle',
//   ];

//   const errors = requiredFields.filter(field => {
//     if (Array.isArray(cateringAndDrinks[field])) {
//       return cateringAndDrinks[field].length === 0;
//     }
//     return !cateringAndDrinks[field];
//   });

//   return errors.length > 0;
// };
export const validateCateringAndDrinks = (cateringAndDrinks) => {
  const errors = [];

  // Check if the venue provides in-house catering
  const venueProvideInHouseCatering =
    cateringAndDrinks.venueProvideInHouseCatering;

  // Only validate 'cuisine' and 'servingStyle' if in-house catering is provided
  if (venueProvideInHouseCatering) {
    const requiredFields = ["cuisine", "servingStyle"];

    requiredFields.forEach((field) => {
      if (Array.isArray(cateringAndDrinks[field])) {
        if (cateringAndDrinks[field].length === 0) {
          errors.push(field);
        }
      } else if (!cateringAndDrinks[field]) {
        errors.push(field);
      }
    });
  }

  return errors.length > 0;
};

export const validateSpaceRules = (spaceRules) => {
  const requiredFields = ["moreSpaceRules"];

  const errors = requiredFields.filter((field) => {
    if (Array.isArray(spaceRules[field])) {
      return spaceRules[field].length === 0;
    }
    // return !spaceRules[field];
    return false;
  });

  return errors.length > 0;
};
// offer and packages
export const validateOffersAndPackages = (offersAndPackages) => {
  const requiredFields = ["description"];
  // Check if offersAndPackages is undefined, null, or not an array
  if (!Array.isArray(offersAndPackages)) {
    return true; // Return true indicating errors if not an array
  }
  // Validate each offer/package item in the array
  const hasErrors = offersAndPackages.some((offerPackage) => {
    // If offerPackage is not an object or is null, it's invalid
    if (typeof offerPackage !== "object" || offerPackage === null) {
      return true;
    }
    // Check if any required field is missing or empty
    return requiredFields.some((field) => {
      if (Array.isArray(offerPackage[field])) {
        return offerPackage[field].length === 0;
      }
      return !offerPackage[field];
    });
  });
  return hasErrors;
};


export const validatePricing = (pricingData) => {
  // Check if pricingData is undefined at the beginning
  if (pricingData === undefined) {
    console.error("Error: pricingData is undefined");
    return true; // Return true to indicate an error
  }


  if (!pricingData) {
    console.error("no data");
    return false;
  }

  // Initialize a counter for valid categories
  let validCategoryCount = 0;

  // Initialize an array to store validation results for each relevant category
  const validationResults = Object.keys(pricingData || {}).map((optionKey) => {
    const dataArray = pricingData[optionKey];

    // Skip specific keys like 'selectedOptions'
    if (optionKey === "selectedOptions") {
      return true; // Treat selectedOptions as valid and skip validation
    }

    // Check if dataArray is undefined or an empty array
    if (
      dataArray === undefined ||
      (Array.isArray(dataArray) && dataArray.length === 0)
    ) {
      console.warn(`Warning: ${optionKey} is empty or undefined`); // Just warn, don't fail validation
      return false; // Treat empty category as invalid
    }

    // Validate each item in the dataArray
    const isValid = dataArray.every((item) => {
      const isItemValid =
        item.duration &&
        item.from &&
        item.to &&
        item.minSpend &&
        Array.isArray(item.selectedDays) &&
        item.selectedDays.length > 0; // Ensure selectedDays is not empty

      // Log invalid items for debugging
      if (!isItemValid) {
        console.error(`Invalid item in ${optionKey}:`, item);
      }
      return isItemValid;
    });

    // If this category is valid, increment the count
    if (isValid) {
      validCategoryCount++;
    }

    return isValid; // Return the validity of this category
  });

  // Check if at least two categories are valid
  const hasValidOption = validCategoryCount >= 1;

  // Return true if there are fewer than two valid options
  return !hasValidOption; // Return false if there are invalid options
};

export const validateBasicDetails = (basicDetails = {}) => {
  // Define the required fields
  const requiredFields = ["venueName", "hotelName", "venueDescription"];

  // Check if basicDetails is an object
  if (typeof basicDetails !== "object" || basicDetails === null) {
    return true; // Return true indicating errors if not an object
  }

  // Check if any required field is empty
  const hasErrors = requiredFields.some((field) => {
    return !basicDetails[field] || basicDetails[field].trim() === "";
  });

  // Return true if there are errors (i.e., if any required field is empty)
  return hasErrors;
};
// additional details
export const validateAdditionalDetails = (additionalDetails) => {
  const { venueView, venueSetting, venueStyle, venueHire } = additionalDetails;

  // Check if venueStyle is valid
  const isVenueStyleValid = Object.values(venueStyle).every(
    (styleArray) => Array.isArray(styleArray) && styleArray.length > 0
  );

  // Check if all required fields have values
  const isValid =
    Array.isArray(venueView) &&
    venueView.length > 0 &&
    Array.isArray(venueSetting) &&
    venueSetting.length > 0 &&
    isVenueStyleValid &&
    venueHire !== "";

  return isValid;
};
export const validateFacilitiesStepper = (facilities = {}) => {
  const errors = {};
  // Validate activitiesForKidsIncludes if activitiesForKids is true
  if (
    facilities.activitiesForKids &&
    (!Array.isArray(facilities.activitiesForKidsIncludes) ||
      facilities.activitiesForKidsIncludes.length === 0)
  ) {
    errors.activitiesForKidsIncludes =
      "Please provide at least one activity for kids.";
  }
  // Validate that at least one value inside facilities.facilities is true
  const facilityValues = Object.values(facilities.facilities || {});
  const atLeastOneTrue = facilityValues.some((value) => value === true);
  if (!atLeastOneTrue) {
    errors.facilities = "At least one facility option must be selected.";
  }
  const hasErrors = Object.keys(errors).length > 0;
  return hasErrors;
};
export const validateUploadDocumentstepper = (UploadDocuments = {}) => {
  const errors = {};

  // Define documents with "required" status for the first two
  const documents = [
    { name: "floorPlans", optional: false, multiple: true },
    { name: "tradeLicense", optional: false, multiple: false },
    { name: "sampleMenus", optional: true, multiple: true },
    { name: "brochure", optional: true, multiple: true },
  ];

  // Validate each selected document
  documents.forEach((doc) => {
    const documentFiles = UploadDocuments[doc.name] || [];
    // Check if required documents are uploaded
    if (!doc.optional && documentFiles.length === 0) {
      errors[doc.name] = `${doc.name} is required`;
    }
  });

  // Determine if there are any errors
  const hasErrors = Object.keys(errors).length > 0;

  // Return hasErrors, indicating whether there are validation issues
  return hasErrors;
};

export const validateUploadPhotosAndVideosStepper = (
  uploadImagesAndVideos = {}
) => {
  const errors = {};

  if (uploadImagesAndVideos["images"].length === 0)
    errors.images = "At least one image is required";

  // Determine if there are any errors
  const hasErrors = Object.keys(errors).length > 0;

  // Return hasErrors, indicating whether there are validation issues
  return hasErrors;
};

export const validateCapacityTypeStepper = (capacity = {}) => {
  const errors = {};

  // Check if required fields are present
  if (!capacity.venueSize) {
    errors.venueSize = "Venue Size is required";
  }
  if (!capacity.sitting) {
    errors.sitting = "Sitting capacity is required";
  }
  if (!capacity.standing) {
    errors.standing = "Standing capacity is required";
  }

  // Validate additional layout fields
  const additionalLayout = capacity.additionalLayout || {};
  Object.keys(additionalLayout).forEach((layoutType) => {
    const layout = additionalLayout[layoutType];
    if (layout.enabled && !layout.value) {
      errors[layoutType] = `${
        layoutType.charAt(0).toUpperCase() + layoutType.slice(1)
      } layout value is required`;
    }
  });

  // Determine if there are any errors
  const hasErrors = Object.keys(errors).length > 0;

  // Return hasErrors, indicating whether there are validation issues
  return hasErrors;
};
