import costStructure from "../constants/costStructure";
import { chipLabelsWithCategories } from "../constants/chipLabels";


function calculateVenuePayment(quantityNumber, sameLocation, items = []) {
  let baseCharge = 0;
  let subTotal = 0;

  // Filter out items with quantityNumber of 0
  const filteredItems = items.filter(item => item.amount > 0);

  // Count the number of "yes" and "no" answers
  let yesCount = 0;
  let noCount = 0;

  filteredItems.forEach(item => {
    if (item.answer === 'yes') {
      yesCount++;
    } else if (item.answer === 'no') {
      noCount++;
    }
  });


  // Calculate base charge based on the counts
  baseCharge = (yesCount * 2000) + (noCount * 1000);

  // Calculate subtotal based on quantityNumber and sameLocation logic
  if (sameLocation === "no") {
    subTotal = baseCharge * quantityNumber;
  } else if (sameLocation === "yes") {
    subTotal = baseCharge;
  }


  // Calculate VAT
  const vatPercentage = costStructure.vat;
  const vat = (subTotal * vatPercentage) / 100;

  // Calculate Grand Total
  const grandTotal = subTotal + vat;


  return {
    baseCharge,
    quantityNumber,
    subTotal,
    vat,
    grandTotal,
    yesCount,
    noCount,
  };
}

export default calculateVenuePayment;
