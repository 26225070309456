import React, { useState } from "react";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import Tooltip from "../Tooltip";

const pricingData = [
  {
    day: "Sunday",
    time: "6:00AM - 9:00AM",
    price: "AED 7000 venue rental",
    slots: [
      {
        type: "Per session",
        time: "6:00AM - 9:00AM",
        price: "AED 6000 min. spend",
      },
      {
        type: "Per morning",
        time: "6:00AM - 9:00AM",
        price: "AED 6000 min. spend",
      },
      {
        type: "Per hour",
        time: "6:00AM - 9:00AM",
        price: "AED 6000 min. spend",
      },
      {
        type: "Per evening",
        time: "6:00AM - 9:00AM",
        price: "AED 6000 min. spend",
      },
    ],
  },

  {
    day: "Monday",
    time: "6:00AM - 9:00AM",
    price: "AED 7000 venue rental",
    slots: [
      {
        type: "Per session",
        time: "6:00AM - 9:00AM",
        price: "AED 6000 min. spend",
      },
      {
        type: "Per morning",
        time: "6:00AM - 9:00AM",
        price: "AED 6000 min. spend",
      },
      {
        type: "Per hour",
        time: "6:00AM - 9:00AM",
        price: "AED 6000 min. spend",
      },
      {
        type: "Per evening",
        time: "6:00AM - 9:00AM",
        price: "AED 6000 min. spend",
      },
    ],
  },
  {
    day: "Tuesday",
    time: "6:00AM - 9:00AM",
    price: "AED 5500 per person",
    slots: [
      {
        type: "Per session",
        time: "6:00AM - 9:00AM",
        price: "AED 6000 min. spend",
      },
      {
        type: "Per morning",
        time: "6:00AM - 9:00AM",
        price: "AED 6000 min. spend",
      },
      {
        type: "Per hour",
        time: "6:00AM - 9:00AM",
        price: "AED 6000 min. spend",
      },
      {
        type: "Per evening",
        time: "6:00AM - 9:00AM",
        price: "AED 6000 min. spend",
      },
    ],
  },
  {
    day: "Wednesday",
    time: "6:00AM - 9:00AM",
    price: "AED 5500 min. spend",
    slots: [
      {
        type: "Per session",
        time: "6:00AM - 9:00AM",
        price: "AED 6000 min. spend",
      },
      {
        type: "Per morning",
        time: "6:00AM - 9:00AM",
        price: "AED 6000 min. spend",
      },
      {
        type: "Per hour",
        time: "6:00AM - 9:00AM",
        price: "AED 6000 min. spend",
      },
      {
        type: "Per evening",
        time: "6:00AM - 9:00AM",
        price: "AED 6000 min. spend",
      },
    ],
  },
  {
    day: "Thursday",
    time: "6:00AM - 9:00AM",
    price: "AED 6000 min. spend",
    slots: [
      {
        type: "Per session",
        time: "6:00AM - 9:00AM",
        price: "AED 6000 min. spend",
      },
      {
        type: "Per morning",
        time: "6:00AM - 9:00AM",
        price: "AED 6000 min. spend",
      },
      {
        type: "Per hour",
        time: "6:00AM - 9:00AM",
        price: "AED 6000 min. spend",
      },
      {
        type: "Per evening",
        time: "6:00AM - 9:00AM",
        price: "AED 6000 min. spend",
      },
    ],
  },
  { day: "Friday", closed: true },
  { day: "Saturday", closed: true },
];
const transformFormDataToPricingData = (formData) => {
  if (!formData) return [];
  const pricingTypes = Object.keys(formData);
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const convertTo12HourFormat = (time) => {
    const [hours, minutes] = time.split(":");
    const period = +hours < 12 ? "AM" : "PM";
    const hour = +hours % 12 || 12;
    return `${hour}:${minutes} ${period}`;
  };

  return daysOfWeek.map((day, index) => {
    const dayShort = day.slice(0, 3);
    const dayData = pricingTypes.reduce((acc, type) => {
      const filteredData = formData[type]?.filter((item) =>
        item.selectedDays?.includes(dayShort)
      );
      if (filteredData.length) {
        acc.push(
          ...filteredData.map((item) => ({
            type,
            time:
              item?.from && item?.to
                ? `${convertTo12HourFormat(
                    item.from
                  )} - ${convertTo12HourFormat(item.to)}`
                : "",
            price: `${item.minSpend} min. spend`,
            duration: item.duration,
          }))
        );
      }
      return acc;
    }, []);
    return {
      day,
      slots: dayData.length ? dayData : null,
      closed: !dayData.length,
      time: dayData.length ? dayData[0].time : "",
      price: dayData.length ? dayData[0].price : "",
      duration: dayData.length ? dayData[0].duration : "",
    };
  });
};

const Pricing = ({ data }) => {
  // const { formData } = useContext(VenueContext);
  const pricingData = transformFormDataToPricingData(data);
  const [expanded, setExpanded] = useState(null);

  const toggleExpand = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <div className="bg-white rounded-lg flex justify-between mb-8 lg:w-3/4 w-full mx-auto py-0">
      <div className="flex items-start gap-1">
        <h2 className="text-2xl font-bold">Pricing</h2>
        <Tooltip text="Room Rate may vary during different seasons, submit your enquiries for a personalized quote for your booking" />
      </div>
      <div className="lg:w-[75%] w-full text-base text-[#222222] font-medium">
        {pricingData &&
          pricingData?.map((day, index) => (
            <div key={index} className="mb-4">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleExpand(index)}
              >
                <h2 className="text-start">{day.day}</h2>
                {!expanded === index && (
                  <h2 className="text-start">
                    {day.closed ? "Closed" : day.time}
                  </h2>
                )}
                <h2 className="text-start flex items-center gap-5">
                  {!expanded === index && !day.closed && day.price}
                  {day?.slots &&
                    (expanded === index ? (
                      <MdKeyboardArrowUp className="text-3xl" />
                    ) : (
                      <MdKeyboardArrowDown className="text-3xl" />
                    ))}
                </h2>
              </div>
              {expanded === index && day.slots && (
                <ul className="list-disc list-inside mt-2 py-4 space-y-2">
                  {day?.slots.map((slot, slotIndex) => (
                    <li
                      key={slotIndex}
                      className='font-normal text-[#222222] flex before:content-["•"] before:mr-4 before:text-black'
                    >
                      <div className="flex justify-between w-full ">
                        <div className="mr-4 w-[18%]">
                          {slot?.duration === "perday"
                            ? "Per Day"
                            : slot?.duration === "persession"
                            ? "Per Session"
                            : slot?.duration === "permorning"
                            ? "Per Morning"
                            : slot?.duration === "perafternoon"
                            ? "Per Afternoon"
                            : slot?.duration === "perevening"
                            ? "Per Evening"
                            : slot?.duration
                            ? slot.duration
                                .split("_")
                                .map((word, index) =>
                                  index === 0
                                    ? word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                    : word
                                )
                                .join(" ")
                            : ""}
                        </div>
                        <div className="mr-4">{slot?.time}</div>
                        AED{" "}
                        {slot?.price?.replace(/\d+/, (num) =>
                          Number(num).toLocaleString()
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Pricing;
