import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { fetchUserById } from "../api/loggedUser";

const CheckOnBoarding = ({ element: Component, ...rest }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRedirect, setShouldRedirect] = useState(null);
  const userToken = localStorage.getItem("accessToken");

  const checkUser = async () => {
    try {
      const storedVendorId = localStorage.getItem("vendorId");
      const user = await fetchUserById(storedVendorId);

      // Log onBoardingDetails to inspect the data structure
      const onBoardingDetails = user?.vendor;
      const onBoardingDetailscheck = user?.vendor?.onBoardingDetails;

      // Access paymentStatus and confirmPayment properly
      const paymentStatus = onBoardingDetails?.paymentStatus || "Unknown";

      // Use correct reference to confirmPayment
      const confirmPayment = onBoardingDetails?.confirmPayment;
      if (!confirmPayment) {
        setShouldRedirect("onboarding");
        return;
      }
      // Check if onboarding details are present
      if (onBoardingDetailscheck) {
        if (paymentStatus === "Cancelled") {
          setShouldRedirect("onboarding");
        } else {
          setShouldRedirect("dashboard");
        }
      } else {
        setShouldRedirect("onboarding");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userToken) {
      checkUser();
    } else {
      setIsLoading(false);
      setShouldRedirect("onboarding");
    }
  }, [userToken]);

  // Reset specialDiscount when on the onboarding page
  useEffect(() => {
    if (window.location.pathname === "/onboarding") {
      localStorage.setItem("specialDiscount", 0);
    }
  }, []);

  if (isLoading) return <div>Loading...</div>;

  // Redirect logic
  if (
    shouldRedirect === "dashboard" &&
    window.location.pathname !== "/dashboard"
  ) {
    return <Navigate to="/dashboard" />;
  }

  if (
    shouldRedirect === "onboarding" &&
    window.location.pathname !== "/onboarding"
  ) {
    return <Navigate to="/onboarding" />;
  }

  // Render the passed component
  return <Component {...rest} />;
};

export default CheckOnBoarding;
