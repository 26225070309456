import React from "react";
import { FaChild, FaBeer, FaVolumeUp, FaMusic } from "react-icons/fa";

const Highlights = ({ data }) => {
  const highlightsData1 = [...data?.venueType?.venueType];

  // Utility function to convert time to 12-hour format
  const formatTimeTo12Hour = (timeString) => {
    const [hour, minute] = timeString.split(":");
    const hourInt = parseInt(hour, 10);
    const period = hourInt >= 12 ? "PM" : "AM";
    const formattedHour = hourInt % 12 || 12; // Converts 0 to 12 for midnight, and handles 12-hour conversion
    return `${formattedHour}:${minute} ${period}`;
  };
  const highlightsData2 = [
    {
      icon: <FaBeer />,
      // text: "Alcohol/ Liquor License",
      text: data?.cateringAndDrinks?.alcoholServingTime
        ? `Alcohol/ Liquor License until ${formatTimeTo12Hour(
            data?.cateringAndDrinks?.alcoholServingTime
          )}`
        : "Alcohol/ Liquor License",
      condition: data?.cateringAndDrinks?.alcoholLiquorLicense,
    },
    {
      icon: <FaMusic />,
      text: data?.audio?.indoorMusicAllowedTime
        ? `Indoor music allowed until ${formatTimeTo12Hour(
            data?.audio?.indoorMusicAllowedTime
          )}`
        : "Indoor music allowed",
      condition: data?.audio?.indoorMusicAllowed,
    },

    {
      icon: <FaVolumeUp />,
      text: "Bring your own DJ",
      condition: data?.audio?.clientsCanBringOwnDJ,
    },
    {
      icon: <FaChild />,
      text: data?.audio?.outdoorMusicAllowedTime
        ? `Outdoor music allowed until ${formatTimeTo12Hour(
            data?.audio?.outdoorMusicAllowedTime
          )}`
        : "Outdoor music allowed",
      condition: data?.audio?.outdoorMusicAllowed,
    },
  ];

  return (
    <div className="py-5 lg:w-3/4 mx-auto w-full rounded-lg">
      <div className="flex flex-col gap-4">
        <h2 className="text-2xl font-semibold mb-4">Highlights</h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 p-8 w-full rounded-xl bg-[#FFEBEB]">
          <div className="col-span-3 border-r border-gray-300 pr-4 pl-4 md:pl-0">
            <h3 className="text-xl font-medium mb-4">Venue Type</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
              {highlightsData1?.map((item, index) => (
                <div key={index} className="flex items-center">
                  <span
                    className={`${
                      item.disabled
                        ? "text-gray-400 line-through"
                        : "text-black"
                    }`}
                  >
                    {item}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="col-span-1 pl-4">
            <h3 className="text-xl font-medium mb-4">Setting</h3>
            <div className="flex flex-col gap-2 items-start">
              <span
                className={`${
                  !data?.additionalDetails?.venueSetting?.includes("Indoor")
                    ? "text-gray-400 line-through"
                    : "text-black"
                }`}
              >
                Indoor Space
              </span>
              <span
                className={`${
                  !data?.additionalDetails?.venueSetting?.includes("Outdoor")
                    ? "text-gray-400 line-through"
                    : "text-black"
                }`}
              >
                Outdoor Space
              </span>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-8 w-full rounded-xl bg-[#FFEBEB]">
          {highlightsData2?.map((item, index) => (
            <div key={index} className="flex items-center">
              <span
                className={`text-2xl mr-4 ${
                  !item.condition ? "text-gray-400" : ""
                }`}
              >
                {item.icon}
              </span>
              <span
                className={`${
                  !item.condition ? "text-gray-400 line-through" : "text-black"
                }`}
              >
                {item.text}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Highlights;
