import React, { createContext, useState } from "react";

export const VenueContext = createContext();

export const VenueProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(0); // Track the current step
  const [venuePricing, setvenuePricing] = useState(false);

  const defaultFormData = {
    vendorId: "",
    status: "pending",
    itemStatus: "pending",
    equipment: {
      equipment: [],
      equipmentIcons: {},
    },
    location: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      area: "",
      poBox: "",
      country: "United Arab Emirates",
      mapLink: "",
      lat: null,
      lng: null,
      accessibilityFeatures: {
        wheelchairAccessible: false,
        elevator: false,
        brailleSigns: false,
        hearingLoop: false,
      },
      parkingAvailable: {
        parkingAvailable: false,
        valetParking: false,
      },
    },
    cateringAndDrinks: {
      venueProvideInHouseCatering: false,
      availableMenu: [],
      cuisine: [],
      externalCatering: false,
      alcoholLiquorLicense: false,
      alcoholServingTime: "",
      corkageFeeForBringYourOwnAlcohol: false,
      bringYourOwnAlcoholAllowed: false,
      refreshments: false,
      refreshmentsForGuests: "",
      shisha: false,
      servingStyle: [],
      isVenueAlcohlic: false,
    },
    capacity: {
      venueSize: "",
      unit: "Sq. feet",
      standing: "",
      sitting: "",
      additionalLayout: {
        dining: { enabled: false, value: "" },
        theatre: { enabled: false, value: "" },
        boardroom: { enabled: false, value: "" },
        ushaped: { enabled: false, value: "" },
        cabaret: { enabled: false, value: "" },
      },
    },
    venueType: {
      venueType: [],
      venueRepresent: "",
    },
    facilities: {
      facilities: [],
      facilitiesFeatureIcons: {},
      activitiesForKids: false,
      activitiesForKidsIncludes: [],
    },
    basicDetails: {
      venueName: "",
      hotelName: "",
      groupName: "",
      hasMultipleEventSpaces: false,
      isPrimaryVenue: false,
      venueDescription: "",
    },
    additionalDetails: {
      venueView: [],
      venueSetting: [],
      venueStyle: {
        traditionalStyle: [],
        modernContemporaryStyle: [],
        eclecticStyle: [],
        culturalRegionalStyle: [],
      },
      venueHire: "",
    },

    eventType: {},

    pricing: {},
    offersAndPackages: [],
    offerPackage: [],
    spaceRules: {
      agePolicy: [0, 30],
      moreSpaceRules: "",
      ticketedEventsAllowed: false,
    },
    audio: {
      indoorMusicAllowed: false,
      indoorMusicAllowedTime: "",
      outdoorMusicAllowed: false,
      outdoorMusicAllowedTime: "",
      paSystemSpeakers: false,
      clientsCanPlayOwnMusic: false,
      clientsCanBringOwnDJ: false,
      spaceHasNoiseRestrictions: false,
    },
    UploadDocuments: {
      floorPlans: [],
      tradeLicense: [],
      sampleMenus: [],
      brochure: [],
      selectedDocuments: [],
    },
    imagesAndVideos: {
      images: [],
      videos: [],
      videoLinks: [],
      coverPhoto: "",
    },
    overviewbasicdetailsmissing: {
      isError: false,
    },

    cateringmissing: {
      isError: false,
    },
    spacerulesmissing: {
      isError: false,
    },
    pricingmissing: {
      isError: false,
    },
  };

  // Create state with initial data.
  const [formData, setFormData] = useState(defaultFormData);

  const resetForm = () => {
    setFormData(defaultFormData);
    setCurrentStep(0);
    return defaultFormData; // Return the default data immediately
  };

  const addPricing = (type, newForm) => {
    setFormData((prev) => ({
      ...prev,
      pricing: {
        ...prev.pricing,
        [type]: [...(prev?.pricing[type] || []), newForm],
      },
    }));
  };

  const removePricing = (id, type) => {
    setFormData((prev) => ({
      ...prev,
      pricing: {
        ...prev.pricing,
        [type]: (prev.pricing[type] || []).filter((item) => item.id !== id),
      },
    }));
  };
  const validatePricing2 = (pricingData) => {
    // Check if pricingData is undefined
    if (pricingData === undefined) {
      console.error("Error: pricingData is undefined");
      return false; // Return false for undefined pricingData
    }


    // Initialize a counter for valid categories
    let validCategoryCount = 0;

    // Initialize an array to store validation results for each relevant category
    const validationResults = Object.keys(pricingData || {}).map(
      (optionKey) => {
        const dataArray = pricingData[optionKey];

        // Skip specific keys like 'selectedOptions'
        if (optionKey === "selectedOptions") {
          return true; // Treat selectedOptions as valid and skip validation
        }

        // Check if dataArray is undefined or an empty array
        if (dataArray === undefined || dataArray.length === 0) {
          console.warn(`Warning: ${optionKey} is empty or undefined`); // Just warn, don't fail validation
          return false; // Treat empty category as invalid
        }

        // Validate each item in the dataArray
        const isValid = dataArray.every((item) => {
          const isItemValid =
            item.duration &&
            item.from &&
            item.to &&
            item.minSpend &&
            Array.isArray(item.selectedDays) &&
            item.selectedDays.length > 0; // Ensure selectedDays is not empty

          // Log invalid items for debugging
          if (!isItemValid) {
            console.error(`Invalid item in ${optionKey}:`, item);
          }
          return isItemValid;
        });

        // If this category is valid, increment the count
        if (isValid) {
          validCategoryCount++;
        }

        return isValid; // Return the validity of this category
      }
    );

    // Check if at least two categories are valid
    const hasTwoValidOptions = validCategoryCount >= 1;

    // Update venuePricing based on the validation result
    setvenuePricing(hasTwoValidOptions);

    // Return true if there are fewer than two valid options
    return !hasTwoValidOptions;
  };

  const updatePricing = (id, type, updatedForm) => {
    setFormData((prev) => ({
      ...prev,
      pricing: {
        ...prev.pricing,
        [type]: prev.pricing[type].map((item) =>
          item.id === id ? { ...item, ...updatedForm } : item
        ),
      },
    }));
  };
  return (
    <VenueContext.Provider
      value={{
        formData,
        setFormData,
        currentStep,
        setCurrentStep,
        updatePricing,
        addPricing,
        removePricing,
        resetForm,
        venuePricing,
        setvenuePricing,
        validatePricing2,
      }}
    >
      {children}
    </VenueContext.Provider>
  );
};
