import React from "react";
import CustomIconChipsVenue from "./customChips-venue";
import { Box, Typography, useTheme } from "@mui/material";
;

function MultiChipSelector({ onSelectedChipsChange, isSelectOneChip = false }) {
  const theme = useTheme();

  const handleChipsChange = (selectedChips) => {
    onSelectedChipsChange(selectedChips);
  };
  return (
    <Box
      sx={{
        width: { xs: "100%", md: "95%" },
        mt: 4,
        p: 2,
        borderRadius: "8px",
        // border: { md: `2px solid ${theme.palette.grey.border}` },
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        overflow: "hidden",
        backgroundColor: theme.palette.white,
      }}
    >
      <Typography sx={{ textAlign: "center", pl: "7px", fontWeight: "550" }}>
        Select the type of venues you have
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
          justifyContent: "center",
          mt: 2,
        }} 
      >
        <CustomIconChipsVenue
          onChipsChange={handleChipsChange}
          isSelectOneChip={isSelectOneChip}
        />
      </Box>
    </Box>
  );
}

export default MultiChipSelector;
