import React, { useEffect, useState } from "react";
import { base_URL } from "../../../config/config";
import { toast } from "react-toastify";
import axios from "axios";
import { MdScatterPlot } from "react-icons/md";
import { Button } from "@mui/material";
import CouponTextfield from "../../onboarding/components/CouponTextfield";
import CouponInputField from "../components/CouponInputField";
import moment from "moment";
import CouponTextfieldSubscription from "../components/CouponTextfieldSubscription";
import CouponInputFieldSubscription from "../components/CouponInputFieldSubscription";

function ModifyModal({
  isOpen,
  onClose,
  user,
  refetchOnboardingData,
  refetchUser,
}) {
  const [alcoholQuantity, setAlcoholQuantity] = useState(0);
  const [nonAlcoholQuantity, setNonAlcoholQuantity] = useState(0);
  const [multiVenueQuantity, setMultiVenueQuantity] = useState(0);
  const [singleVenueQuantity, setSingleVenueQuantity] = useState(0);
  const [startLoading, setStartLoading] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [dueAmount, setDueAmount] = useState(0);
  const [proratedAmount, setProratedAmount] = useState(0);
  const [dueAmountUSD, setDueAmountUSD] = useState(null);
  // Ensure the type is "venues" before allowing increment/decrement
  const canModify = user?.onBoardingDetails?.type === "venues";

  // Dynamic rate calculation for alcohol
  const getAlcoholRate = (quantity) => {
    if (quantity === 1) return 1500;
    if (quantity >= 2 && quantity <= 5) return 1300;
    if (quantity > 5) return 1200;
    return 0; // Default rate if quantity is 0
  };

  // Dynamic rate calculation for non-alcohol
  const getNonAlcoholRate = (quantity) => {
    if (quantity === 1) return 1000;
    if (quantity >= 2 && quantity <= 5) return 900;
    if (quantity > 5) return 800;
    return 0; // Default rate if quantity is 0
  };

  // Rate for multi and single venues
  const multiVenueRate =
    user?.onBoardingDetails?.selectedChips?.length === 1 &&
    user?.onBoardingDetails?.selectedChips[0] === "Kids Play Area"
      ? 1000
      : 2000;
  const singleVenueRate =
    user?.onBoardingDetails?.selectedChips?.length === 1 &&
    user?.onBoardingDetails?.selectedChips[0] === "Kids Play Area"
      ? 1000
      : 1000;

  const handleIncrement = (type) => {
    switch (type) {
      case "alcohol":
        setAlcoholQuantity((prevQuantity) => prevQuantity + 1);
        break;
      case "nonAlcohol":
        setNonAlcoholQuantity((prevQuantity) => prevQuantity + 1);
        break;
      case "multiVenue":
        setMultiVenueQuantity((prevQuantity) => prevQuantity + 1);
        break;
      case "singleVenue":
        setSingleVenueQuantity((prevQuantity) => prevQuantity + 1);
        break;
      default:
        break;
    }
  };

  const handleDecrement = (type) => {
    switch (type) {
      case "alcohol":
        if (alcoholQuantity > 0) setAlcoholQuantity(alcoholQuantity - 1);
        break;
      case "nonAlcohol":
        if (nonAlcoholQuantity > 0)
          setNonAlcoholQuantity(nonAlcoholQuantity - 1);
        break;
      case "multiVenue":
        if (multiVenueQuantity > 0)
          setMultiVenueQuantity(multiVenueQuantity - 1);
        break;
      case "singleVenue":
        if (singleVenueQuantity > 0)
          setSingleVenueQuantity(singleVenueQuantity - 1);
        break;
      default:
        break;
    }
  };

  // Total cost calculation
  const calculateTotal = (rate, quantity) => rate * quantity;

  const alcoholRate = getAlcoholRate(alcoholQuantity);
  const nonAlcoholRate = getNonAlcoholRate(nonAlcoholQuantity);

  // Total costs for each section
  const totalAlcoholCost = calculateTotal(alcoholRate, alcoholQuantity);
  const totalNonAlcoholCost = calculateTotal(
    nonAlcoholRate,
    nonAlcoholQuantity
  );
  const totalMultiVenueCost = calculateTotal(
    multiVenueRate,
    multiVenueQuantity
  );
  const totalSingleVenueCost = calculateTotal(
    singleVenueRate,
    singleVenueQuantity
  );

  // Total cost
  const totalCost = canModify
    ? totalAlcoholCost +
      totalNonAlcoholCost +
      totalMultiVenueCost +
      totalSingleVenueCost
    : totalAlcoholCost + totalNonAlcoholCost;
  // Apply discount if promo code is valid
  const discountedTotal = totalCost - (totalCost * discount) / 100;
  // due amount
  //   const pendingAmount = user?.dueAmount > 0 ? totalCost - user?.dueAmount : 0;
  // VAT calculation
  const vatServiceFee = alcoholQuantity > 0 ? totalCost * 0.05 : 0; // VAT only if both alcohol and non-alcohol are present

  // Grand Total calculation
  //   const baseCharge = canModify ? 0 : 2000; // Base charge if can't modify (Hotelcase)
  const baseCharge = canModify ? 0 : 0; // Base charge if can't modify (Hotelcase)

  // Grand Total before discount
  const grandTotalBeforeDiscount =
    Number(totalCost) + Number(vatServiceFee) + Number(baseCharge);

  // Apply discount to grand total
  const discountedGrandTotal =
    grandTotalBeforeDiscount - (grandTotalBeforeDiscount * discount) / 100;
  // Additional payment
  //   const additionalPayment = user?.proAmount || 0;
  const grandTotal = discountedGrandTotal;
  //  +
  // Number(proratedAmount) +
  // Number(dueAmount);
  const discountCodes = {
    FMV100FF: 10,
    "FMV-200OFF": 20,
    CODE40: 40,
    HALF50: 50,
    DISC60: 60,
    UNIQUE75: 75,
    EARLYBIRD90: 90,
    SPECIAL95: 100,
  };
  const validatePromoCode = (code) => {
    const discount = discountCodes[code.toUpperCase()];
    if (discount) {
      setDiscount(discount);
      toast.success("Promo code applied successfully!");
    } else {
      setDiscount(0);
      toast.error("Invalid promo code");
    }
  };
  // Update subscription
  const handleSubscription = async () => {
    try {
      setStartLoading(true);
      const response = await axios.post(`${base_URL}/api/vendor/update-subs`, {
        email: user?.email,
        cardHolderName: user?.onBoardingDetails?.type,
        additionalAmount: grandTotal,
        description: "Upgrading Plan",
        withAlcoholNumber: alcoholQuantity,
        withoutAlcoholNumber: nonAlcoholQuantity,
        singleVenueQuantity,
        multiVenueQuantity,
      });
      if (response.data.success) {
        toast.success("Package modified successfully!");
        setSingleVenueQuantity(0);
        setMultiVenueQuantity(0);
        setNonAlcoholQuantity(0);
        setAlcoholQuantity(0);
        refetchOnboardingData();
        await refetchUser();
        onClose();
      }
    } catch (err) {
      console.error(err);
      setSingleVenueQuantity(0);
      setMultiVenueQuantity(0);
      setNonAlcoholQuantity(0);
      setAlcoholQuantity(0);
      toast.error(err.response.data.message);
    } finally {
      setStartLoading(false);
    }
  };

  //   due amount and pro amount
  useEffect(() => {
    if (isOpen) {
      const getAmounts = async () => {
        try {
          const response = await axios.post(`${base_URL}/get-stripe-details`, {
            email: user?.email,
          });
          const { dueAmount, proratedAmount } = response.data;

          setDueAmount(dueAmount);
          // Inverse conversion rate from AED to USD
          const AED_TO_USD_RATE = 1 / 3.68;
          // Convert AED to USD
          const dueAmountUSD = (dueAmount * AED_TO_USD_RATE).toFixed(2);
          setDueAmountUSD(dueAmountUSD);
          setProratedAmount(proratedAmount);
        } catch (error) {
          console.error("Error fetching amounts: ", error);
        }
      };

      // Call the function to fetch the amounts
      getAmounts();
    }
  }, [isOpen, user?.email]);
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black  bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white w-[90%] md:w-[681px] md:h-[90vh] overflow-y-scroll p-6 rounded-xl shadow-lg relative">
        <div className="flex justify-end">
          <img
            src="/cancelsub.svg"
            alt="close"
            onClick={onClose}
            className="cursor-pointer active:opacity-55"
          />
        </div>
        <h2 className="text-[22px] md:text-[32px] text-center text-[#1D1F2C] font-bold">
          Modify Plan
        </h2>
        {/* Table Content */}
        <div className="flex flex-col md:w-[601px] mt-[22px] md:mt-[30px]  space-y-4">
          {/* Table Header */}
          <div className="flex justify-between gap-x-3 md:gap-x-0 items-center text-[12px] md:text-[16px] text-[#222222] font-medium  pb-2">
            <p className="w-1/2">Plan Breakdown</p>
            <p className="w-1/6 text-end">Rate</p>
            <p className="w-1/6 text-end">Quantity</p>
            <p className="w-1/6 text-right">Total</p>
          </div>

          {/* table rows*/}
          <div className="flex justify-between gap-x-3 md:gap-x-0 items-center text-gray-800">
            <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/2">
              Restaurants with alcohol
            </p>
            <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/6 text-right">
              ${alcoholRate}
            </p>
            {/* Quantity Display */}
            <div className="w-1/6 flex justify-end items-end">
              <div className="flex items-center justify-center border border-[#FFEBEB] rounded-md w-[40px] md:w-[60px] h-[35px] overflow-hidden">
                {/* left */}
                <div className="bg-white flex items-center justify-center w-[70%] h-full">
                  <p className="text-[#222222] font-normal text-[12px] md:text-[14px] leading-none">
                    {String(alcoholQuantity).padStart(2, "0")}
                  </p>
                </div>
                {/* right */}

                {/* Up and Down Arrows */}
                <div className="flex flex-col items-center justify-between bg-[#FFEBEB] w-[40%] h-full">
                  <button
                    onClick={() => handleIncrement("alcohol")}
                    className="w-full flex-1 flex items-center justify-center border-b border-[#FFEBEB] hover:bg-[#FFCCCC]"
                  >
                    <img src="/chevron_up.svg" alt="Increase" />
                  </button>
                  <button
                    onClick={() => handleDecrement("alcohol")}
                    className="w-full flex-1 flex items-center justify-center hover:bg-[#FFCCCC]"
                  >
                    <img src="/chevron_down.svg" alt="Decrease" />
                  </button>
                </div>
              </div>
            </div>

            <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/6 text-right">
              ${calculateTotal(alcoholRate, alcoholQuantity)}
            </p>
          </div>
          {/* Non-Alcohol row */}
          <div className="flex justify-between gap-x-3 md:gap-x-0 items-center text-gray-800">
            <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/2">
              Restaurants without alcohol
            </p>
            <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/6 text-right">
              ${nonAlcoholRate}
            </p>
            {/* Quantity Display */}
            <div className="w-1/6 flex justify-end items-end">
              <div className="flex items-center justify-center border border-[#FFEBEB] rounded-md w-[40px] md:w-[60px] h-[35px] overflow-hidden">
                <div className="bg-white flex items-center justify-center w-[70%] h-full">
                  <p className="text-[#222222] font-normal text-[12px] md:text-[14px] leading-none">
                    {String(nonAlcoholQuantity).padStart(2, "0")}
                  </p>
                </div>
                <div className="flex flex-col items-center justify-between bg-[#FFEBEB] w-[40%] h-full">
                  <button
                    onClick={() => handleIncrement("nonAlcohol")}
                    className="w-full flex-1 flex items-center justify-center border-b border-[#FFEBEB] hover:bg-[#FFCCCC]"
                  >
                    <img src="/chevron_up.svg" alt="Increase" />
                  </button>
                  <button
                    onClick={() => handleDecrement("nonAlcohol")}
                    className="w-full flex-1 flex items-center justify-center hover:bg-[#FFCCCC]"
                  >
                    <img src="/chevron_down.svg" alt="Decrease" />
                  </button>
                </div>
              </div>
            </div>
            <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/6 text-right">
              ${calculateTotal(nonAlcoholRate, nonAlcoholQuantity)}
            </p>
          </div>

          {canModify && (
            <div className="flex justify-between gap-x-3 md:gap-x-0 items-center text-gray-800">
              <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/2">
                Multi Venues
              </p>
              <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/6 text-right">
                ${multiVenueRate}
              </p>
              {/* Quantity Display */}
              <div className="w-1/6 flex justify-end items-end">
                <div className="flex items-center justify-center border border-[#FFEBEB] rounded-md w-[40px] md:w-[60px] h-[35px] overflow-hidden">
                  <div className="bg-white flex items-center justify-center w-[70%] h-full">
                    <p className="text-[#222222] font-normal text-[12px] md:text-[14px] leading-none">
                      {String(multiVenueQuantity).padStart(2, "0")}
                    </p>
                  </div>
                  <div className="flex flex-col items-center justify-between bg-[#FFEBEB] w-[40%] h-full">
                    <button
                      onClick={() => handleIncrement("multiVenue")}
                      className="w-full flex-1 flex items-center justify-center border-b border-[#FFEBEB] hover:bg-[#FFCCCC]"
                    >
                      <img src="/chevron_up.svg" alt="Increase" />
                    </button>
                    <button
                      onClick={() => handleDecrement("multiVenue")}
                      className="w-full flex-1 flex items-center justify-center hover:bg-[#FFCCCC]"
                    >
                      <img src="/chevron_down.svg" alt="Decrease" />
                    </button>
                  </div>
                </div>
              </div>
              <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/6 text-right">
                ${calculateTotal(multiVenueRate, multiVenueQuantity)}
              </p>
            </div>
          )}

          {/* single venue row */}
          {canModify && (
            <div className="flex justify-between gap-x-3 md:gap-x-0 items-center text-gray-800">
              <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/2">
                Single Venue
              </p>
              <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/6 text-right">
                ${singleVenueRate}
              </p>
              {/* Quantity Display */}
              <div className="w-1/6 flex justify-end items-end">
                <div className="flex items-center justify-center border border-[#FFEBEB] rounded-md w-[40px] md:w-[60px] h-[35px] overflow-hidden">
                  <div className="bg-white flex items-center justify-center w-[70%] h-full">
                    <p className="text-[#222222] font-normal text-[12px] md:text-[14px] leading-none">
                      {String(singleVenueQuantity).padStart(2, "0")}
                    </p>
                  </div>
                  <div className="flex flex-col items-center justify-between bg-[#FFEBEB] w-[40%] h-full">
                    <button
                      onClick={() => handleIncrement("singleVenue")}
                      className="w-full flex-1 flex items-center justify-center border-b border-[#FFEBEB] hover:bg-[#FFCCCC]"
                    >
                      <img src="/chevron_up.svg" alt="Increase" />
                    </button>
                    <button
                      onClick={() => handleDecrement("singleVenue")}
                      className="w-full flex-1 flex items-center justify-center hover:bg-[#FFCCCC]"
                    >
                      <img src="/chevron_down.svg" alt="Decrease" />
                    </button>
                  </div>
                </div>
              </div>
              <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/6 text-right">
                ${calculateTotal(singleVenueRate, singleVenueQuantity)}
              </p>
            </div>
          )}

          {/* trial badge */}
          <div className="">
            <CouponTextfieldSubscription />
          </div>
          <CouponInputFieldSubscription onApplyDiscount={validatePromoCode} />

          {/* Summary */}
          <div className="mt-4 pt-4 text-[#222222] font-medium">
            <div className="flex justify-between text-[12px] md:text-[16px]">
              <p className="text-[#717171] font-normal">
                New Subscription Total
              </p>
              <p className="text-[#717171] font-medium">$ {totalCost}</p>
            </div>
            {/* <div className="flex justify-between text-[12px] md:text-[16px] mt-[16px]">
              <p className="text-[#717171] font-normal">
                Current Subscription Total
              </p>
              <p className="text-[#717171] font-medium">${dueAmount}</p>
            </div> */}
            {/* Base Charge Row */}
            {/* {!canModify && (
              <div className="flex justify-between text-[12px] md:text-[16px] mt-[16px]">
                <p className="text-[#717171] font-normal">Base Charge</p>
                <p className="text-[#717171] font-medium"> ${baseCharge}</p>
              </div>
            )} */}

            {discount !== 0 && (
              <div className="flex justify-between text-[12px] md:text-[16px] mt-[16px]">
                <p className="text-[#717171] font-normal">Discount</p>
                <p className="text-[#717171] font-medium"> {discount}%</p>
              </div>
            )}

            {/* <div className="flex justify-between text-[12px] md:text-[16px] mt-[16px]">
              <p className="text-[#717171] font-normal">Additional Payment</p>
              <p className="text-[#717171] font-medium">${proratedAmount}</p>
            </div> */}
            {vatServiceFee > 0 && (
              <div className="flex justify-between text-[12px] md:text-[16px] mt-[16px]">
                <p className="text-[#717171] font-normal">
                  VAT service fee (5%)
                </p>
                <p className="text-[#717171] font-medium">${vatServiceFee}</p>
              </div>
            )}
          </div>

          {/* border */}
          <div className="border border-t border-[#DDDDDD]"></div>

          <div className="flex justify-between text-black font-bold mt-4">
            <p className="text-[#5E5E5E] font-normal">Grand Total</p>
            <p>
              ${grandTotal}{" "}
              <span className="text-[#B0B0B0] font-normal">/year</span>
            </p>
          </div>
          <div className="flex flex-col md:flex-row md:justify-between items-start">
            <p className="text-xs text-gray-600 ">
              Enjoy a zero-dollar payment today, with your subscription plan
              starting after six months, <br className="hidden md:inline" />{" "}
              when you&apos;ll be charged <br className="hidden md-inline" /> on
              &nbsp;{moment(user?.trialEnd).format("MMMM D, YYYY")}
            </p>
            <div className=" flex items-start justify-start">
              <p className="text-[16px] text-black font-medium  whitespace-nowrap">
                Pay now: $0
              </p>
            </div>
          </div>
          {/* continue/ proceed */}
          <div className="mx-auto w-full  flex items-center justify-center">
            {startLoading ? (
              <>
                <button className="bg-gray-400 flex gap-x-3 justify-center items-center  active:opacity-55  text-white p-2 rounded-lg w-full md:w-[400px] mt-3 md:mt-[25px]">
                  Modifying <MdScatterPlot className=" animate-spin" />
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={handleSubscription}
                  className="bg-[#FE4747]  active:opacity-55  text-white p-2 rounded-lg w-full md:w-[400px] mt-3 md:mt-[25px]"
                >
                  Continue
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModifyModal;
