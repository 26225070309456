import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import TitleDescriptionCoupon from "../components/TitleDescriptionCoupon";
// import MultiChipSelector from "../components/MultiChipSelector";
import MessageCard from "../components/MessageCard";
import CircularProgressLoader from "../../../components/CircularProgressLoader";
import VenueCard from "../components/VenueCard";
import calculateIndividualPayment from "../../../utils/calculateIndividualPayment";
import PaymentIndividualView from "../payment/PaymentIndividualView";
import BillingForm from "../components/BillingForm";
import { payment } from "../../../api/hotelPayment";
import { fetchLoggedUser } from "../../../api/loggedUser";

function IndividualFlow({
  activeStep,
  handleContinue,
  toggleBackButton,
  isBackButtonClicked,
  resetBackButtonClicked,
}) {
  const [user, setUser] = useState();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetchLoggedUser();
        setUser(response.vendor);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, []);
  const [quantityNumber, setQuantityNumber] = useState(1);
  const [selectedChips, setSelectedChips] = useState([]);
  const [paymentData, setPaymentData] = useState({});
  const [loading, setLoading] = useState(false);
  const [billingInformation, setBillingInformation] = useState(null);

  const handleQuantityNumberChange = (event) => {
    setQuantityNumber(event.target.value);
  };

  const handleSelectedChipsChange = (chips) => {
    setSelectedChips(chips);
  };

  // To hide the Back Button on the Message View
  useEffect(() => {
    let timeoutId;

    if (activeStep === 4) {
      toggleBackButton(false);
      const data = calculateIndividualPayment();
      setPaymentData(data);

      // Delay the continue action by 3 seconds
      timeoutId = setTimeout(() => {
        handleContinue();
      }, 3000);
    } else {
      toggleBackButton(true);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [activeStep, toggleBackButton, handleContinue]);

  const handlePayment = async () => {
    const singlechoosed = localStorage.getItem("singlechoosed");
    // Check if storedGrandTotal is not null
    const amountsinglechoosed = singlechoosed ? parseInt(singlechoosed, 10) : 0;
    const paymentPayload = {
      email: user?.email,
      cardHolderName: "Venue",
      savedForFuture: true,
      amount: "500",
      // amount: paymentData.grandTotal,
      // amount:amountsinglechoosed,
      description: "Payment for venue",
      billingInformation: billingInformation,
    };

    try {
      setLoading(true);
      const response = await payment(paymentPayload);
      if (
        response.success &&
        response.session &&
        typeof response.session.url === "string"
      ) {
        window.location.href = response.session.url;
      } else {
        console.error("URL not found or invalid");
      }
      if (!response.ok) {
        throw new Error("Payment failed");
      }

      const result = await response.json();

      if (result.session && result.session.url) {
        window.location.href = result.session.url;
        return; // Exit the function early as we're redirecting
      }

      // If no redirect URL, continue with the process
      handleContinue();
    } catch (error) {
      console.error("Error:", error);
      // Handle the error appropriately (e.g., show an error message to the user)
    } finally {
      setLoading(false);
    }
  };

  // Basically the logic is to redirect the user to the type of venue screen by reloading, when the user clicks the backbutton on the active step 5 which is plan screen.
  // useEffect(() => {
  //   if (activeStep === 5 && isBackButtonClicked) {
  //     window.location.reload();
  //     resetBackButtonClicked();
  //   }
  // });

  const handleContinueWithPayment = (shouldHandlePayment) => {
    if (shouldHandlePayment) {
      handlePayment();
    } else {
      handleContinue();
    }
  };
  useEffect(() => {
    if (activeStep === 7) {
      handlePayment();
    }
  }, [activeStep]);

  return (
    <>
      {/* {activeStep === 2 && (
        <Box sx={{ width: { xs: "100", md: "70%" } }}>
          <TitleDescriptionCoupon
            title={"Tell us more about your venue"}
            description={"This will help us find the best plan for you"}
            isCoupon={true}
          />
          <MultiChipSelector
            onSelectedChipsChange={handleSelectedChipsChange}
            isSelectOneChip={true}
          />
        </Box>
      )} */}
      {activeStep === 3 && (
        <Box sx={{ width: { xs: "100", md: "70%" } }}>
          <TitleDescriptionCoupon
            title={"Tell us more about your venue"}
            description={"This will help us find the best plan for you"}
            isCoupon={true}
          />
          <VenueCard
            toolTipText={
              "A venue that is designed as a kids play area or activity centre, offering space for hosting kids events. This would exclude any areas designated for restaurant services."
            }
            quantityNumber={quantityNumber}
            handleQuantityNumberChange={handleQuantityNumberChange}
            title={"Venues"}
            description={selectedChips[0]}
          />
        </Box>
      )}
      {activeStep === 4 && (
        <MessageCard
          loaderComponent={<CircularProgressLoader color={"red"} />}
          primaryText={
            "Creating a plan suitable to your venue. Please wait a moment"
          }
          secondaryText={"Please wait for a moment..."}
        />
      )}
      {activeStep === 5 && (
        <PaymentIndividualView
          paymentData={paymentData}
          handleContinue={handleContinue}
        />
      )}
      {/* 6 - Billing */}
      {activeStep === 6 && (
        <BillingForm
          handleContinue={handleContinueWithPayment}
          shouldHandlePayment={true}
          loading={loading}
          setBillingInformation={setBillingInformation}
        />
      )}
      {/* 7 - Payment Message Card */}
      {activeStep === 7 && <>{/* Redirect logic here */}</>}

      {activeStep < 4 && (
        <Box sx={{ width: "70%", mt: 2 }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "primary.main",
              color: "white",
              textTransform: "none",
              mt: "1rem",
              boxShadow: "none",
              width: { xs: "100%", md: "70%" },
            }}
            onClick={handleContinue}
          >
            Continue
          </Button>
        </Box>
      )}
    </>
  );
}

export default IndividualFlow;
