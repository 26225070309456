import React, { useState, useEffect, useRef } from "react";
import Input from "../../shared/common/customInput";
import { FaRegCalendarAlt } from "react-icons/fa";
import { CloseOutlined } from "@mui/icons-material";
import DatePicker from "react-datepicker";

const OfferModal = ({ onClose, onSave, offerToEdit = null }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [errors, setErrors] = useState({});
  const [descriptionCount, setDescriptionCount] = useState(0);

  const currentDate = new Date(); // Current date in YYYY-MM-DD format
  const modalRef = useRef(null);

  useEffect(() => {
    if (offerToEdit) {
      setTitle(offerToEdit.title);
      setDescription(offerToEdit.description);
      setStartDate(offerToEdit.startDate);
      setEndDate(offerToEdit.endDate);
      setDescriptionCount(offerToEdit?.description?.length || 0);
    } else {
      // setStartDate(null);
      // setEndDate(null);
    }
  }, [offerToEdit]);

  useEffect(() => {
    // Function to handle clicks outside the modal
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    // Attach event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleSave = () => {
    const newErrors = {};

    // Validate required fields
    if (!title.trim()) {
      newErrors.title = "Offer title is required.";
    }
    if (!description.trim()) {
      newErrors.description = "Offer description is required.";
    }
    // if (!startDate) {
    //   newErrors.startDate = "Start date is required.";
    // }
    if (!endDate) {
      newErrors.endDate = "End date is required.";
    }

    // Validate date fields
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    // Validate start date: should be today or a future date
    if (startDate && startDateObj < currentDate.setHours(0, 0, 0, 0)) {
      newErrors.startDate = "Start date must be today or a future date.";
    }

    // Validate end date: should be today or a future date, and after the start date
    if (endDate && endDateObj < currentDate.setHours(0, 0, 0, 0)) {
      newErrors.endDate = "End date must be today or a future date.";
    } else if (endDate && endDateObj < startDateObj) {
      newErrors.endDate = "End date must be after the start date.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const offerData = { title, description, startDate, endDate };
    onSave(offerToEdit ? { ...offerToEdit, ...offerData } : offerData);
    onClose();
  };
  const handleDateChange = (date) => {
    const selectedDate = date;
    setStartDate(selectedDate);

    // Convert the selected date to dd/mm/yyyy with full month or 3-letter abbreviation
    const dateObject = new Date(selectedDate);
    if (isNaN(dateObject)) {
      setErrors({ startDate: "Invalid date format" });
      setFormattedDate("");
    } else {
      const formatted = dateObject.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "long", // Use 'short' for 3-letter abbreviation
        year: "numeric",
      });
      setFormattedDate(formatted);
      setErrors({});
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);

    // Reset errors
    const newErrors = { ...errors, endDate: "" };

    // Validation logic
    if (date && date < currentDate) {
      newErrors.endDate = "End date cannot be in the past";
    }

    setErrors(newErrors);
  };
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div
        ref={modalRef} // Attach ref to modal container
        className="bg-white p-6 rounded-lg shadow-lg w-1/2 flex flex-wrap justify-between"
      >
        <div className="w-full flex items-center justify-between">
          <h2 className="lg:text-3xl sm:text-2xl text-xl font-bold text-neutralBlack mb-2">
            {offerToEdit ? "Edit Offer" : " Offer & Packages"}
          </h2>
          <CloseOutlined
            className="cursor-pointer w-[2px] h-[24px]"
            onClick={() => onClose()}
          />
        </div>

        <div className=" w-full">
          <p className="mb-3  font-outfitt text-sm text-offGray">
            Add an offer on top of your pricing
          </p>
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
            <div className="  w-full">
              <Input
                label="Offer Title"
                type="text"
                placeholder="Enter Offer Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                className={errors.title ? "border-red-500" : ""}
              />
            </div>
            {errors.title && (
              <p className="text-red-500 text-sm">{errors.title}</p>
            )}
          </div>
        </div>

        <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 w-full">
          <div className=" w-full mb-4">
            <label className="mb-2 flex items-center space-x-2">
              <span className="text-secondary text-sm">From </span>
            </label>
            <div className=" customDatePickerWidth">
              {/* <input
              type="date"
              min={currentDate}
              value={startDate}
              onChange={handleDateChange}
              className={`w-full py-2 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent ${
                errors.startDate ? "border-red-500" : ""
              }`}
            /> */}
              <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                minDate={currentDate} // Prevent selection of past dates
                dateFormat="dd-MM-yyyy"
                placeholderText="Select start date"
                className={`w-full py-2 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent ${
                  errors.startDate ? "border-red-500" : ""
                }`}
              />
              {errors.startDate && (
                <p className="text-red-500 text-sm mt-1">{errors.startDate}</p>
              )}
            </div>
          </div>

          <div className=" w-full mb-4">
            <label className="mb-2 flex items-center space-x-2">
              <span className="text-secondary text-sm">To </span>
            </label>
            <div className=" customDatePickerWidth">
              {/* <input
              type="date"
              min={currentDate}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className={`w-full py-2 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent ${
                errors.endDate ? "border-red-500" : ""
              }`}
              required
            /> */}
              <DatePicker
                selected={endDate}
                onChange={handleEndDateChange}
                minDate={currentDate} // Prevent selection of past dates
                dateFormat="dd-MM-yyyy"
                placeholderText="Select end date"
                className={`w-full py-2 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent ${
                  errors.endDate ? "border-red-500" : ""
                }`}
              />

              {errors.endDate && (
                <p className="text-red-500 text-sm mt-1">{errors.endDate}</p>
              )}
            </div>
          </div>
        </div>

        <div className="mb-4 w-full">
          <label className=" text-secondary flex justify-between items-center text-sm font-outfitt mb-2">
            Offer Description
            <p
              className={`text-offGray text-sm font-outfitt ${
                descriptionCount === 300 ? "text-red-500" : "text-offGray"
              }`}
            >
              ({descriptionCount}/300 Words)
            </p>
          </label>
          <textarea
            rows={5}
            value={description}
            onChange={(e) => {
              setDescriptionCount(e.target.value?.length);
              setDescription(e.target.value);
            }}
            className={`w-full p-2 border border-gray-300 rounded-md resize-none ${
              errors.description ? "border-red-500" : ""
            }`}
            maxLength={300}
            required
            placeholder="Enter offer description"
          />
          {errors.description && (
            <p className="text-red-500 text-sm mt-1">{errors.description}</p>
          )}
        </div>

        <button
          className="bg-primary w-full text-white px-4 py-2 rounded-md"
          onClick={handleSave}
        >
          {offerToEdit ? "Update Offer" : "Save Offer"}
        </button>
      </div>
    </div>
  );
};

export default OfferModal;
