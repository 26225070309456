import React, { useContext, useState } from 'react';
import { VenueContext } from '../../../context/VenueContext'; // Import your context
import { categories } from '../venu-data'; // Import the categories data
import SelectableTags from '../../shared/common/selectableTags'; // Assuming you have this component
import CustomCheckbox from '../../shared/common/checkBox'; // Assuming you have this component
// import ToggleSwitch from '../../shared/common/custom-toggle'; // Assuming you have this component

// Export the validateEventTypeSelection function so it can be imported elsewhere
export const validateEventTypeSelection = (formData, setFormData) => {
	const eventType = formData?.eventType || {};
	let isAnySelected = false;

	// Check if any event type option is selected
	for (let subcategory in eventType) {
		if (
			Array.isArray(eventType[subcategory]) &&
			eventType[subcategory].length > 0
		) {
			isAnySelected = true;
			break; // If at least one option is selected, stop checking further
		}
	}

	// Update the error state in the context
	setFormData((prevData) => ({
		...prevData,
		errors: {
			...prevData.errors,
			eventType: {
				...prevData.errors?.eventType,
				general: isAnySelected
					? ''
					: 'Please select at least one event type.',
			},
		},
	}));

	return isAnySelected; // Return true if at least one option is selected
};

const EventTypeForm = () => {
	const { formData, setFormData } = useContext(VenueContext);

	// Initialize state from context
	const selectedOptions = formData?.eventType || {};
	const kidVenue = formData?.eventVenuesForKids || false;
	const errors = formData?.eventType?.errors || {};
	console.log(errors, 'eventType errors');
	// Update context with new selected options
	const updateSelectedOptions = (subcategoryName, options) => {
		setFormData((prevData) => ({
			...prevData,
			eventType: {
				...prevData.eventType,
				[subcategoryName]: options,
			},
			errors: {
				...prevData.errors,
				eventType: '', // Reset errors on selection
			},
		}));
	};

	// Toggle selected options
	const handleSelectAll = (subcategoryName) => {
		const subcategory = categories
			.flatMap((cat) => cat.subcategories)
			.find((sub) => sub.name === subcategoryName);

		if (subcategory) {
			const updatedOptions =
				selectedOptions[subcategoryName]?.length ===
				subcategory.options.length
					? []
					: subcategory.options;

			updateSelectedOptions(subcategoryName, updatedOptions);
		}
	};

	// Handle option change
	const handleOptionChange = (subcategoryName, option) => {
		const currentOptions = selectedOptions[subcategoryName] || [];
		const updatedOptions = currentOptions.includes(option)
			? currentOptions.filter((opt) => opt !== option)
			: [...currentOptions, option];

		updateSelectedOptions(subcategoryName, updatedOptions);
	};

	// Check if all options are selected
	const isAllSelected = (subcategoryName) => {
		const subcategory = categories
			.flatMap((cat) => cat.subcategories)
			.find((sub) => sub.name === subcategoryName);

		return subcategory
			? selectedOptions[subcategoryName]?.length ===
					subcategory.options.length
			: false;
	};

	// Handle toggle for kid-friendly venues
	const handleKidVenueToggle = () => {
		setFormData((prevData) => ({
			...prevData,
			eventVenuesForKids: !kidVenue,
			// Clear the list if toggling off, otherwise keep the existing list
			eventVenuesForKidsIncludes: !kidVenue
				? []
				: prevData.eventVenuesForKidsIncludes,
		}));
	};

	// Handle selected kid venues change
	const handleKidVenueOptionChange = (option) => {
		const currentOptions = formData.eventVenuesForKidsIncludes || [];
		const updatedOptions = currentOptions.includes(option)
			? currentOptions.filter((opt) => opt !== option)
			: [...currentOptions, option];

		setFormData((prevData) => ({
			...prevData,
			eventVenuesForKidsIncludes: updatedOptions,
		}));
	};

	return (
		<div className='p-2'>
			<h1 className='text-3xl lg:text-2xl text-darkGray font-semibold mb-4'>
				{/* Event Type ```` <span className="text-red-500 ml-1">*</span> */}
				Event Type <span className='text-red-500 ml-1'>*</span>
			</h1>

			{categories.map((category) => (
				<div key={category.name} className='mb-6'>
					<h2 className='text-base font-medium text-darkGray mb-2'>
						{category.name}
					</h2>
					{category.subcategories.map((subcategory) => (
						<div key={subcategory.name} className='mb-4'>
							<div className='flex items-center justify-between w-full mb-2'>
								<h3 className='text-sm text-secondary font-normal mr-2'>
									{subcategory.name}
								</h3>
								<CustomCheckbox
									label='Select all'
									checked={isAllSelected(subcategory.name)}
									onChange={() => handleSelectAll(subcategory.name)}
								/>
							</div>
							<div className='flex flex-wrap'>
								<SelectableTags
									tags={subcategory.options}
									selectedTags={
										selectedOptions[subcategory.name] || []
									}
									onTagClick={(option) =>
										handleOptionChange(subcategory.name, option)
									}
								/>
							</div>
						</div>
					))}
				</div>
			))}

			{errors.eventType !== '' && (
				<p className='text-red-700 text-[12px]'>{errors.eventType}</p>
			)}
			{/* <div className="mb-4">
        <label className="flex items-center">
          <span className="text-darkGray font-normal mr-2">
            Do you have event venues for kids
          </span>
          <ToggleSwitch isOn={kidVenue} handleToggle={handleKidVenueToggle} />
        </label>
      </div>
      {kidVenue && (
        <div className="flex flex-wrap">
          {venueKid.map((option) => (
            <SelectableTags
              max={10}
              key={option}
              tags={[option]}
              selectedTags={formData.eventVenuesForKidsIncludes || []}
              onTagClick={() => handleKidVenueOptionChange(option)}
            />
          ))}
        </div>
      )} */}
		</div>
	);
};

export default EventTypeForm;
