import React from "react";
import HotelCarousel from "./HotelCarousel";

const ExploreHotel = ({ hotels }) => {
  return (
    <div className="flex justify-center pt-20">
      <div className="w-[90%] mt-8">
        <h1 className="sm:text-4xl text-2xl text-darkGray font-semibold text-center my-4">
          Looking for Hotel stay with Venues
        </h1>
        <HotelCarousel Data={hotels} />
      </div>
    </div>
  );
};

export default ExploreHotel;
