/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useState, useEffect } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { updateHotelForm } from "../../api/hotel-api";
import { toast } from "react-toastify";
import { HotelContext } from "../../context/HotelContext";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Add carousel styles
import { MdClose, MdFullscreen } from "react-icons/md";
import Gallery from "./gallery";
const HeroSection = ({ data, videos, id }) => {
  const navigate = useNavigate();
  // Function to navigate back to the Preview step
  const { setCurrentStep } = useContext(HotelContext);
  // Function to navigate back to step 6 (Preview)
  const handleBackToPreview = () => {
    setCurrentStep(6); // Navigate to step 6 (Preview)
    navigate(`/hostel-Listing-Form/${id}`); // Programmatically navigate to the Preview page
  };

  const { formData } = useContext(HotelContext);

  const vendorId = localStorage.getItem("vendorId");
  useEffect(() => {
    if (!vendorId) {
      alert("Vendor Id is not available");
      // window.location.href = '/hotel-listing';
    }
  }, []);

  const [isExpanded, setIsExpanded] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal
  const handleSaveData = async (status = "pending") => {
    if (formData?.overview?.locationDetails?.addressLine1 == "") {
      toast.error("Data is missing in location");
      return;
    }
    if (formData?.photoVideo?.images?.length == 0) {
      toast.error("Images are missing");
      return;
    }
    let errorMessage = "";
    let hasErrors = false;

    // Validate Overview step
    const validateOverview = () => {
      const basicDetails = formData?.overview?.basicDetails || {};
      const additionalDetails = formData?.overview?.additionalDetails || {};
      return (
        !basicDetails?.hotelName ||
        !basicDetails?.hotelRating ||
        !basicDetails?.hotelWebsite ||
        !basicDetails?.hotelDescription ||
        !additionalDetails?.hotelBuiltYear ||
        !additionalDetails?.tradeLicenseFile
      );
    };

    // Validate Accommodation step
    const validateAccommodation = () => {
      const accommodation = formData?.accommodation || {};
      return (
        !accommodation?.accommodations && accommodation?.accommodations !== 0
      );
    };

    // Validate Pricing step
    const validatePricing = () => {
      const pricing = formData?.pricing || {};
      return !pricing?.basePrice;
    };

    // Validate Photos & Videos step
    const validatePhotosAndVideos = () => {
      const photoVideo = formData?.photoVideo || {};
      return !photoVideo?.images || photoVideo.images.length === 0;
    };

    // Run validations
    if (validateOverview()) {
      errorMessage += "Data is missing in Overview.\n";
      hasErrors = true;
    }
    if (validateAccommodation()) {
      errorMessage += "Data is missing in Accommodation.\n";
      hasErrors = true;
    }
    if (validatePricing()) {
      errorMessage += "Data is missing in Pricing.\n";
      hasErrors = true;
    }
    if (validatePhotosAndVideos()) {
      errorMessage += "Data is missing in Photos & Videos.\n";
      hasErrors = true;
    }

    // Show error messages if there are any
    if (hasErrors) {
      toast.error(errorMessage.trim());
      return; // Exit the function early if there are errors
    }

    // Determine the updated status
    const updatedItemStatus =
      formData?.itemStatus === "Approved"
        ? "Edit Pending"
        : formData?.itemStatus === "Rejected"
        ? "ReSubmitting"
        : "pending";

    // Manually construct the updated form data
    const updatedFormData = {
      ...formData,
      itemStatus: updatedItemStatus,
      vendorId: vendorId,
      status: status === "completed" ? "completed" : "pending", // Ensure status is set correctly
    };

    // Save the hotel data
    try {
      const response = await updateHotelForm(updatedFormData);

      if (response.success) {
        toast.success("Hotel detail sent for review!");
        if (status === "completed") {
          setTimeout(() => {
            navigate("/dashboard?hotelRegister=true");
          }, 1000);
        }
      } else {
        toast.error(response.error || "An error occurred. Please try again.");
      }
    } catch (error) {
      toast.error(
        error.response?.data?.error || "An error occurred. Please try again."
      );
      console.error("Error:", error);
    }
  };

  // Toggle modal visibility
  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  // Open the modal with all photos
  const handleShowAllPhotos = () => {
    setIsModalOpen(true);
  };

  // Close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const toggleFullScreen = () => {
    const elem = document.querySelector(".custom-carousel");
    if (!document.fullscreenElement) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  return (
    <div className="p-6">
      <div className="flex flex-wrap lg:px-20 justify-between gap-5 items-center w-full mb-6">
        <button
          onClick={handleBackToPreview} // Trigger back to Preview step on click
          className="flex items-center text-gray-600 cursor-pointer"
        >
          <FaArrowLeft className="mr-2" />
          <span>Back </span>
        </button>
        <div className="flex gap-3 items-center">
          <div className="bg-black px-2 md:px-0 md:w-[200px] h-[40px] gap-x-2 flex items-center justify-center rounded-lg">
            <img src="/tick.svg" alt="" />
            <button
              onClick={() => handleSaveData("completed")}
              className=" text-white  "
            >
              Submit for review
            </button>
          </div>
          <Link
            to={`/hostel-Listing-Form/Hotel/${id}`}
            className="bg-red-500 text-white px-2 md:px-0 md:w-[200px] h-[40px] justify-center rounded-lg flex items-center"
          >
            <AiOutlineEdit className="mr-2" /> Edit
          </Link>
        </div>
      </div>
      {/* Hotel name and previewlisting card button */}
      <div className="md:flex justify-between lg:px-20 mb-5">
        <div>
          <h2 className="md:text-[32px] text-[24px] font-bold flex gap-4 items-center">
            Hotel Detail Page
          </h2>
        </div>
        {/* preview button */}

        <div
          className="active:opacity-30 mt-1 flex items-center justify-center text-[#222222] font-[500px] px-2 border border-[#222222] rounded-lg md:px-0 md:w-[250px] h-[40px] gap-x-3 cursor-pointer"
          onClick={handleBackToPreview} // Trigger back to Preview step on click}
        >
          <img src="/eye.svg" alt="eye" />
          <button className="">Preview Listing Card</button>
        </div>
      </div>

      <Gallery
        photos={data}
        videos={videos}
        handleExpandClick={handleExpandClick}
        handleShowAllPhotos={handleShowAllPhotos}
      />

      {/* Modal for showing all photos */}
      {isModalOpen && (
        <div className="custom-carousel z-[5000] absolute inset-0 bg-black/80 flex w-[98vw] m-auto items-center justify-center">
          <div className="text-[16px] text-white flex gap-x-2 absolute top-2 z-[5000] ">
            <p>{`${activeIndex + 1} / ${data?.length + videos?.length} `}</p>
            <button
              className="text-white mr-3 text-lg cursor-pointer"
              onClick={toggleFullScreen}
            >
              <MdFullscreen />
            </button>
            <button
              className="text-white text-lg cursor-pointer"
              onClick={handleCloseModal}
            >
              <MdClose />
            </button>
          </div>

          <Carousel
            selectedItem={activeIndex}
            showArrows
            onChange={(index) => setActiveIndex(index)}
            showThumbs={true}
            showStatus={false}
            infiniteLoop
            className=" custom-carousel"
            showIndicators={false}
            // renderArrowPrev={(onClickHandler, hasPrev) => (
            //   <CustomPrevButton onClick={onClickHandler} disabled={!hasPrev} />
            // )}
            // renderArrowNext={(onClickHandler, hasNext) => (
            //   <CustomNextButton onClick={onClickHandler} disabled={!hasNext} />
            // )}
          >
            {data?.map((image, index) => (
              <div className="md:h-[556px] h-[228px] max-h-[566px] max-w-[100vw]">
                <img
                  key={index}
                  src={image}
                  alt={`Image ${index}`}
                  className="object-contain  overflow-hidden h-full w-full"
                />
              </div>
            ))}
            {videos?.map((video, index) => {
              // Check if the video is a YouTube link or .mp4 file
              const isYouTubeLink =
                video.includes("youtube.com") || video.includes("youtu.be");

              return isYouTubeLink ? (
                <div
                  key={index + data?.length + 1}
                  className="w-[90%] sm:w-[69%] m-auto h-[590px] relative mb-1"
                >
                  <iframe
                    className="rounded-lg w-full h-full object-cover cursor-pointer"
                    src={`https://www.youtube.com/embed/${
                      video.split("v=")[1] || video.split("/").pop()
                    }`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded YouTube Video"
                    onLoadedMetadata={(e) => (e.target.currentTime = 1)}
                    preload="metadata"
                  />
                </div>
              ) : (
                <video
                  key={index + data?.length + 1}
                  className="rounded-lg mb-4 sm:w-[69%] h-[590px] w-full  object-cover cursor-pointer mx-auto"
                  controls
                  onLoadedMetadata={(e) => (e.target.currentTime = 1)}
                  preload="metadata"
                >
                  <source src={video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              );
            })}
          </Carousel>
        </div>
      )}
    </div>
  );
};

export default HeroSection;
