// src/components/CustomCheckbox.js
import React, { useRef } from "react";

const CustomCheckbox = ({ checked, onChange, label }) => {
  const checkboxRef = useRef(null);

  const handleSpanClick = () => {
    if (checkboxRef.current) {
      checkboxRef.current.click();
    }
  };
  return (
    <div className="inline-flex items-center cursor-pointer">
      <input
        ref={checkboxRef}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="hidden"
      />
      <span
        onClick={() => handleSpanClick()}
        className={`w-6 h-6 border-2 flex items-center justify-center z-50 ${
          checked ? "border-green1" : "border-secondary"
        }`}
      >
        {checked && (
          <svg
            className="w-4 h-4 text-green1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        )}
      </span>
      {label && (
        <span onClick={() => handleSpanClick()} className="ml-2 text-darkGray">
          {label}
        </span>
      )}
    </div>
  );
};

export default CustomCheckbox;
