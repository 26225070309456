import React, { useContext } from "react";
import ToggleSwitch from "../shared/common/custom-toggle";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { VenueContext } from "../../context/VenueContext";
import Cookies from "js-cookie";

const SpaceRules = () => {
  const { formData, setFormData } = useContext(VenueContext);

  // Provide default values if spaceRules is undefined
  const spaceRules = formData.spaceRules || {
    agePolicy: [0, 100],
    moreSpaceRules: "",
    ticketedEventsAllowed: false,
  };

  const { agePolicy, moreSpaceRules, ticketedEventsAllowed } = spaceRules;

  const handleSliderChange = (value) => {
    const [minAge, maxAge] = value;

    // Deem no limit based on the slider's position
    const updatedMinAge = minAge === 0 ? 0 : minAge;
    const updatedMaxAge = maxAge === 99 ? 99 : maxAge;

    const updatedSpaceRules = {
      ...spaceRules,
      agePolicy: [updatedMinAge, updatedMaxAge],
    };

    setFormData((prevData) => {
      const newFormData = {
        ...prevData,
        spaceRules: updatedSpaceRules,
      };
      Cookies.set("formData", JSON.stringify(newFormData), { expires: 7 }); // Save to cookies
      return newFormData;
    });
  };

  const handleMoreRulesChange = (event) => {
    const updatedSpaceRules = {
      ...spaceRules,
      moreSpaceRules: event.target.value,
    };
    setFormData((prevData) => {
      const newFormData = {
        ...prevData,
        spaceRules: updatedSpaceRules,
      };
      Cookies.set("formData", JSON.stringify(newFormData), { expires: 7 }); // Save to cookies
      return newFormData;
    });
  };

  const handleToggleChange = () => {
    const updatedSpaceRules = {
      ...spaceRules,
      ticketedEventsAllowed: !spaceRules.ticketedEventsAllowed,
    };
    setFormData((prevData) => {
      const newFormData = {
        ...prevData,
        spaceRules: updatedSpaceRules,
      };
      Cookies.set("formData", JSON.stringify(newFormData), { expires: 7 }); // Save to cookies
      return newFormData;
    });
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Space Rules</h1>
      <h2 className="text-lg font-semibold mb-2">Age Policy</h2>
      <label htmlFor="moreRules" className="block text-sm text-secondary">
        Select age limit
      </label>

      <div className="slider mb-4 lg:w-3/5">
        <Slider
          range
          min={0}
          max={99}
          value={agePolicy}
          onChange={handleSliderChange}
          className="w-[90%] mx-auto"
        />
        <div className="flex justify-between mt-2">
          <span>
            {agePolicy[0] === 0 ? "No lower limit (0)" : agePolicy[0]}
          </span>
          <span>{agePolicy[1] === 60 ? "" : agePolicy[1]}</span>
        </div>
      </div>
      <div className="mb-4">
        <label
          htmlFor="moreRules"
          className="block text-lg mb-2 font-medium text-gray-500"
        >
          More space rules
        </label>
        <textarea
          id="moreRules"
          rows="5"
          className="mt-1 p-3 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          value={moreSpaceRules}
          onChange={handleMoreRulesChange}
        />
        {/* {moreSpaceRules?.length < 1 &&
                    <p className="text-primary pt-2">More space rules is Required</p>} */}
      </div>
      <div className="flex items-center gap-5">
        <label
          htmlFor="allowTicketedEvents"
          className="ml-3 block text-lg font-medium text-darkGray"
        >
          Ticketed events allowed?
        </label>

        <ToggleSwitch
          isOn={ticketedEventsAllowed}
          handleToggle={handleToggleChange}
        />
      </div>
    </div>
  );
};

export default SpaceRules;
