/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { BiDish } from 'react-icons/bi';
import SelectableTags from '../shared/common/selectableTags';
import ToggleSwitch from '../shared/common/custom-toggle';
import alcoholic1 from '../../assets/icons/catering/Alcohol.svg';
import alcoholic2 from '../../assets/icons/catering/corking.svg';
import alcoholic3 from '../../assets/icons/catering/bring.svg';
import alcoholic4 from '../../assets/icons/catering/external.svg';
import alcoholic5 from '../../assets/icons/catering/refreshmnet.svg';
import alcoholic6 from '../../assets/icons/catering/shisha.svg';
import { VenueContext } from '../../context/VenueContext';
import { fetchLoggedUser } from '../../api/loggedUser';
import { toast } from 'react-toastify';

const CateringAndDrinks = () => {
	const { formData, setFormData } = useContext(VenueContext);
	const [alcoholic, setAlcoholic] = useState(
		formData?.cateringAndDrinks?.isParentAlcoholic ||
			formData?.cateringAndDrinks?.isVenueAlcohlic ||
			false
	);
	const [alcoholicCount, setAlcoholicCount] = useState(0);
	const [user, setUser] = useState(null);

	useEffect(() => {
		const fetchUser = async () => {
			try {
				const response = await fetchLoggedUser();
				setUser(response.vendor);
			} catch (error) {
				console.log(error);
			}
		};
		fetchUser();
	}, []);

	
	// handle alchol toggle button
	const handleToggleChange = (field) => {
		setFormData((prevData) => ({
			...prevData,
			cateringAndDrinks: {
				...prevData.cateringAndDrinks,
				[field]: !prevData.cateringAndDrinks[field], // Toggles the value
			},
		}));
	};

	const handleSelectChange = (field, value) => {
		setFormData((prevData) => ({
			...prevData,
			cateringAndDrinks: {
				...prevData.cateringAndDrinks,
				[field]: value,
			},
		}));
	};

	const toggleSelection = (item, selectedItems) => {
		return selectedItems.includes(item)
			? selectedItems.filter((i) => i !== item)
			: [...selectedItems, item];
	};

	const handleAlcohlic = () => {
		if (
			formData?.basicDetails?.isPrimaryVenue &&
			(window.location.pathname.includes(
				'venu-restaurant-Form/Restaurant'
			) ||
				window.location.pathname.includes(
					'create-Listing/Restaurant'
				))
		) {
			return;
		}

		if (
			alcoholicCount <= user?.onBoardingDetails?.withAlcoholNumber
		) {
			setAlcoholic(!alcoholic);
		} else {
			toast.error('You exceeded your Alcoholic count');
		}
		setFormData((prevData) => ({
			...prevData,
			cateringAndDrinks: {
				...prevData.cateringAndDrinks,
				isVenueAlcohlic: !alcoholic,
			},
		}));
	};

	// create s state for the show time
	const [alcoholTime, setAlcoholTime] = useState(
		formData?.cateringAndDrinks?.alcoholServingTime || ''
	);

	const handleTimeChange = (field, value) => {
		setFormData((prevData) => ({
			...prevData,
			cateringAndDrinks: {
				...prevData.cateringAndDrinks,
				[field]: value, // Update the time value in form data
			},
		}));
		setAlcoholTime(value); // Update local state for time
	};

	const availableMenuOptions = [
		'Kosher',
		'Vegan',
		'Gluten free',
		'Halal',
		'Vegetarian',
		'Kids friendly menu',
	];
	const cuisineOptions = [
		'Chinese',
		'Indian',
		'Arabic',
		'Italian',
		'Lebanese',
		'Japanese',
		'American',
		'French',
		'Korean',
		'Mediterranean',
		'Mexican',
		'Thai',
		'Turkish',
		'Asian',
		'Middle Eastern',
		'International',
		'European',
		'Fusion',
		'Contemporary',
		'Barbeque',
		'Persian',
		'Brazilian',
		'Kosher',
		'Peruvian',
	];
	const activities = [
		'Live station',
		'Buffet',
		'Canapés/pass around',
		'Family sharing',
		'Sitdown service',
		'Others',
	];
	const generateTimeOptions = () => {
		const options = [];
		for (let i = 0; i < 24; i++) {
			const hour = i % 12 || 12;
			const ampm = i < 12 ? 'AM' : 'PM';
			options.push(
				<option
					key={`${i}:00`}
					value={`${String(i).padStart(2, '0')}:00`}
				>
					{`${hour}:00 ${ampm}`}
				</option>
			);
			options.push(
				<option
					key={`${i}:30`}
					value={`${String(i).padStart(2, '0')}:30`}
				>
					{`${hour}:30 ${ampm}`}
				</option>
			);
		}
		return options;
	};

	const sections = [
		{
			name: 'External catering',
			subtitle: 'Venue allows external catering',
			icon: alcoholic4,
			toggleState: formData.cateringAndDrinks?.externalCatering,
			setToggleState: () => handleToggleChange('externalCatering'),
		},
		{
			name: 'Refreshments',
			subtitle: 'Refreshments for guests, free for every booking',
			icon: alcoholic5,
			toggleState: formData.cateringAndDrinks?.refreshments,
			setToggleState: () => handleToggleChange('refreshments'),
			type: 'refreshment',
		},
		{
			name: 'Shisha',
			subtitle: '',
			icon: alcoholic6,
			toggleState: formData.cateringAndDrinks?.shisha,
			setToggleState: () => handleToggleChange('shisha'),
		},
	];

	const Alcohol = [
		{
			name: 'Alcohol/ Liquor License',
			subtitle: 'Your venue can sell or supply alcohol',
			icon: alcoholic1,
			toggleState: formData.cateringAndDrinks?.alcoholLiquorLicense,
			setToggleState: () =>
				handleToggleChange('alcoholLiquorLicense'),
			inputType: 'time', // Added field to specify that time input is required for this toggle
		},
		{
			name: 'Bring your own alcohol Allowed',
			subtitle:
				'Guests are welcome to bring their own alcoholic beverages',
			icon: alcoholic3,
			toggleState:
				formData.cateringAndDrinks?.bringYourOwnAlcoholAllowed,
			setToggleState: () =>
				handleToggleChange('bringYourOwnAlcoholAllowed'),
		},
	];

	// Only add corkage option if bringYourOwnAlcoholAllowed is true
	if (formData.cateringAndDrinks?.bringYourOwnAlcoholAllowed) {
		Alcohol.push({
			name: 'Corkage fee for bringing your own alcohol',
			subtitle:
				'A corkage fee applies if the customer brings their own alcohol',
			icon: alcoholic2,
			toggleState:
				formData.cateringAndDrinks?.corkageFeeForBringYourOwnAlcohol,
			setToggleState: () =>
				handleToggleChange('corkageFeeForBringYourOwnAlcohol'),
		});
	}

	const [styles, setStyles] = useState(
		formData.cateringAndDrinks?.servingStyle || []
	);
	const [refreshment, setRefreshmnet] = useState([]);

	const toggleActivity = (activity) => {
		const updatedActivities = toggleSelection(activity, styles);
		setStyles(updatedActivities);

		setFormData((prevFormData) => ({
			...prevFormData,
			cateringAndDrinks: {
				...prevFormData.cateringAndDrinks,
				servingStyle: updatedActivities,
			},
		}));
	};
	const toggleRefreshment = (activity) => {
		const updatedActivities = toggleSelection(activity, styles);
		setRefreshmnet(updatedActivities);

		// setFormData((prevFormData) => ({
		//   ...prevFormData,
		//   cateringAndDrinks: {
		//     ...prevFormData.cateringAndDrinks,
		//     servingStyle: updatedActivities,
		//   },
		// }));
	};

	const errors = formData?.cateringAndDrinks?.errors || {};

	return (
		<div className='p-2 mx-auto'>
			<h1 className='lg:text-3xl text-2xl font-bold mb-6'>
				Catering & Drinks
			</h1>

			<div className='mb-6'>
				<div className='flex items-center justify-between mb-2'>
					<div className='flex items-center'>
						<BiDish className='text-2xl mr-2' />
						<div>
							<span className='text-xl font-medium text-darkGray'>
								Venue provides In-house catering
							</span>
							<p className='text-base text-offGray'>
								Venue has their own chef and staff
							</p>
						</div>
					</div>
					<ToggleSwitch
						isOn={
							formData?.cateringAndDrinks?.venueProvideInHouseCatering
						}
						handleToggle={() =>
							handleToggleChange('venueProvideInHouseCatering')
						}
					/>
				</div>
			</div>

			{/* only show if  venueProvideInHouseCatering is true*/}
			{formData?.cateringAndDrinks?.venueProvideInHouseCatering && (
				<>
					<div className='mb-6'>
						<h2 className='text-xl font-medium text-darkGray mb-2'>
							Available menu
						</h2>
						<p className='text-offGray mb-4'>
							Select the type of serving style at your venue
						</p>
						<div className='flex flex-wrap gap-2'>
							<SelectableTags
								max={6}
								tags={availableMenuOptions}
								selectedTags={
									formData.cateringAndDrinks?.availableMenu
								}
								onTagClick={(tag) =>
									setFormData((prevData) => ({
										...prevData,
										cateringAndDrinks: {
											...prevData.cateringAndDrinks,
											availableMenu: toggleSelection(
												tag,
												formData.cateringAndDrinks?.availableMenu
											),
										},
									}))
								}
							/>
						</div>
						{errors?.availableMenu && (
							<p className='text-red-500 py-1'>
								{errors?.availableMenu}
							</p>
						)}
					</div>

					<div className='mb-6'>
						<h2 className='text-xl font-medium text-darkGray mb-2'>
							Cuisine
						</h2>
						<p className='text-offGray mb-4'>
							Select the type of cuisine at your venue
						</p>
						<div className='flex flex-wrap gap-2'>
							<SelectableTags
								max={100}
								tags={cuisineOptions}
								selectedTags={formData.cateringAndDrinks?.cuisine}
								onTagClick={(tag) =>
									setFormData((prevData) => ({
										...prevData,
										cateringAndDrinks: {
											...prevData.cateringAndDrinks,
											cuisine: toggleSelection(
												tag,
												formData.cateringAndDrinks?.cuisine
											),
										},
									}))
								}
							/>
						</div>
						{errors.cuisine && (
							<p className='text-red-500 py-1'>{errors.cuisine}</p>
						)}
					</div>
				</>
			)}

			{window.location.pathname.includes(
				'venu-restaurant-Form/Restaurant'
			) ||
			window.location.pathname.includes(
				'create-Listing/Restaurant'
			) ? (
				formData?.cateringAndDrinks?.isParentAlcoholic && (
					<div className='flex items-start justify-between mb-2 w-full'>
						<div className='flex items-start gap-5'>
							<div>
								<p className='text-xl pb-10 font-semibold'>
									Does your restaurant serve alcohol?
								</p>
							</div>
						</div>
						<ToggleSwitch
							isOn={alcoholic}
							handleToggle={handleAlcohlic}
						/>
					</div>
				)
			) : (
				<div className='flex items-start justify-between mb-2 w-full'>
					<div className='flex items-start gap-5'>
						<div>
							<p className='text-xl pb-10 font-semibold'>
								Does your restaurant serve alcohol?
							</p>
						</div>
					</div>
					<ToggleSwitch
						isOn={alcoholic}
						handleToggle={handleAlcohlic}
					/>
				</div>
			)}

			{alcoholic &&
				Alcohol?.map((item, i) => (
					<div
						className='mb-6 w-full flex flex-wrap gap-3 items-start'
						key={i}
					>
						<div className='flex items-center justify-between mb-2 lg:w-3/5 w-full'>
							<div className='flex items-start gap-5'>
								<img src={item.icon} alt='' className='' />
								<div>
									<span className='text-lg font-semibold'>
										{item.name}
									</span>
									<p className='text-gray-500'>{item.subtitle}</p>
								</div>
							</div>
							<ToggleSwitch
								isOn={item.toggleState}
								handleToggle={item.setToggleState}
							/>
						</div>

						{/* Show time input if Alcohol/ Liquor License is toggled on */}
						{item.name === 'Alcohol/ Liquor License' &&
							item.toggleState && (
								<div className='mt-[-17px] lg:w-1/4 w-full'>
									<label
										htmlFor='alcoholServingTime'
										className='text-base font-normal ml-1'
									>
										Until:
									</label>
									<select
										name='alcoholServingTime'
										value={alcoholTime}
										onChange={(e) =>
											handleTimeChange(
												'alcoholServingTime',
												e.target.value
											)
										}
										className='border rounded-lg p-2 w-full'
									>
										<option value='' disabled>
											Select Time
										</option>
										{generateTimeOptions()}
									</select>
									{errors.alcoholServingTime && (
										<p className='text-red-500 py-1'>
											{errors.alcoholServingTime}
										</p>
									)}
								</div>
							)}
					</div>
				))}

			{sections.map((item, i) => (
				<div
					className='mb-6 w-full flex flex-wrap gap-3 items-start'
					key={i}
				>
					<div className='flex items-center justify-between mb-2 lg:w-3/5 w-full'>
						<div className='flex items-start gap-5'>
							<img src={item.icon} alt='' className='' />
							<div>
								<span className='text-lg font-semibold'>
									{item.name}
								</span>
								<p className='text-gray-500'>{item.subtitle}</p>
							</div>
						</div>
						<ToggleSwitch
							isOn={item.toggleState}
							handleToggle={item.setToggleState}
						/>
					</div>
					{item.toggleState && item.inputType && (
						<div className='w-full'>
							<h2 className='text-xl font-medium text-darkGray mb-2'>
								Refereshments for guests
							</h2>
							<p className='text-secondary mb-4'>
								Free for every booking
							</p>
							<div className='flex flex-wrap gap-2'>
								<SelectableTags
									tags={['Tea & Coffee', 'Coffee', 'Water']}
									selectedTags={refreshment}
									onTagClick={toggleRefreshment}
								/>
							</div>
						</div>
					)}
				</div>
			))}
			<div className='mb-6'>
				<h2 className='text-xl font-medium text-darkGray mb-2'>
					Serving Style
				</h2>
				<p className='text-secondary mb-4'>
					Select the type of serving style at your venue
				</p>
				<div className='flex flex-wrap gap-2'>
					<SelectableTags
						max={10}
						tags={activities}
						selectedTags={styles}
						onTagClick={toggleActivity}
					/>
				</div>
				{errors.servingStyle && (
					<p className='text-red-500 py-1'>{errors.servingStyle}</p>
				)}
			</div>
		</div>
	);
};

export default CateringAndDrinks;
