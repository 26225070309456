import React, { useState, useContext, useRef } from 'react'; // Import useRef
import { BsUpload } from 'react-icons/bs';
import AWS from 'aws-sdk';
import { HotelContext } from '../../../context/HotelContext';
import { PiFilePdfThin } from 'react-icons/pi';

// Configure AWS SDK
const S3_BUCKET = 'st-findmyvenue';
const REGION = 'ap-south-1';

AWS.config.update({
	accessKeyId: 'AKIATCKAPHDJJECWAMJF',
	secretAccessKey: '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',
	region: REGION,
});

const myBucket = new AWS.S3({
	params: { Bucket: S3_BUCKET },
	region: REGION,
});

const FileUpload = () => {
	const { formData, setFormData } = useContext(HotelContext);
	const [file, setFile] = useState(
		formData?.overview?.additionalDetails?.tradeLicenseFile || null
	);
	const [fileType, setFileType] = useState('');
	const [uploading, setUploading] = useState(false);
	const [progress, setProgress] = useState(0);
	const [error, setError] = useState('');

	// Create a ref for the file input
	const fileInputRef = useRef(null);

	const handleFileUpload = (file, type) => {
		setUploading(true);
		setError('');
	  
		const isPDF = file.type === "application/pdf"; // Check if the file is a PDF
	  
		const params = {
		  ACL: 'public-read',
		  Body: file,
		  Bucket: S3_BUCKET,
		  Key: `${type}/${file.name}`,
		  ContentType: isPDF ? "application/pdf" : file.type, // Set Content-Type for PDFs
		};
	  
		myBucket
		  .upload(params, (err, data) => {
			setUploading(false);
			if (err) {
			  console.error('Error uploading file:', err.message);
			  setError('Error uploading file. Please try again.'); // Set upload error
			  return;
			}
	  
			// Update formData with the uploaded file URL
			setFormData((prevFormData) => ({
			  ...prevFormData,
			  overview: {
				...prevFormData.overview,
				additionalDetails: {
				  ...prevFormData.overview.additionalDetails,
				  tradeLicenseFile: data.Location,
				  errors: {
					...prevFormData.overview.additionalDetails.errors,
					tradeLicenseFile: '', // Clear previous error if upload is successful
				  },
				},
			  },
			}));
	  
			setFile(data.Location); // Update file URL after successful upload
			setProgress(0); // Reset progress after upload
		  })
		  .on('httpUploadProgress', (evt) => {
			setProgress(Math.round((evt.loaded / evt.total) * 100));
		  });
	  };
	  

	const handleFileChange = (event) => {
		if (file !== null) {
			setError('Only 1 file is allowed for this document.');
			return;
		}
		const selectedFile = event.target.files[0];
		const allowedTypes = [
			'image/jpeg',
			'image/png',
			'application/pdf',
		];

		if (selectedFile) {
			if (selectedFile.size > 5 * 1024 * 1024) {
				// Updated to 5 MB
				setError('File size should not exceed 5 MB.'); // Updated error message
				return;
			}
			if (!allowedTypes.includes(selectedFile.type)) {
				setError('PNG, JPG, and PDF files are allowed.');
				return;
			}
			handleFileUpload(selectedFile, 'brochure');
			const fileUrl = URL.createObjectURL(selectedFile);
			setFile(fileUrl);
			setFileType(selectedFile.type);
		}
	};

	const handleRemoveFile = () => {
		setFile(null);
		setError('');

		// Reset file input value
		if (fileInputRef.current) {
			fileInputRef.current.value = null;
		}

		// Update context
		setFormData((prevFormData) => ({
			...prevFormData,
			overview: {
				...prevFormData.overview,
				additionalDetails: {
					...prevFormData.overview.additionalDetails,
					tradeLicenseFile: null,
					errors: {
						...prevFormData.overview.additionalDetails.errors,
						tradeLicenseFile: '',
					},
				},
			},
		}));
	};

	return (
		<div className='w-full p-2 bg-white rounded-lg'>
			<h2 className='text-2xl font-medium text-darkGray text-base mb-4'>
				Trade Licence{' '}
				<span className='text-red-500 ml-1 text-[15px]'>*</span>
			</h2>

			<div className='flex flex-wrap justify-between gap-5 items-start'>
				<div className='w-full md:w-1/2'>
					<p className='text-secondary text-sm mb-1'>
						Upload Trade License Document
					</p>

					<div className='w-4.5/5 h-40 relative border-dashed border-2 border-gray-300 rounded-lg p-6 py-2 flex flex-col items-center justify-center'>
						<input
							type='file'
							accept='.jpg,.jpeg,.png,.pdf'
							className='opacity-0 absolute cursor-pointer w-full h-full'
							id='file-upload'
							onChange={handleFileChange}
							ref={fileInputRef} // Assign the ref to the input element
						/>
						<label
							htmlFor='file-upload'
							className='cursor-pointer text-center text-lg text-red-500'
						>
							<BsUpload
								className='mx-auto mb-2 font-semibold text-gray-400'
								size={30}
							/>
							<div className='flex flex-wrap justify-center gap-x-2 items-center w-full'>
								<p className='mb-1 font-medium underline'>
									Click to upload
								</p>
								<p className='text-offGray'>or drag and drop</p>
							</div>
							<p className='text-secondary text-xs mt-2'>
								PDF, PNG, JPG (Upto 5 MB)
							</p>
						</label>
					</div>
				</div>

				{file && (
					<div className='h-[12rem] bg-lightGray w-[1px] md:block hidden'></div>
				)}

				{file && (
					<div className='w-full md:w-2/5 overflow-hidden mt-6 md:mt-0 flex flex-col'>
						<h3 className='text-secondary text-sm mb-2'>
							Document Uploaded
						</h3>

						<div className='relative'>
							<div className='w-[100px] h-[100px] border rounded-lg overflow-hidden flex bg-gray-100'>
								{fileType === 'application/pdf' ? (
									<div className='relative w-full h-full flex items-center justify-center'>
										<PiFilePdfThin size={30} />
										<button
											className='absolute top-0 right-0 mt-2 mr-2 bg-[#BDBDBD] rounded-[4px] p-1 text-white w-[24px] h-[24px] flex items-center justify-center'
											onClick={handleRemoveFile}
										>
											<img src='/close.svg' alt='close' />
										</button>
									</div>
								) : (
									<div className='relative h-full w-full'>
										<img
											src={file}
											alt='Uploaded'
											className='w-[100px] h-[100px] rounded-lg object-cover'
										/>
										<button
											className='absolute top-0 right-0 mt-2 mr-2 bg-[#BDBDBD] rounded-[4px] p-1 text-white w-[24px] h-[24px] flex items-center justify-center'
											onClick={handleRemoveFile}
										>
											<img src='/close.svg' alt='close' />
										</button>
									</div>
								)}
							</div>
							<p className='text-secondary text-sm mt-2'>
								File Name: {file.split('/').pop()}{' '}
								{/* Extract file name from the URL */}
							</p>
						</div>
					</div>
				)}
			</div>
			{error && <p className='text-red-500 pb-3'>{error}</p>}
		</div>
	);
};

export default FileUpload;
