import React from "react";
import img1 from "../../assets/pics/handle1.svg";
import img2 from "../../assets/pics/handle2.svg";
import img3 from "../../assets/pics/handle3.svg";

const Effortless = () => {
  const data = [
    {
      icon: img1,
      name: "Instant Registration",
      des: "Create your account in under 15 mins",
    },
    {
      icon: img2,
      name: "Enquiry Management",
      des: "Track leads & enquiries and send automated client responses",
    },
    {
      icon: img3,
      name: "Intuitive Dashboard",
      des: "Manage your listings and gain detailed venue performance insights and analytics",
    },
  ];

  return (
    <div className="w-full flex justify-center pt-20 mb-[90px]">
      <div className="w-[90%] flex flex-col items-center">
        <h1 className="text-[#222222] sm:text-4xl text-3xl font-semibold my-10">
          Easy to list, Easy to manage
        </h1>
        <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6 w-full">
          {data.map((e, i) => (
            <div
              key={i}
              className="bg-white border border-gray-400 p-5 flex flex-col items-center gap-4 rounded-md h-full"
            >
              <img src={e.icon} alt="" />
              <h1 className="text-lg font-medium">{e.name}</h1>
              <p className="text-secondary text-center">{e.des}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Effortless;
