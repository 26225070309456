import { useEffect, useState } from "react";
import DashboardLayout from "../../layout/dashboard-layout";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useLocation } from "react-router-dom";
import VenueCard from "./VenueCard";
import { useQuery } from "@tanstack/react-query";
import { getOnboarding } from "../../api/signup.request";
import { useTeamMember } from "../../queries/team-members";
import { fetchLoggedUser } from "../../api/loggedUser";
import { useUser } from "../../context/user";

const MyVenues = () => {
  const [user, setUser] = useState(null);
  // setting in context parent state the hotels tile full data
  const { hotelVenue, setHotelVenue } = useUser();
  const location = useLocation();
  const checkAdmin = localStorage.getItem("checkAdmin");
  // if it is super admin than  localStorage.getItem("vendorId"); will work
  // but if it is admin that was created by invitation than  localStorage.getItem("invitedBy"); will work
  // than the id will be used of that super admin to fetch venues (For admin show all venues of super admin)
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");
  const limiteduser = localStorage.getItem("vendorId");

  const [data, setData] = useState([]);
  const [invitedData, setInvitedData] = useState(false);
  // const [hotelVenue, setHotelVenue] = useState(null);
  const [uniqueRestaurantsWithVenues, setUniqueRestaurantsWithVenues] =
    useState([]);
  const [multipleVenuesInSameCity, setMultipleVenuesInSameCity] = useState([]);

  const { data: boardingData } = useQuery({
    queryKey: ["onboardingData"],
    queryFn: async () => await getOnboarding(vendorId),
    refetchOnWindowFocus: false,
  });
  const { data: limitedvenues } = useTeamMember(limiteduser);
  useEffect(() => {
    if (
      boardingData &&
      boardingData.restaurantForms &&
      boardingData.restaurantForms.length > 0
    ) {
      setData(boardingData);
      setInvitedData(false);
    } else {
      // If restaurantForms is empty or undefined
      setData(boardingData);
      setInvitedData(true);
    }
  }, [boardingData]);

  // inside hotels for limited ones exclude whore are without hotel and venue name
  useEffect(() => {
    if (
      (limitedvenues?.user?.assignedVenues?.length > 0 ||
        limitedvenues?.user?.Hotel?.length > 0) &&
      (checkAdmin === "false" || checkAdmin === "null")
    ) {
      // Filter the hotel
      const filteredHotel =
        data?.hotels?.find((hotel) =>
          limitedvenues?.user?.Hotel?.some((venue) => venue?._id === hotel?._id)
        ) || null;

      // Filter the venues
      const filteredVenues = limitedvenues?.user?.assignedVenues.filter(
        (venue) => {
          return data?.venueForms?.some((v) => v?._id === venue._id);
        }
      );

      // Process filtered venues if any matches are found
      if (filteredHotel !== null) {
        const hotelData = data.hotels[0];
        const hotelName = hotelData?.overview?.basicDetails?.hotelName;

        if (filteredVenues.length > 0) {
          // Find venues matching the hotelName in the filtered venues
          const matchedVenues = filteredVenues.filter(
            (venue) => venue.basicDetails.hotelName === hotelName
          );

          setHotelVenue({
            hotelName: filteredHotel?.overview?.basicDetails?.hotelName,
            venues: [filteredHotel, ...matchedVenues],
          });
        } else {
          setHotelVenue({
            hotelName: filteredHotel?.overview?.basicDetails?.hotelName,
            venues: [filteredHotel],
          });
        }
      } else {
        if (filteredVenues.length > 0) {
          const hotelData = data.hotels[0];
          const hotelName = hotelData?.overview?.basicDetails?.hotelName;
          // Find venues matching the hotelName in the filtered venues
          const matchedVenues = filteredVenues.filter(
            (venue) => venue.basicDetails.hotelName === hotelName
          );

          setHotelVenue({
            hotelName: filteredHotel?.overview?.basicDetails?.hotelName,
            venues: [...matchedVenues],
          });
        }
      }
    } else if (data?.hotels && data?.hotels.length > 0 && data?.venueForms) {
      // Fallback logic when limitedvenues is empty or not available
      const hotelData = data.hotels[0];
      const hotelName = hotelData?.overview?.basicDetails?.hotelName;

      const initialHotelVenue = {
        hotelName: hotelName,
        venues: [hotelData],
      };

      const matchedVenues = data?.venueForms.filter(
        (venue) => venue?.basicDetails?.hotelName === hotelName
      );

      setHotelVenue({
        ...initialHotelVenue,
        venues: [hotelData, ...matchedVenues],
      });
    }
  }, [limitedvenues, data, checkAdmin, hotelVenue, setHotelVenue]);

  // for restaurants for limites users + admin
  useEffect(() => {
    if (data?.restaurantForms) {
      const alcoholicRestaurantCount = data?.withAlcoholNumber;
      const nonAlcoholicRestaurantCount = data?.withoutAlcoholNumber;
      const combineRestaurantSlots = [];
      // Step 1: Create slots for all restaurants (all assigns tiles should show even its empty)
      for (let i = 0; i < alcoholicRestaurantCount; i++) {
        combineRestaurantSlots.push({
          restaurantName: `Restaurant w/Alcohol ${i + 1}`,
          restaurants: [],
          isAlcoholic: true,
        });
      }
      for (let i = 0; i < nonAlcoholicRestaurantCount; i++) {
        combineRestaurantSlots.push({
          restaurantName: `Restaurant w/o Alcohol ${i + 1}`,
          restaurants: [],
          isAlcoholic: false,
        });
      }

      // Helper functions
      const findSlotByName = (slotName, isAlcoholic) => {
        return combineRestaurantSlots.find(
          (slot) =>
            slot.restaurantName === slotName && slot.isAlcoholic === isAlcoholic
        );
      };

      const findNextAvailableSlot = (isAlcoholic) => {
        return combineRestaurantSlots.find(
          (slot) =>
            slot.isAlcoholic === isAlcoholic && slot.restaurants.length === 0
        );
      };

      const validateTileLocation = (tileLocation, isAlcoholic) => {
        if (!tileLocation) return false;
        const isAlcoholicTile = tileLocation.includes("w/Alcohol");
        const isNonAlcoholicTile = tileLocation.includes("w/o Alcohol");
        return isAlcoholic ? isAlcoholicTile : isNonAlcoholicTile;
      };

      const processedRestaurantNames = new Set();

      // Step 2: Process restaurants
      data.restaurantForms.forEach((restaurant) => {
        const restaurantName = restaurant.basicDetails.venueName;
        const isAlcoholic =
          restaurant?.basicDetails?.tilelocation?.includes("w/Alcohol");
        const tileLocation = restaurant?.basicDetails?.tilelocation;

        if (processedRestaurantNames.has(restaurantName)) return;

        processedRestaurantNames.add(restaurantName);

        let availableSlot = validateTileLocation(tileLocation, isAlcoholic)
          ? findSlotByName(tileLocation, isAlcoholic)
          : findNextAvailableSlot(isAlcoholic);

        if (availableSlot) {
          availableSlot.restaurants.push(restaurant);
        }
      });

      // Step 3: Handle limited venues logic
      if (limitedvenues && (checkAdmin === "false" || checkAdmin === "null")) {
        const limitedAssignedVenues = limitedvenues?.user?.RestaurantForm;

        if (Array.isArray(limitedAssignedVenues)) {
          const filteredVenues = combineRestaurantSlots.map((slot) => {
            const filteredSlotVenues = slot?.restaurants?.filter((venue) =>
              limitedAssignedVenues.some(
                (assignedVenue) => assignedVenue._id === venue._id
              )
            );

            return {
              ...slot,
              restaurants: filteredSlotVenues || [],
            };
          });
          // Always set all slots, even empty ones
          setUniqueRestaurantsWithVenues(filteredVenues);
        }
      } else {
        // Ensure all slots are shown 
        setUniqueRestaurantsWithVenues(combineRestaurantSlots);
      }
    }
  }, [data, limitedvenues, checkAdmin]);

  // for slotss one multi-slots single slots old one
  // useEffect(() => {
  //   if (data?.venueForms && data?.items) {
  //     // dont show slots whoise amount is not pais means 0
  //     const items = data?.items.filter((item) => item?.amount !== 0);
  //     const combineVenue = [];

  //     // Step 1: Create slots in combineVenue based on the `items` array
  //     items.forEach((item, i) => {
  //       if (item.answer === "no") {
  //         combineVenue.push({
  //           venueName: `${
  //             data.selectedChips.length === 1 &&
  //             data.selectedChips.includes("Kids Play Area")
  //               ? `Unique Location ${i + 1} - Kids`
  //               : `Unique Venue ${i + 1}`
  //           }`,
  //           venues: [],
  //         });
  //       } else {
  //         combineVenue.push({
  //           venueName: `${
  //             data.selectedChips.length === 1 &&
  //             data.selectedChips.includes("Kids Play Area")
  //               ? `Multi Location ${i + 1} - Kids`
  //               : `Multi Venue ${i + 1}`
  //           }`,
  //           venues: [],
  //         });
  //       }
  //     });

  //     // Step 2: Process venue forms and assign them to the correct slots
  //     data.venueForms.forEach((venue) => {
  //       const venueHotelName = venue?.basicDetails?.slotName;

  //       // Find the matching slot where the venueName includes the hotelName
  //       const matchingSlot = combineVenue.find((slot) =>
  //         slot.venueName.includes(venueHotelName)
  //       );

  //       if (matchingSlot) {
  //         // If a matching slot is found, push the venue into that slot's venues array
  //         matchingSlot.venues.push(venue);
  //       }
  //     });

  //     // only for limited users limit condition will run means if condition
  //     if (limitedvenues && (checkAdmin === "false" || checkAdmin === "null")) {
  //       // Access the assigned venues of limited user
  //       const limitedAssignedVenues = limitedvenues?.user?.assignedVenues;

  //       // Check if assignedVenues is an array and has data
  //       if (
  //         Array.isArray(limitedAssignedVenues) &&
  //         limitedAssignedVenues.length > 0
  //       ) {
  //         // Extract hotelName and venueName from all assigned venues
  //         const filteredVenues = combineVenue
  //           .map((slot) => {
  //             // Filter the venues within the current slot
  //             const filteredSlotVenues = slot.venues.filter((venue) => {
  //               return limitedAssignedVenues.some((assignedVenue) => {
  //                 return assignedVenue._id === venue._id;
  //               });
  //             });

  //             // Return the slot with only the matched venues
  //             return {
  //               ...slot,
  //               venues: filteredSlotVenues,
  //             };
  //           })
  //           .filter((slot) => slot.venues.length > 0); // Only include slots with matched venues

  //         setMultipleVenuesInSameCity(filteredVenues);
  //       }
  //     } else {
  //       // If limitedvenues is undefined or null, return all venues
  //       setMultipleVenuesInSameCity(combineVenue);
  //     }
  //   }
  // }, [
  //   data,
  //   hotelVenue,
  //   uniqueRestaurantsWithVenues,
  //   limitedvenues,
  //   checkAdmin,
  // ]);

  // for slotss one multi-slots single slots new one
  useEffect(() => {
    if (data?.venueForms && data?.items) {
      const items = data?.items?.filter((item) => item?.amount !== 0);
      const combineVenue = [];

      // Step 1: Create slots in combineVenue based on the `items` array
      items?.forEach((item, i) => {
        if (item?.answer === "no") {
          combineVenue?.push({
            venueName: `${
              data?.selectedChips?.length === 1 &&
              data?.selectedChips?.includes("Kids Play Area")
                ? `Unique Location ${i + 1} - Kids`
                : `Unique Venue ${i + 1}`
            }`,
            venues: [],
          });
        } else {
          combineVenue.push({
            venueName: `${
              data?.selectedChips?.length === 1 &&
              data?.selectedChips?.includes("Kids Play Area")
                ? `Multi Location ${i + 1} - Kids`
                : `Multi Venue ${i + 1}`
            }`,
            venues: [],
          });
        }
      });

      // Step 2: Process venue forms and assign them to the correct slots
      data?.venueForms?.forEach((venue) => {
        const venueHotelName = venue?.basicDetails?.slotName;

        let matchingSlot;

        // Check if venueHotelName exists (i.e., slotName is available)
        if (venueHotelName) {
          // Find the matching slot by venueHotelName
          matchingSlot = combineVenue?.find((slot) =>
            slot?.venueName?.includes(venueHotelName)
          );
        }

        // If venueHotelName is missing, assign it to the "Multi Venue" slot
        if (!matchingSlot) {
          // Specifically assign missing venues to the "Multi Venue" slot
          matchingSlot = combineVenue?.find((slot) =>
            slot.venueName.includes("Multi Venue")
          );
        }

        // Push the venue into the matching slot's venues array
        matchingSlot?.venues?.push(venue);
      });

      // Limited venues logic for user-specific filtering
      if (limitedvenues && (checkAdmin === "false" || checkAdmin === "null")) {
        const limitedAssignedVenues = limitedvenues?.user?.assignedVenues;
        if (
          Array.isArray(limitedAssignedVenues) &&
          limitedAssignedVenues?.length > 0
        ) {
          const filteredVenues = combineVenue
            .map((slot) => {
              const filteredSlotVenues = slot?.venues?.filter((venue) => {
                return limitedAssignedVenues.some(
                  (assignedVenue) => assignedVenue._id === venue._id
                );
              });

              return {
                ...slot,
                venues: filteredSlotVenues,
              };
            })
            .filter((slot) => slot.venues.length > 0);

          setMultipleVenuesInSameCity(filteredVenues);
        }
      } else {
        setMultipleVenuesInSameCity(combineVenue);
      }
    }
  }, [
    data,
    hotelVenue,
    uniqueRestaurantsWithVenues,
    limitedvenues,
    checkAdmin,
  ]);
  // worst way to fetch user
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetchLoggedUser();
        setUser(response.vendor);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, []);
  return (
    <DashboardLayout>
      <div className="flex justify-center bg-white w-full">
        <div className="w-[95%]">
          <h1 className="text-[32px] font-[600]">My Venues</h1>
          <div className="flex items-center gap-1 text-[13px] font-[500]">
            <p className="font-medium text-primary">Dashboard</p>
            <MdKeyboardArrowRight />
            <p className="">
              {location.pathname === "/myVenue" ? "My venues" : ""}
            </p>
          </div>

          <div className="">
            {/* Hotel Venues */}
            {/* {hotelVenue?.venues?.length > 0 &&
							hotelVenue.venues.every(
								(venue) => venue !== undefined
							) && (
								<VenueCard
									// title={hotelVenue.hotelName}
									title='Hotel'
									type=''
									category='Venue'
									combineData='hotel'
									multiVenue={true}
									data={hotelVenue.venues}
								/>
							)} */}
            {/* hotels,veues part only  */}
            {user?.admin ||
            user?.assignedVenues.length > 0 ||
            (user?.Hotel && user?.Hotel?.length > 0)
              ? hotelVenue?.venues?.length > 0 &&
                hotelVenue.venues.every((venue) => venue !== undefined) && (
                  <VenueCard
                    title="Hotel"
                    type=""
                    category="Venue"
                    combineData="hotel"
                    multiVenue={true}
                    data={hotelVenue.venues}
                  />
                )
              : // for limited if he is not admin than dont show
                hotelVenue?.venues?.length > 0 &&
                checkAdmin === "true" &&
                hotelVenue.venues.every((venue) => venue !== undefined) && (
                  <VenueCard
                    title="Hotel"
                    type=""
                    category="Venue"
                    combineData="hotel"
                    multiVenue={true}
                    data={hotelVenue.venues}
                  />
                )}

            {/* venues part */}
            {multipleVenuesInSameCity?.length > 0 ? (
              multipleVenuesInSameCity?.map((cityVenue, index) => (
                <VenueCard
                  key={index}
                  cardnumber={index}
                  category="Venue"
                  combineData="Venue"
                  title={cityVenue.venueName}
                  type={
                    cityVenue.venueName.includes("Multi Location") ||
                    cityVenue.venueName.includes("Multi Venue")
                      ? "Multi Venue"
                      : "Single Venue"
                  }
                  multiVenue={
                    cityVenue.venueName.includes("Multi Location") ||
                    cityVenue.venueName.includes("Multi Venue")
                      ? true
                      : (cityVenue.venueName.includes("Unique Location") ||
                          cityVenue.venueName.includes("Unique Venue")) &&
                        cityVenue?.venues?.length < 1
                      ? true
                      : false
                  }
                  data={cityVenue?.venues}
                />
              ))
            ) : (
              <p></p> // You can customize the fallback message here.
            )}

            {/* Unique Venues by City */}
            {/* {
              uniqueCitiesWithVenues?.map((cityVenue, index) => (
                <VenueCard
                  key={index}
                  category="Venue"
                  combineData="Venue"
                  title={cityVenue.venueName}
                  type={cityVenue.venueName.includes("Multi Venue in Slot") ? "Multi Venue" : "Single Venue"}
                  multiVenue={
                    cityVenue.venueName.includes("Multi Venue in Slot")
                      ? true
                      : cityVenue.venueName.includes("Unique Venue in Slot") && cityVenue?.venues?.length < 1
                        ? true
                        : false
                  }
                  data={cityVenue?.venues}
                />

              ))
            ) : (
              <p></p> // You can customize the fallback message here.
            )}
            {/* Multiple Resturant */}
            {/* {uniqueRestaurantsWithVenues.length > 0 ? (
							uniqueRestaurantsWithVenues.map((cityVenue, index) => (
								<VenueCard
									key={index}
									category='Venue'
									combineData='Restaurant'
									title={cityVenue.restaurantName}
									type=''
									multiVenue={true}
									data={cityVenue.restaurants}
								/>
							))
						) : (
							<p></p>
						)} */}

            {/* multiple restaurants */}
            {user?.admin ||
            (user?.RestaurantForm && user?.RestaurantForm.length > 0) ? (
              uniqueRestaurantsWithVenues.length > 0 ? (
                uniqueRestaurantsWithVenues.map((cityVenue, index) => (
                  <VenueCard
                    key={index}
                    category="Venue"
                    combineData="Restaurant"
                    title={cityVenue.restaurantName}
                    type=""
                    multiVenue={true}
                    data={cityVenue.restaurants}
                  />
                ))
              ) : (
                <p></p> // Placeholder when there are no venues and the user is either an admin or has submitted the form
              )
            ) : uniqueRestaurantsWithVenues.length > 0 ? (
              uniqueRestaurantsWithVenues.map((cityVenue, index) => (
                <VenueCard
                  key={index}
                  category="Venue"
                  combineData="Restaurant"
                  title={cityVenue.restaurantName}
                  type=""
                  multiVenue={true}
                  data={cityVenue.restaurants}
                />
              ))
            ) : (
              <p></p> // Placeholder when there are no venues and the user is neither an admin nor has submitted the form
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default MyVenues;
