import { Box, Typography } from '@mui/material';
import Tooltip from '../../../components/Tooltip';
import TextfieldNumber from './TextfieldNumber';
import { useTheme } from '@mui/material/styles';
import LabelledRadioGroup from './LabelledRadioGroup';

function VenueCard({
	isLocation = false,
	labelText,
	sameLocation = null,
	handleSameLocationChange = null,
	isOtherText = false,
	otherText = null,
	toolTipText,
	quantityNumber,
	handleQuantityNumberChange,
	title,
	description,
}) {
	const theme = useTheme();
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				backgroundColor: 'white',
				width: { xs: '80%', md: '90%' },
				padding: '1.5rem',
				marginTop: '2rem',
				border: `2px solid ${theme.palette.grey.border}`,
				borderRadius: '12px',
				boxShadow: 'none',
			}}
		>
			{isLocation && (
				<LabelledRadioGroup
					label={labelText}
					value={sameLocation}
					handleChange={handleSameLocationChange}
				/>
			)}

			{isOtherText && (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						mt: isLocation ? 2 : 0,
					}}
				>
					<Typography
						variant='body1'
						gutterBottom
						fontWeight='500'
						align='left'
						sx={{
							fontSize: {
								xs: 'subtitle2.fontSize',
								md: 'body1.fontSize',
							},
						}}
					>
						{otherText}
					</Typography>
				</Box>
			)}

			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					mt: isLocation || isOtherText ? 2 : 0,
					gap: 2,
					flexDirection: { xs: 'column', md: 'row' },
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						width: { xs: '100%', md: '47%' },
					}}
				>
					<Box sx={{ display: 'flex' }}>
						<Typography
							variant='body1'
							gutterBottom
							fontWeight='500'
							align='left'
							sx={{
								fontSize: {
									xs: 'subtitle2.fontSize',
									md: '16px',
								},
								color: '#222222',
							}}
						>
							{title}
						</Typography>
						<Tooltip text={toolTipText} />
					</Box>
					<Typography
						variant='body1'
						gutterBottom
						fontWeight='400'
						align='left'
						sx={{
							fontSize: {
								xs: 'subtitle2.fontSize',
								md: '16px',
							},
							color: '#717171',
							lineHeight: '24.96px',
						}}
					>
						{description}
					</Typography>
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'start',
						width: { xs: '100%', md: '49%' },
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							width: '42%',
						}}
					>
						{/* <Typography
              variant="body1"
              gutterBottom
              fontWeight="400"
              align="center"
              sx={{
                fontSize: {
                  xs: "subtitle2.fontSize",
                  md: "body1.fontSize",
                },
              }}
            >
              Quantity
            </Typography> */}
						<TextfieldNumber
							value={quantityNumber}
							onChange={handleQuantityNumberChange}
						/>
					</Box>
				</Box>
			</Box>
		</div>
	);
}

export default VenueCard;
