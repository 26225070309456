export const tipsHotel = {
  0: {
    0: "Highlight unique features, describe room types, mention location advantages, and be concise and clear to stand out for a perfect event.",
    1: "Showcase the hotel's achievements with awards, and clarify when it was built and renovated. This helps guests trust the quality and feel confident about their stay.",
    2: "Include both your street name and number for the entire property The floor number is address line 2 where relevant Individual apartment or floor numbers can be shared later. Provide the post/zip code . Correctly spell the street name. Use the physical address of the property, not your office or home address",
  },
  1: "Including the total number of rooms in your hotel description helps guests quickly find accommodations for their group size.",
  2: "It's important to mention the room price to attract the right type of enquiries. Indicating the starting prices helps customers understand the cost and encourages them to contact you directly for a personalized quote.",
  3: "What should I include in my offers and packages? Consider incorporating seasonal offers,such as summer deals, brunch packages or wedding packages, to attract a larger clientele.",
  4: {
    0: "List room features tailored to accommodate individual & group sizes, highlighting amenities such as spacious layouts, multiple beds, and interconnected rooms, to help potential guests make informed decisions when selecting accommodation.",
    1: "Highlight business-friendly features such as high-speed internet, dedicated workspaces, meeting rooms, and business center services to cater to guests seeking accommodation for work-related purposes.",
    2: "Prominently showcase recreational amenities like swimming pools, fitness centers, spa facilities, and on-site restaurants to attract guests seeking leisure and relaxation during their stay.",
  },
  5: "Upload high-quality photos and videos in common formats like JPEG and MP4, focusing on key areas such as rooms and amenties. Ensure files are organized, labeled, and compressed for faster uploads.",
  //   6: "Exterior shot, Entrance, Reception area, Amenities, Past Events in your venue. Please note the individual venue photos within the hotel can be added when creating a listing for that particular venue.Consider a promotional video showcasing past venues or highlighting available venue spaces.",
  //   7: "",
  0: {
    0: "Highlight unique features, describe room types, mention location advantages, and be concise and clear to stand out for a perfect event.",
    1: "Showcase the hotel's achievements with awards, and clarify when it was built and renovated. This helps guests trust the quality and feel confident about their stay.",
    2: "Include both your street name and number for the entire property The floor number is address line 2 where relevant Individual apartment or floor numbers can be shared later. Provide the post/zip code . Correctly spell the street name. Use the physical address of the property, not your office or home address",
  },
  1: "Including the total number of rooms in your hotel description helps guests quickly find accommodations for their group size.",
  2: "It's important to mention the room price to attract the right type of enquiries. Indicating the starting prices helps customers understand the cost and encourages them to contact you directly for a personalized quote.",
  3: "What should I include in my offers and packages? Consider incorporating seasonal offers,such as summer deals, brunch packages or wedding packages, to attract a larger clientele.",
  4: {
    0: "List room features tailored to accommodate individual & group sizes, highlighting amenities such as spacious layouts, multiple beds, and interconnected rooms, to help potential guests make informed decisions when selecting accommodation.",
    1: "Highlight business-friendly features such as high-speed internet, dedicated workspaces, meeting rooms, and business center services to cater to guests seeking accommodation for work-related purposes.",
    2: "Prominently showcase recreational amenities like swimming pools, fitness centers, spa facilities, and on-site restaurants to attract guests seeking leisure and relaxation during their stay.",
  },
  5: "Upload high-quality photos and videos in common formats like JPEG and MP4, focusing on key areas such as rooms and amenties. Ensure files are organized, labeled, and compressed for faster uploads.",
  //   6: "Exterior shot, Entrance, Reception area, Amenities, Past Events in your venue. Please note the individual venue photos within the hotel can be added when creating a listing for that particular venue.Consider a promotional video showcasing past venues or highlighting available venue spaces.",
  //   7: "",
};

export const tipsVenue = {
  0: {
    0: "A venue with multiple event spaces is often called a 'multi-purpose venue' or 'venue complex,' highlighting its versatility and variety. It offers diverse options for hosting various events in one location. Opting for this choice allows you to add more event spaces to the venue.",
    1: "Selecting the venue style and view give clients a clearly  understanding of what the venue has to offer.\n Venue Hire: Select “Whole venue”  if the entire space is available for hire. This would imply that the whole space or partial spaces within the venue can be booked depending on the enquiry. Select “Private” if the venue can be exclusively rented for the event. This venue is typically part of a bigger venue.",
    2: "",
    3: "",
    4: {
      0: "Emphasis distinctive room features to captivate and attract potential guests.",
      1: "Emphasis distinctive business features to captivate and attract potential guests.",
      2: "Emphasis distinctive recreational features to captivate and attract potential guests.",
    },
  },
  1: "",
  2: "Offer tiered pricing options that account for seasonality and group size, providing flexibility and transparency to guests when planning their accommodation, and consider highlighting seasonal discounts or promotions for group bookings.",
  3: "Incorporating special offers and packages into your venue search results can attract more venue seekers and generate additional inquiries, thereby broadening your reach. Ensure that your offers and packages are appealing to captivate potential clients. Optionally, you can include start and end dates for added clarity.",
  5: "Clearly outline the space rules, including age restrictions, whether ticketed events are permitted, and any other important regulations that clients should be aware of.",

  4: "Clearly outline your venue'scatering requirements, including whether in-house catering is available, if outside catering is allowed, and any specific menu options or dietary restrictions that must be accommodated. Providing this information helps clients understand their choices and ensures a seamless dining experience for their event Drinks- Specify your venue’s alcohol policy, including whether you offer in -house bartending services, allow outside alcohol, or have a corkage fee.Clarify any licensing requirements and restrictions on serving times or types of alcohol.This helps clients plan their beverage service effectively and ensures compliance with local regulations.",

  6: {
    0: "Highlight the venue's facilities that will attract clients, showcasing features that enhance their event experience.",
    1: "Offering equipment for events can enhance your venue's attractiveness to clients, so be sure to highlight this feature.",
  },
  7: "Please provide details regarding the music policy, including allowed genres, timing restrictions, and whether clients can bring their own DJs. Clearly outlining these rules will give clients a better understanding of the venue's music regulations.",
  8: "Including floor plans and a trade license is essential, as the trade license verifies the venue's legitimacy and trustworthiness. The floor plan will also help clients understand the venue's size and layout, making their planning process easier.",
  9: "Upload high-quality photos and videos in common formats like JPEG and MP4, focusing on key areas such as rooms and amenities. Ensure files are organized, labeled, and compressed for faster uploads.",
  // 10: "Adding your Hotel Bio will provide the personalized information unique to your space.",
};

// JavaScript code to render the tips dynamically as lists
function renderTips(tips, containerId) {
  const container = document.getElementById(containerId); // Find the container where the list will be rendered
  container.innerHTML = ""; // Clear the container first

  // Loop through each tip category
  for (const key in tips) {
    const category = tips[key];

    // Check if the category is an object (which might contain sub-items like lists)
    if (typeof category === "object") {
      const subList = document.createElement("ul"); // Create a new list element
      for (const subKey in category) {
        const listItem = document.createElement("li");
        listItem.textContent = category[subKey]; // Add the text content
        subList.appendChild(listItem); // Append list item to sublist
      }
      container.appendChild(subList); // Append the sublist to the container
    } else {
      // If the category is just a string, create a single list item
      const listItem = document.createElement("p");
      listItem.textContent = category; // Add the text content
      container.appendChild(listItem); // Append the list item to the container
    }
  }
}

// Render the tips in the HTML container with id 'venue-tips'
// renderTips(tipsVenue, ' s');
