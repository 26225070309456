import React, { useContext, useEffect, useState } from "react";
import DetailComponent from "./DetailComponent";
import { GoPencil } from "react-icons/go";
import Modal from "../../components/shared/custom-modal/custom-modal";
import { IoAddCircleOutline } from "react-icons/io5";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import AddNewVenue from "./AddNewVenue";
import VenuModel from "../../components/shared/VenuModal";
import ListonVenue from "./ListonVenue";
import ArenaHall from "./ArenaHall";
import { RxCross1 } from "react-icons/rx";
import { VenueContext } from "../../context/VenueContext";
import { saveDataForVenue, saveVenueData } from "../../api/venue-api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createResForm, saveResData } from "../../api/restaurant-api";
import moment from "moment";
import axios from "axios";
import { base_URL } from "../../config/config";
import { MdScatterPlot } from "react-icons/md";
import CancelSubscriptionModal from "../onboarding/MySubscriptions/CancelSubscriptionModal";
import CancellationDoneModal from "../onboarding/MySubscriptions/CancellationDoneModal";
import { useUser } from "../../context/user";

const VenueCard = ({
  title,
  type,
  data,
  multiVenue,
  category,
  combineData,
  user,
  frommodify,
  fetchagain,
  refetchUser,
  key,
  cardnumber,
}) => {
  const checkAdmin = localStorage.getItem("checkAdmin");
  const [isCardOpen, setIsCardOpen] = useState(true);
  const [modalStep, setModalStep] = useState(0);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [newTitle, setNewTitle] = useState(title);
  const [filteredData, setFilteredData] = useState([]);
  const { formData, setFormData } = useContext(VenueContext);
  const { tileIs, setTileIs } = useUser();
  const [userHistory, setUserHistory] = useState(user?.history || []);
  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancellationDoneModalOpen, setIsCancellationDoneModalOpen] =
    useState(false);
  const vendorId = localStorage?.getItem("vendorId");
  const navigate = useNavigate();

  useEffect(() => {
    if (title) {
      setNewTitle(title);
    }
  }, [title]);
  useEffect(() => {
    setUserHistory(user?.history || []);
    setUserEmail(user?.email);
  }, [userHistory, user, title]);
  const showCards = () => {
    setIsCardOpen(!isCardOpen);
  };

  // const handleTogglePopup = (index) => {
  //   setActivePopup(activePopup === index ? null : index);
  // };

  const handleOpenModal = (title) => {
    // when clicking on add new saving the tile name so that we can keep track of W/Alochol and Wo/Alcohol
    setTileIs(title);
    setModalStep(1);
  };

  const handleCloseModal = () => {
    setModalStep(0);
  };
  const handleNextModalStep = () => {
    setModalStep(modalStep + 1);
  };

  const handleRenameClick = () => {
    setIsRenameModalOpen(true);
  };

  const handleRenameClose = () => {
    setIsRenameModalOpen(false);
  };

  const handleRenameSave = () => {
    const trimmedTitle = newTitle.trim();
    if (trimmedTitle.length == 0) {
      toast.error("The venue name cannot be empty or just spaces.");
      return;
    }
    setIsRenameModalOpen(false);
  };

  const handleAddSubVenue = () => {
    setModalStep(2); // Open the ListonVenue modal
  };

  const handleSelectVenue = (filteredData) => {
    setFilteredData(filteredData);
    setModalStep(3); // Open the ArenaHall modal
  };

  const handleSaveData = async () => {
    setLoading(true); // Set loading to true when starting the API call
    try {
      let updatedData;

      if (category === "Venue" && combineData === "Restaurant") {
        const firstItem = data[0];
        updatedData = {
          ...formData,
          vendorId,
          basicDetails: {
            ...formData?.basicDetails,
            hotelName: firstItem?.basicDetails?.isPrimaryVenue
              ? firstItem?.basicDetails?.venueName
              : firstItem?.basicDetails?.hotelName,
            isPrimaryVenue: false,
          },
          location: firstItem?.location,
          status: "pending",
        };

        const response = await createResForm(updatedData);
        if (response.success && response?.venueForm?._id) {
          navigate(`/create-Listing/Restaurant/${response.venueForm?._id}`); // Use navigate from react-router
        }
      } else if (category === "Venue" && combineData === "Venue") {
        const firstItem = data[0];
        updatedData = {
          ...formData,
          vendorId: vendorId,
          basicDetails: {
            ...formData?.basicDetails,
            isPrimaryVenue: false,
          },
          location: firstItem?.overview?.locationDetails,
          status: "pending",
        };
        const response = await saveDataForVenue(updatedData); // Make sure saveVenueData is defined
        if (response.success && response?.venueForm?._id) {
          navigate(`/create-Listing/${category}/${response.venueForm?._id}`); // Use navigate from react-router
        }
      } else if (category === "Venue") {
        const firstItem = data[0];
        updatedData = {
          ...formData,
          vendorId: vendorId, // Make sure to define or obtain vendorId
          basicDetails: {
            ...formData?.basicDetails,
            hotelName: firstItem?.overview?.basicDetails?.hotelName,
            isPrimaryVenue: false,
          },
          location: firstItem?.overview?.locationDetails,
          status: "pending",
        };
        const response = await saveDataForVenue(updatedData); // Make sure saveVenueData is defined
        if (response.success && response?.venueForm?._id) {
          navigate(`/create-Listing/${category}/${response.venueForm?._id}`); // Use navigate from react-router
        }
      }
    } catch (error) {
      toast.error(
        error.response?.data?.error || "An error occurred. Please try again."
      );
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  // for cancellation testing commenting code
  const handleSeperateCancellation = async (newTitle) => {
    let decrementAmount = 0;

    // Determine the decrement amount based on title
    if (newTitle.includes("Restaurant w/Alcohol")) {
      const withAlcoholNumber = user?.onBoardingDetails?.withAlcoholNumber || 0;

      // if (withAlcoholNumber === 1) {
      //   decrementAmount = 1500;
      // } else if (withAlcoholNumber >= 2 && withAlcoholNumber <= 5) {
      //   decrementAmount = 1300;
      // } else if (withAlcoholNumber > 5) {
      //   decrementAmount = 1200;
      // }

      if (withAlcoholNumber === 1) {
        decrementAmount = 1;
      } else if (withAlcoholNumber >= 2 && withAlcoholNumber <= 5) {
        decrementAmount = 1;
      } else if (withAlcoholNumber > 5) {
        decrementAmount = 1;
      }
    } else if (newTitle.includes("Restaurant w/o Alcohol")) {
      const withoutAlcoholNumber =
        user?.onBoardingDetails?.withoutAlcoholNumber || 0;

      // if (withoutAlcoholNumber === 1) {
      //   decrementAmount = 1000;
      // } else if (withoutAlcoholNumber >= 2 && withoutAlcoholNumber <= 5) {
      //   decrementAmount = 900;
      // } else if (withoutAlcoholNumber > 5) {
      //   decrementAmount = 800;
      // }
      if (withoutAlcoholNumber === 1) {
        decrementAmount = 1;
      } else if (withoutAlcoholNumber >= 2 && withoutAlcoholNumber <= 5) {
        decrementAmount = 1;
      } else if (withoutAlcoholNumber > 5) {
        decrementAmount = 1;
      }
    } else if (newTitle.includes("Hotel")) {
      // Check and confirm if newTitle correctly matches for Hotel cancellation
      // decrementAmount = 2000;
      decrementAmount = 1;
    } else {
      console.error("Unknown title for cancellation:", newTitle);
    }

    let totalItems = 0;

    // if (Array.isArray(user?.onBoardingDetails?.items)) {
    //   if (
    //     user?.onBoardingDetails?.selectedChips?.length === 1 &&
    //     user?.onBoardingDetails?.selectedChips[0] === "Kids Play Area"
    //   ) {
    //     totalItems = user?.onBoardingDetails?.items.reduce((acc, item) => {
    //       return acc + 1000 * item.amount;
    //     }, 0);
    //   } else {
    //     totalItems = user?.onBoardingDetails?.items.reduce((acc, item) => {
    //       const rate = item.answer === "yes" ? 2000 : 1000;
    //       return acc + rate * item.amount;
    //     }, 0);
    //   }
    // }
    if (Array.isArray(user?.onBoardingDetails?.items)) {
      if (
        user?.onBoardingDetails?.selectedChips?.length === 1 &&
        user?.onBoardingDetails?.selectedChips[0] === "Kids Play Area"
      ) {
        totalItems = user?.onBoardingDetails?.items.reduce((acc, item) => {
          return acc + 1 * item.amount;
        }, 0);
      } else {
        totalItems = user?.onBoardingDetails?.items.reduce((acc, item) => {
          const rate = item.answer === "yes" ? 1 : 1;
          return acc + rate * item.amount;
        }, 0);
      }
    }

    const totalAmountToSend = decrementAmount + totalItems;

    try {
      setLoading(true);
      const response = await axios.post(
        `${base_URL}/api/vendor/cancelSeperately`,
        {
          email: user?.email,
          decrementAmount: totalAmountToSend,
          newTitle,
        }
      );
      toast.success(`${newTitle} Subscription cancelled successfully`);
      fetchagain();
      await refetchUser();
      setUserHistory((prevHistory) => [...prevHistory, newTitle]);
    } catch (error) {
      console.error("Error in API call:", error);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => setIsModalOpen(false);
  const closeCancellationDoneModal = () => {
    setIsCancellationDoneModalOpen(false);
  };
  const openCancellationDoneModal = () => {
    const selectedReason = localStorage.getItem("selectedReason");
    const enterReason = localStorage.getItem("enterReason");

    axios
      .post(`${base_URL}/api/vendor/cancel-subs`, {
        selectedReason: selectedReason,
        enterReason: enterReason,
        email: user?.email,
      })
      .then((response) => {
        setIsCancellationDoneModalOpen(true); // Open the cancellation done modal
      })
      .catch((error) => {
        console.error("Error during cancellation", error);
        toast.error("No Subscription found");
      });

    localStorage.removeItem("selectedReason");
    localStorage.removeItem("enterReason");
    closeModal(); // Close the cancellation modal
  };
  return (
    <>
      <CancelSubscriptionModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onContinue={openCancellationDoneModal}
      />
      <CancellationDoneModal
        isOpen={isCancellationDoneModalOpen}
        onClose={closeCancellationDoneModal}
        user={user}
      />
      <div className="flex flex-col md:flex-row justify-between items-center p-4 bg-white rounded-lg box-shadow mt-6 md:h-[80px] h-auto">
        <div className="">
          <div className="flex w-full items-center">
            <div className="font-medium text-sm md:text-2xl pr-3 truncate max-w-lg">
              {newTitle}
            </div>
            {combineData !== "hotel" && !frommodify && (
              <GoPencil
                className="text-lg w-5 cursor-pointer mt-2"
                onClick={handleRenameClick}
              />
            )}
          </div>

          <div className="text-[16px] text-[#717171] font-[400]">{type}</div>
        </div>
        {/* determining by checking history weather this was cancelled or not */}
        {frommodify ? (
          userHistory?.includes(newTitle) ? (
            <div className="flex flex-col">
              <div className="text-[12px] md:text-[14px]">
                You have access to your account until &nbsp;{" "}
                {moment(user?.trialEnd).format("MMMM D, YYYY")}
              </div>
            </div>
          ) : (
            <div className="flex flex-col text-[12px] md:text-[14px]">
              <div>
                Active until &nbsp;
                {moment(user?.trialEnd).format("MMMM D, YYYY")}
              </div>
              <div className="mx-auto w-full  flex items-end justify-end">
                {loading ? (
                  <>
                    <button className=" flex gap-x-3 justify-center items-center  active:opacity-55  text-black p-2 rounded-lg ">
                      Cancelling <MdScatterPlot className=" animate-spin" />
                    </button>
                  </>
                ) : (
                  <>
                    <div
                      onClick={() => handleSeperateCancellation(newTitle)}
                      className="text-red-400 cursor-pointer active:opacity-55 underline underline-offset-2"
                    >
                      Cancel
                    </div>
                  </>
                )}
              </div>
            </div>
          )
        ) : null}
        {!frommodify && (
          <div className="flex items-center space-x-3">
            {checkAdmin === "true" && !frommodify && (
              <button
                disabled={!multiVenue}
                onClick={() => handleOpenModal(title)}
                className={`flex gap-3 items-center ${
                  multiVenue
                    ? "text-gray-500"
                    : "cursor-not-allowed text-gray-200"
                }`}
              >
                <IoAddCircleOutline className="text-3xl cursor-pointer font-semibold" />
                <p className="text-[20px] text-[#5e5e5e] font-[500]">
                  Add venue
                </p>
              </button>
            )}
            {!isCardOpen ? (
              <IoIosArrowDown
                className="text-3xl text-[#fe4747] cursor-pointer font-[400]"
                onClick={showCards}
              />
            ) : (
              <IoIosArrowUp
                className="text-3xl text-[#fe4747] cursor-pointer font-[400]"
                onClick={showCards}
              />
            )}
          </div>
        )}
      </div>
      {isCardOpen && !frommodify && (
        <div className="flex justify-center ">
          <DetailComponent
            data={data}
            category={category}
            combineData={combineData}
            isCardOpen={isCardOpen}
          />
        </div>
      )}

      {modalStep === 1 && (
        <Modal isOpen={true} onClose={handleCloseModal}>
          <AddNewVenue
            category={category}
            data={data}
            combineData={combineData}
            title={title}
            onContinue={handleNextModalStep}
            onClose={handleCloseModal}
            onAddSubVenue={handleAddSubVenue}
          />
        </Modal>
      )}

      {isRenameModalOpen && (
        <Modal isOpen={true} onClose={handleRenameClose}>
          <div className="p-6 bg-white rounded-lg">
            <button
              onClick={handleRenameClose}
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
            >
              <RxCross1 className="h-6 w-6" />
            </button>
            <h2 className="text-3xl text-center font-semibold mb-4">
              Rename Venue
            </h2>
            <label className="block mb-2">Name of the venue</label>
            <input
              type="text"
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
              className="border rounded w-full py-3 px-3 mb-4"
            />
            <button
              onClick={handleRenameSave}
              className="bg-red-500 text-white py-2 px-4 rounded w-full"
            >
              Save
            </button>
          </div>
        </Modal>
      )}

      {modalStep === 2 && (
        <VenuModel isOpen={true} onClose={handleCloseModal}>
          <ListonVenue
            category={category}
            data={data}
            combineData={combineData}
            onContinue={handleNextModalStep}
            onClose={handleCloseModal}
            onSelectVenue={handleSelectVenue}
          />
        </VenuModel>
      )}

      {modalStep === 3 && (
        <VenuModel isOpen={true} onClose={handleCloseModal}>
          <ArenaHall
            title={title}
            formData={formData}
            setFormData={setFormData}
            filteredData={filteredData}
            onContinue={handleSaveData}
            onClose={handleCloseModal}
          />
        </VenuModel>
      )}
    </>
  );
};

export default VenueCard;
