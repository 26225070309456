import React from "react";
import { Route, Navigate } from "react-router-dom";
import { useAuth } from "./auth";

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const user = localStorage.getItem("accessToken");

  return user ? <Component {...rest} /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
