import { useState } from "react";
import {
  TextField,
  InputAdornment,
  Box,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { ReactComponent as CouponIcon } from "../../../assets/images/coupon_icon.svg";
import { useTheme } from "@mui/material";

const couponCodes = {
  FMV10OFF: 10,
  "FMV-20OFF": 20,
  CODE40: 40,
  HALF50: 50,
  DISC60: 60,
  UNIQUE75: 75,
  EARLYBIRD90: 90,
  SPECIAL95: 95,
};

const CouponInputFieldSubscription = ({ onApplyDiscount }) => {
  const theme = useTheme();
  const [couponText, setCouponText] = useState("");
  const [isCouponValid, setIsCouponValid] = useState(true); // Initially assuming it's valid
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleChange = (event) => {
    const value = event.target.value;
    setCouponText(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onApplyDiscount(couponText.trim());
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        alignItems: "center",
        width: "95%",
        margin: "7px auto",
        // backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,

        border: `1px solid ${theme.palette.grey.border}`,
        textTransform: "none",
        boxShadow: "none",
        borderRadius: 1,
        "&:hover": {
          backgroundColor: "white",
        },
      }}
    >
      <TextField
        variant="outlined"
        value={couponText}
        onChange={handleChange}
        fullWidth
        placeholder="Enter coupon code"
        sx={{ borderRadius: "8px" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CouponIcon />
            </InputAdornment>
          ),
          sx: {
            borderRadius: "8px",
            "& .MuiOutlinedInput-input": {
              textAlign: "center",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            backgroundColor: "transparent",
          },
        }}
        inputProps={{
          style: {
            color: "black",
          },
        }}
      />
      <Button
        type="submit"
        variant="contained"
        size="large"
        sx={{
          mr: 2,
          borderRadius: "8px",
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          textTransform: "none",
          "&:hover": {
            backgroundColor: theme.palette.black,
          },
        }}
      >
        Apply
      </Button>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CouponInputFieldSubscription;
