import React from "react";

const CustomRadioBox = ({
  id,
  title,
  icon,
  description,
  selectedId,
  onSelect,
  customHeightClass,
  checked,
}) => {
  const isSelected = selectedId == id;
  let selectedIcon = (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12" r="11.5" fill="#039F8D" stroke="#039F8D" />
      <circle cx="12.5" cy="12" r="7" fill="white" />
    </svg>
  );
  let unselectedIcon = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="11.5" fill="white" stroke="#C2C2C2" />
    </svg>
  );

  return (
    <div onClick={() => onSelect(id)} className={`cursor-pointer `}>
      <div className={`border rounded-lg p-3 ${customHeightClass}`}>
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-3 my-2">
            {/* {icon} */}
            <img src={icon} alt="" className="w-[32px] h-[32px]" />
            <p className=" text-[16px] text-md text-[#222222]">{title}</p>
          </div>
          <div>{checked ? selectedIcon : unselectedIcon}</div>
        </div>
        <p className="md:relative break-words  xl:left-[40px] xl:w-[270px] font-[400px] text-[#5e5e5e] text-[12px] xl:text-[14px]">
          {description}
        </p>
      </div>
    </div>
  );
};

export default CustomRadioBox;
