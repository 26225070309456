
import { useState } from "react";

const EventType = ({ data }) => {
  // Extract all items from the eventType data and flatten them into a single array
  const allItems = Object.values(data?.eventType || {})
    .flat()
    .filter((item) => typeof item === "string"); // Ensure all items are strings

  // State to manage the visibility of items
  const [showMore, setShowMore] = useState(false);

  // Determine the items to display based on showMore state
  const displayedItems = showMore ? allItems : allItems.slice(0, 10);

  return (
    <div className="py-6 lg:w-3/4 w-full lg:flex-row flex-col flex  justify-between  gap-5 border-t border-gray-300 mx-auto">
      <h2 className="text-2xl font-bold mb-4">Event Type</h2>
      <div className=" lg:w-[75%] w-full">
        <div className="flex flex-wrap gap-4 w-full">
          {displayedItems?.length > 0 ? (
            displayedItems?.map((item, index) => (
              <span
                key={index}
                className="px-4 py-2 border sm:text-base text-xs rounded-full border-[#FE4747] text-black cursor-pointer bg-white"
              >
                {item}
              </span>
            ))
          ) : (
            <p className="text-gray-500">No items available</p>
          )}
        </div>
        {/* Show More/Less button */}
        {allItems?.length > 10 && (
          <button
            onClick={() => setShowMore((prev) => !prev)}
            className="mt-4 text-[#FE4747] underline"
          >
            {showMore ? "Show Less" : "Show More"}
          </button>
        )}
      </div>
    </div>
  );
};

export default EventType;
