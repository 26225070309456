import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const StyledInput = ({
  id,
  label,
  placeholder,
  type,
  value,
  onChange,
  width,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mb: 2,
        width: { xs: "100%", md: width } || "100%",
      }}
    >
      <Typography
        variant="body1"
        gutterBottom
        fontWeight="300"
        align="left"
        sx={{
          fontSize: {
            xs: "subtitle2.fontSize",
            md: "body1.fontSize",
            color: theme.palette.grey.text,
          },
        }}
      >
        {label}
      </Typography>
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        style={{
          width: "100%",
          height: "45px",
          borderRadius: "10px",
          padding: "0 15px",
          fontSize: "0.9rem",
          border: "1px solid #c4c4c4",
        }}
        disabled
      />
    </Box>
  );
};

export default StyledInput;
