import React, { useState } from "react";
import img1 from "../../assets/pics/work1.png";
import img2 from "../../assets/pics/work2.png";
import img3 from "../../assets/pics/work3.png";
import rope from "../../assets/pics/workRope.svg";
import { BsArrowRight } from "react-icons/bs";

const HowWorks = () => {
  const [showVideo, setShowVideo] = useState(false);
  const data = [
    {
      img: img1,
      step: "Step 1",
      name: "Browse Venues",
      des: "Explore a variety of venue options for every event",
    },
    {
      img: img2,
      step: "Step 2",
      name: "Compare Venues",
      des: "Shortlist & compare your favorites, that best match your requirements",
    },
    {
      img: img3,
      step: "Step 3",
      name: "Send Enquiries",
      des: "Connect directly with venues to request a personalized proposal",
    },
  ];

  return (
    <div className="flex flex-col items-center w-full pt-16 sm:px-32 px-5 ">
      <div className="flex flex-col items-center z-[100px]">
        <h1 className="md:text-[40px] text-[33px] text-[#252525] font-semibold">
          How it works
        </h1>
        <p className="mt-2 text-[#626262] md:text-[24px] text-[22px]">
          Find your ideal venue in three simple steps
        </p>
      </div>
      <div className="relative flex justify-center z-[10px]">
        <div className="grid md:grid-cols-3 grid-cols-1  gap-2 mt-8 w-full ">
          {data.map((e, i) => (
            <div key={i} className="flex flex-col items-center gap-2">
              <img src={e.img} alt="" className="h-[220px] w-[220px]" />
              <h1 className="text-[#7B7B7B] md:text-[20px] text-[16px] font-semibold">
                {e.step}
              </h1>
              <div className="md:w-[70%] w-full md:ml-[15px] ml-0">
                <h1 className="text-[#252525] mdtext-[24px] text-[20px]  text-center font-semibold">
                  {e.name}
                </h1>
                <div className="text-[#5B5B5B] md:text-[20px] text-[16px]  text-center  ">
                  {e.des}
                </div>
              </div>
            </div>
          ))}
        </div>
        <img
          src={rope}
          className="lg:block hidden absolute top-0 left-0 "
          alt=""
        />
      </div>
      <div className="flex flex-col items-center gap-3 my-10">
        <h1 className="text-lg text-center">Learn more about our process</h1>
        {!showVideo ? (
          <button
            onClick={() => setShowVideo(true)}
            className="text-white flex bg-black py-3 items-center justify-center rounded-lg gap-2 w-[170px]"
          >
            Watch Video <BsArrowRight />
          </button>
        ) : (
          <video
            src="https://st-findmyvenue.s3.ap-south-1.amazonaws.com/FindMyVenue+Intro+-+High+Res+.mp4"
            controls
            autoPlay
            className="w-full max-w-xl lg:max-w-5xl rounded-lg"
          />
        )}
      </div>
    </div>
  );
};

export default HowWorks;
