import axios from "axios";
import { base_URL } from "../config/config";

const apiService = axios.create({
  baseURL: base_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const getHotelData = async (vendorId) => {
  const response = await apiService.get(`/api/hotel/getHotel/${vendorId}`);
  return response.data.hotel;
};

export const getVendorHotel = async (vendorId) => {
  const response = await apiService.get(
    `/api/hotel/getVendorHotel/${vendorId}`
  );
  return response.data;
};

export const saveHotelData = async (data) => {
  const response = await apiService.post("/api/hotel/registerHotel", data);
  return response.data;
};

export const updateHotelForm = async (data) => {
  const response = await apiService.post("/api/hotel/updateHotelForm", data);
  return response.data;
};

export const unpublishHotel = async ({ id, category }) => {
  try {
    const response = await apiService.put(
      `/api/hotel/unpublishHotel/${category}/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Error unpublishing hotel:", error);
  }
};

export const getHotelById = async (id) => {
  try {
    const response = await apiService.get(`/api/hotel/${id}`);
    return response?.data;
  } catch (error) {
    console.error("Error fetching onboarding details:", error);
  }
};
