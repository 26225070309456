import axios from 'axios';
import { base_URL } from '../config/config';


const API_BASE_URL = `${base_URL}/`;


export const payment = async (paymentData) => {
    try {
        const accessToken = localStorage.getItem('accessToken');
        const response = await axios.post(
            `${API_BASE_URL}api/vendor/payment-create`,
            paymentData,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error processing payment:', error);
        throw error;
    }
};


export const getBillingDetails = async (vendorId) => {
    try {
        const accessToken = localStorage.getItem('accessToken');
        const response = await axios.get(
            `${API_BASE_URL}api/vendor/get-billing-details/${vendorId}`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching billing details:', error);
        throw error;
    }
};


export const updateBillingDetails = async (
    vendorId,
    billingDetails
) => {
    try {
        const accessToken = localStorage.getItem('accessToken');
        const response = await axios.put(
            `${API_BASE_URL}api/vendor/update-billing-details/${vendorId}`,
            { billingDetails },
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error updating billing details:', error);
        throw error;
    }
};



