import { useState } from "react";
import { TextField, InputAdornment, Box } from "@mui/material";
import { ReactComponent as CouponIcon } from "../../../assets/images/coupon_icon.svg";
import { useTheme } from "@mui/material";

const CouponTextfieldSubscription = () => {
  const theme = useTheme();
  const [couponText, setCouponText] = useState("Start your Free 6 Month Trial");
  const [isCouponValid, setIsCouponValid] = useState(true);

  const handleChange = (event) => {
    const value = event.target.value;
    setCouponText(value);
    setIsCouponValid(value.trim() !== "");
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "95%",
        margin: "auto",
        backgroundColor: isCouponValid ? theme.palette.green.light : "white",
        color: isCouponValid ? theme.palette.green.text : "black",
        textTransform: "none",
        boxShadow: "none",
        borderRadius: 1,
        border: isCouponValid
          ? `1px solid ${theme.palette.green.light}`
          : `1px solid ${theme.palette.grey.border}`,
        "&:hover": {
          backgroundColor: isCouponValid ? theme.palette.green.light : "white",
        },
      }}
    >
      <TextField
        variant="outlined"
        value={couponText}
        onChange={handleChange}
        readOnly={true}
        fullWidth
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <CouponIcon />
            </InputAdornment>
          ),
          sx: {
            "& .MuiOutlinedInput-input": {
              textAlign: "center",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            backgroundColor: "transparent",
          },
        }}
        inputProps={{
          style: {
            color: isCouponValid ? theme.palette.green.text : "black",
          },
        }}
      />
    </Box>
  );
};

export default CouponTextfieldSubscription;
