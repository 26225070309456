/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { HotelContext } from '../../context/HotelContext';
import { toast } from 'react-toastify';
import BasicForm from '../../components/hostel_listing_form/overview-form/basic-form';
import icon from '../../assets/icon2.svg';
import { FaSave } from 'react-icons/fa';
import { MdSync } from 'react-icons/md';
import AdditionalDetailsForm from '../../components/hostel_listing_form/overview-form/addition-detail-from';
import LocationForm from '../../components/hostel_listing_form/overview-form/location';
import AccommodationForm from '../../components/hostel_listing_form/form-accommodation';
import Stepper from '../../components/hostel_listing_form/stepper';
import PricingForm from '../../components/hostel_listing_form/pricing-form';
import OfferForm from '../../components/hostel_listing_form/offer-package/form';
import RoomFeatures from '../../components/hostel_listing_form/amenities/room-feature-from';
import BusinessFeatures from '../../components/hostel_listing_form/amenities/business-feature-form';
import RecreationalFeatures from '../../components/hostel_listing_form/amenities/recreation-features';
import UploadPhotosVideos from '../../components/hostel_listing_form/photo-video-form';
import CardPreview from '../../components/hostel_listing_form/card-preview';
import Navbar from '../../components/Navbar';
import {
	validateAccoumentionForm,
	validateAdditionForm,
	validateBasicForm,
	validateBusinessFeatures,
	validateLocationForm,
	validatePhotoVideoForm,
	validatePriceForm,
	validateRoomFeatures,
} from '../../components/hostel_listing_form/validation';
// import { useQuery } from '@tanstack/react-query';
import { updateHotelForm } from '../../api/hotel-api';
import { tipsHotel } from '../../constants/tips';
import { getSingleVenue } from '../../api/venue-api';

const EditHostelListingForm = () => {
	const navigate = useNavigate();
	const [currentStep, setCurrentStep] = useState(0);
	const [currentSubStep, setCurrentSubStep] = useState(0);
	const { formData, setFormData } = useContext(HotelContext);
	const vendorId = localStorage?.getItem('vendorId');
	const [isAutoSaveEnabled, setIsAutoSaveEnabled] = useState(true);
	const [awards, setAwards] = useState([{ name: '', year: '' }]);
	const [errors, setErrors] = useState({
		hotelBuiltYear: '',
		lastRenovatedYear: '',
		awards: [],
	});
	const { category, id } = useParams();

	useEffect(() => {
		const fetchData = async () => {
			if (!category || !id) return;

			try {
				const response = await getSingleVenue({ category, id });
				setFormData(response);
			} catch (error) {
				toast.error('Error fetching venue data.');
			}
		};

		fetchData();
	}, [category, id, setFormData]);

	const handleSaveLater = async (status = 'pending') => {
		try {
			const updatedData = {
				...formData,
				vendorId,
				status,
			};
			const response = await updateHotelForm(updatedData);
			if (response.success) {
				toast.success(` Saved !`);
				setFormData('');
				navigate('/dashboard?hotelRegisterPending=true');
			} else {
				toast.error(
					response.error || 'An error occurred. Please try again.'
				);
			}
		} catch (error) {
			toast.error(
				error.response?.data?.error ||
					'An error occurred. Please try again.'
			);
			console.error('Error:', error);
		}
	};
	const handleSaveData = async (status = 'pending') => {
		try {
			const updatedData = {
				...formData,
				vendorId,
				status,
			};
			const response = await updateHotelForm(updatedData);
			if (response.success) {
				// toast.success(
				//   `${status === "completed" ? "Created" : "Saved"} !`
				// );
				if (status === 'completed') {
					toast.success('Created!');
					setTimeout(() => {
						navigate('/dashboard?hotelRegister=true');
					}, 1000);
				}
			} else {
				toast.error(
					response.error || 'An error occurred. Please try again.'
				);
			}
		} catch (error) {
			toast.error(
				error.response?.data?.error ||
					'An error occurred. Please try again.'
			);
			console.error('Error:', error);
		}
	};

	// const handleSubmit = async () => {
	//     let errorMessage = '';
	//     let hasErrors = false;
	//     // Check for errors in each step
	//     if (formData.errors.overview) {
	//         errorMessage += 'Data is missing in Overview.\n';
	//         hasErrors = true;
	//     }
	//     if (formData.errors.accommodation) {
	//         errorMessage += 'Data is missing in Accommodation.\n';
	//         hasErrors = true;
	//     }
	//     if (formData.errors.pricing) {
	//         errorMessage += 'Data is missing in Pricing.\n';
	//         hasErrors = true;
	//     }
	//     if (formData.errors.photoVideo) {
	//         errorMessage += 'Data is missing in Photos & Videos.\n';
	//         hasErrors = true;
	//     }
	//     if (hasErrors) {
	//         toast.error(errorMessage.trim());
	//     } else {
	//         try {
	//             // Simulate an API call or any asynchronous operation
	//             await handleSaveData('completed');
	//         } catch (error) {
	//             toast.error('An error occurred while saving data.');
	//             console.error(error);
	//         }
	//     }
	// };
	const handleSaveForLater = async () => {
		await handleSaveLater('pending');
	};

	const handleContinue = async () => {
		const lastStep = 6;
		const currentSubStepCount = subSteps[currentStep].length;

		// Perform basic validation based on current step and substep
		if (
			currentStep === 0 &&
			currentSubStep === 0 &&
			!validateBasicForm(formData, setFormData)
		) {
			return;
		}
		if (
			currentStep === 0 &&
			currentSubStep === 1 &&
			!validateAdditionForm(formData, setFormData)
		) {
			return;
		}

		// Validate awards and ensure no errors are present
		// const awardsValid = validateAwards();
		// if (currentStep === 0 && currentSubStep === 1 && !awardsValid) {
		//   return;
		// }

		if (
			currentStep === 0 &&
			currentSubStep === 2 &&
			!validateLocationForm(formData, setFormData)
		) {
			return;
		}
		if (
			currentStep === 1 &&
			!validateAccoumentionForm(formData, setFormData)
		) {
			return;
		}
		if (
			currentStep === 2 &&
			!validatePriceForm(formData, setFormData)
		) {
			return;
		}
		if (
      currentStep === 4 &&
      currentSubStep === 0 &&
      !validateRoomFeatures(formData, setFormData)
    ) {
      return;
    }
    if (
      currentStep === 4 &&
      currentSubStep === 1 &&
      !validateBusinessFeatures(formData, setFormData)
    ) {
      return;
    }
		if (
			currentStep === 5 &&
			!validatePhotoVideoForm(formData, setFormData)
		) {
			return;
		}

		if (currentStep === lastStep) {
			// await handleSubmit();
			navigate('/hotel-preview');
		} else {
			if (isAutoSaveEnabled) {
				await handleSaveData('pending');
			}
			if (currentSubStep < currentSubStepCount - 1) {
				setCurrentSubStep(currentSubStep + 1);
			} else {
				setCurrentStep(currentStep + 1);
				setCurrentSubStep(0);
			}
		}
	};

	const subSteps = [
		['BasicForm', 'AdditionalDetailsForm', 'LocationForm'],
		[],
		[],
		[],
		['RoomFeatures', 'BusinessFeatures', 'RecreationalFeatures'],
		[],
		[],
	];

	const renderForm = () => {
		if (currentStep === 0) {
			switch (currentSubStep) {
				case 0:
					return <BasicForm />;
				case 1:
					return (
						<AdditionalDetailsForm
							awards={awards}
							setAwards={setAwards}
							errors={errors}
							setErrors={setErrors}
						/>
					);
				case 2:
					return <LocationForm />;
				default:
					return <BasicForm />;
			}
		}
		if (currentStep === 4) {
			switch (currentSubStep) {
				case 0:
					return <RoomFeatures />;
				case 1:
					return <BusinessFeatures />;
				case 2:
					return <RecreationalFeatures />;
				default:
					return <BasicForm />;
			}
		}
		switch (currentStep) {
			case 1:
				return <AccommodationForm />;
			case 2:
				return <PricingForm />;
			case 3:
				return <OfferForm />;
			case 5:
				return <UploadPhotosVideos />;
			case 6:
				return <CardPreview />;
			default:
				return <BasicForm />;
		}
	};

	const renderTip = () => {
		if (currentStep === 0 || currentStep === 4) {
			return tipsHotel[currentStep][currentSubStep];
		}
		return tipsHotel[currentStep];
	};

	return (
    <>
      <Navbar />

      <div className="flex px-5 lg:px-10 py-5 min-h-[90vh] bg-[#F3F3F3] w-full">
        <div className="flex justify-between flex-wrap gap-[2px] w-full">
          <div className="flex justify-start flex-wrap rounded-2xl bg-white lg:w-[74%] w-full">
            <div className="lg:w-1/4 w-full border-r border-lightGray mt-4">
              <Stepper
                currentStep={currentStep}
                currentSubStep={currentSubStep}
                setCurrentStep={setCurrentStep}
                setCurrentSubStep={setCurrentSubStep}
              />
            </div>
            <div className="lg:w-3/4 w-full px-5 py-5">{renderForm()}</div>
            <div className="w-full bg-[#F3F3F3] mt-5 p-2 flex justify-between items-center">
              <button
                className="flex text-base font-outfitt font-medium items-center text-mediumGray"
                onClick={handleSaveForLater}
              >
                <FaSave className="mr-2" size={17} />
                <span className="underline">Save for later</span>
              </button>
              <div className="flex gap-5 items-center">
                <button
                  onClick={() => setIsAutoSaveEnabled(!isAutoSaveEnabled)}
                  className={`flex space-x-2 text-base font-outfitt font-medium items-center text-mediumGray ${
                    isAutoSaveEnabled
                      ? "text-green-500 animate-bounce"
                      : "text-mediumGray"
                  }`}
                >
                  <span className="text-sm font-normal">
                    Auto Save is {isAutoSaveEnabled ? "on" : "off"}
                  </span>
                  <MdSync className="mr-2 text-mediumGray" size={15.5} />
                </button>
                <button
                  className="bg-primary text-white px-4 py-2 lg:w-48 rounded-lg hover:bg-red-600"
                  onClick={handleContinue}
                >
                  {currentStep === 5
                    ? "Show preview"
                    : currentStep === 6
                    ? "Preview detail page"
                    : "Continue"}
                </button>
              </div>
            </div>
          </div>
          {currentStep !== 6 && (
            <div className="lg:w-[24%] px-2 w-full">
              <div className="flex flex-col bg-[#F7F7F7] border border-[#EBEBEB] rounded-xl p-4 items-start">
                <img src={icon} alt="" width={59} height={59} />
                <div className="mt-4">
                  <h3 className="text-base font-medium text-[#222222]">Tips</h3>
                  <p className="text-[#5e5e5e] font-normal text-sm leading-[21.84px]">
                    {renderTip()}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditHostelListingForm;
