import axios from 'axios';
import { base_URL } from '../config/config';

// Create an axios instance with default configurations
const apiService = axios.create({
    baseURL: base_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Function to get restaurant data by vendor ID
export const getResData = async (vendorId) => {
    try {
        const response = await apiService.get(`/api/restaurantform/getRestaurant/${vendorId}`);
        return response.data.venueForms; // Adjust this based on actual API response
    } catch (error) {
        console.error('Error fetching restaurant data:', error);
        throw new Error(error.response?.data?.error || 'An error occurred while fetching restaurant data.');
    }
};

// Function to get restaurant data by vendor ID
export const getRestaurant = async (id) => {
    try {
        const response = await apiService.get(`/api/restaurantform/getResForm/${id}`);
        return response.data.venueForm; // Adjust this based on actual API response
    } catch (error) {
        console.error('Error fetching restaurant data:', error);
        throw new Error(error.response?.data?.error || 'An error occurred while fetching restaurant data.');
    }
};

// Function to save restaurant data
export const saveResData = async (data) => {
    try {
        const response = await apiService.post('/api/restaurantform/createRestaurant', data);
        return response.data;
    } catch (error) {
        console.error('Error saving restaurant data:', error);
        throw new Error(error.response?.data?.error || 'An error occurred while saving restaurant data.');
    }
};

export const createResForm = async (data) => {
    try {
        const response = await apiService.post('/api/restaurantform/createResForm', data);
        return response.data;
    } catch (error) {
        console.error('Error saving restaurant data:', error);
        throw new Error(error.response?.data?.error || 'An error occurred while saving restaurant data.');
    }
};

export const updateResForm = async (data) => {
    try {
        const response = await apiService.post('/api/restaurantform/updateResForm', data);
        return response.data;
    } catch (error) {
        console.error('Error saving restaurant data:', error.message);
        throw new Error(error.response?.data?.error.message || 'An error occurred while saving restaurant data.');
    }
};
