import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import logo from "../assets/images/logo.svg";
import profileIcon from "../assets/images/userres.svg";
import profileIcon2 from "../assets/images/downres.svg";
import { Cookies } from "react-cookie";

function ResponsiveAppBar() {
  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const isLoggedIn = !!localStorage.getItem("accessToken");

  const handleOpenProfileMenu = (event) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setAnchorElProfile(null);
  };

  const handleLogout = () => {
    const cookies = new Cookies();
    cookies.remove("token", { path: "/" });
    localStorage.clear();
    navigate("/");
  };

  return (
    <AppBar position="static" className="z-[9999]">
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ width: "100%", zIndex: 10 }}>
          <div style={{ display: "block", width: "auto", zIndex: 10 }}>
            <Box
              component="img"
              src={logo}
              alt="Logo"
              sx={{ display: "block", width: "100%", height: "auto" }}
            />
          </div>

          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ display: "flex", alignItems: "center", zIndex: 10 }}>
            {location.pathname !== "/onboarding" && (
              <Link
                to="/list-venues"
                style={{
                  marginRight: "16px",
                  paddingRight: "8px",
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                List your Venue
              </Link>
            )}
            <Box
              sx={{ display: { md: "flex" }, alignItems: "center", zIndex: 10 }}
            >
              <IconButton onClick={handleOpenProfileMenu} sx={{ zIndex: 10 }}>
                <Box
                  component="img"
                  src={profileIcon}
                  alt="profile"
                  sx={{ height: 45, width: 45 }}
                />
                <Box
                  component="img"
                  src={profileIcon2}
                  alt="profile"
                  className="ml-3"
                />
              </IconButton>
              <Menu
                anchorEl={anchorElProfile}
                open={Boolean(anchorElProfile)}
                onClose={handleCloseProfileMenu}
                sx={{ zIndex: 11 }}
              >
                {isLoggedIn ? (
                  <MenuItem onClick={handleLogout}>Log Out</MenuItem>
                ) : (
                  <MenuItem
                    component={Link}
                    to="/login"
                    onClick={handleCloseProfileMenu}
                  >
                    Log In
                  </MenuItem>
                )}
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
