import React from "react";
import PlanBenefits from "./PlanBenefits";
import moment from "moment";

function SubscriptionsPlans({ user }) {
  if (!user || !user.onBoardingDetails) return null;

  const { onBoardingDetails, history } = user;

  // Count the occurrences of "Restaurant w/Alcohol" and "Restaurant w/o Alcohol"
  const countWithAlcohol = history?.filter((entry) =>
    entry.includes("Restaurant w/Alcohol")
  ).length;
  const countWithoutAlcohol = history?.filter((entry) =>
    entry.includes("Restaurant w/o Alcohol")
  ).length;

  const {
    withAlcoholNumber = 0,
    withoutAlcoholNumber = 0,
    items = [],
  } = onBoardingDetails;

  // Decrement the original numbers based on counts from history
  const adjustedWithAlcoholNumber =
    Number(withAlcoholNumber) - Number(countWithAlcohol);
  const adjustedWithoutAlcoholNumber =
    Number(withoutAlcoholNumber) - Number(countWithoutAlcohol);

  // Pricing logic for "with alcohol"
  let rateWithAlcohol = 0;
  if (adjustedWithAlcoholNumber === 1) {
    rateWithAlcohol = 1500;
  } else if (adjustedWithAlcoholNumber >= 2 && adjustedWithAlcoholNumber <= 5) {
    rateWithAlcohol = 1300;
  } else if (adjustedWithAlcoholNumber > 5) {
    rateWithAlcohol = 1200;
  }

  // Pricing logic for "without alcohol"
  let rateWithoutAlcohol = 0;
  if (adjustedWithoutAlcoholNumber === 1) {
    rateWithoutAlcohol = 1000;
  } else if (
    adjustedWithoutAlcoholNumber >= 2 &&
    adjustedWithoutAlcoholNumber <= 5
  ) {
    rateWithoutAlcohol = 900;
  } else if (adjustedWithoutAlcoholNumber > 5) {
    rateWithoutAlcohol = 800;
  }

  // Pricing logic for "items"
  let totalItems = 0;
  if (Array.isArray(items)) {
    if (
      user?.onBoardingDetails?.selectedChips?.length === 1 &&
      user?.onBoardingDetails?.selectedChips[0] === "Kids Play Area"
    ) {
      // Charge 1000 for each item when only "Kids Play Area" is selected, regardless of 'yes' or 'no'
      totalItems = items.reduce((acc, item) => {
        return acc + 1000 * item.amount;
      }, 0);
    } else {
      // Apply the existing pricing logic if multiple items or not "Kids Play Area"
      totalItems = items.reduce((acc, item) => {
        const rate = item.answer == "yes" ? 2000 : 1000; // Multi-case (yes) or single-case (no)
        return acc + rate * item.amount;
      }, 0);
    }
  }

  // Add logic for hotel + stays if applicable
  let hotelStaysTotal = 0;
  if (user.onBoardingDetails.type === "HotelStayandVenue") {
    hotelStaysTotal = 2000; // Fixed charge of 2000
  }

  // Calculate totals (ensure that undefined or null values are treated as 0)
  const totalWithAlcohol = rateWithAlcohol * adjustedWithAlcoholNumber || 0;
  const totalWithoutAlcohol =
    rateWithoutAlcohol * adjustedWithoutAlcoholNumber || 0;
  const subTotal =
    totalWithAlcohol + totalWithoutAlcohol + totalItems + hotelStaysTotal;
  const vat = subTotal * 0.05; // 5% VAT
  const grandTotalinitial = subTotal + vat;
  // Apply user's special discount if available
  const discountPercentage = user?.specialDiscount || 0;
  const discountAmount = (grandTotalinitial * discountPercentage) / 100;
  const grandTotal = grandTotalinitial - discountAmount;
  return (
    <div className="flex flex-col border p-5 rounded-lg shadow-md bg-white mt-3">
      <div className="flex flex-col md:flex-row justify-between">
        {/* Left Section: Plan Breakdown */}
        <div className="flex flex-col w-full md:w-1/2 md:pr-[60px] md:pl-[40px]  md:mt-[40px]">
          <div className="w-[138px] h-[30px] bg-[#4990FB] rounded-full text-[14px] flex items-center justify-center">
            <h3 className=" text-[12px] md:text-[14px] font-bold  rounded-full  text-white ">
              {user?.paymentStatus === "Cancelled" ? (
                <>Previous Plan</>
              ) : user?.isModified ? (
                <>Modified Plan</>
              ) : (
                <>Subscription Plans</>
              )}
            </h3>
          </div>
          <h2 className="text-[32px] font-bold mb-2 mt-[12px]">
            Venue Plan{" "}
            <span className="text-[16px] font-normal text-[#5E5E5E]">
              / Annum
            </span>
          </h2>
          {/* table */}
          <div className="flex justify-between items-center text-[12px] md:text-[16px] text-[#222222] font-medium">
            <p className="w-1/2">Plan Breakdown</p>
            <p className="w-1/6 text-center">Rate</p>
            <p className="w-1/6 text-center">Quantity</p>
            <p className="w-1/6 text-right">Total</p>
          </div>
          {/* Row for Restaurants with Alcohol */}
          <div className="flex justify-between items-center text-gray-800 mt-[20px]">
            <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/2">
              Restaurants w/ Alcohol
            </p>
            <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/6 text-center">
              ${rateWithAlcohol}
            </p>
            <div className="border border-[#FFEBEB] w-[45px] h-[27px] flex items-center justify-center rounded-md w-1/6 text-center">
              <p className="text-[#222222] font-normal text-[12px]">
                {adjustedWithAlcoholNumber}
              </p>
            </div>
            <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/6 text-right">
              ${totalWithAlcohol}
            </p>
          </div>
          {/* Row for Restaurants without Alcohol */}
          <div className="flex justify-between items-center text-gray-800 mt-[10px]">
            <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/2">
              Restaurants w/o alcohol
            </p>
            <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/6 text-center">
              ${rateWithoutAlcohol}
            </p>
            <div className="border border-[#FFEBEB] w-[45px] h-[27px] flex items-center justify-center rounded-md w-1/6 text-center">
              <p className="text-[#222222] font-normal text-[12px]">
                {adjustedWithoutAlcoholNumber}
              </p>
            </div>
            <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/6 text-right">
              ${totalWithoutAlcohol}
            </p>
          </div>

          {/* Row for Items */}
          {items &&
            items
              .filter((item) => {
                // Generate the name based on the item's answer
                const itemName =
                  item.answer === "yes" ? "Multi Venue" : "Unique Venue";

                // Check if any history item includes or matches the generated name
                return !user?.history?.some(
                  (historyItem) =>
                    historyItem.includes(itemName) ||
                    itemName.includes(historyItem)
                );
              })
              .map((item, index) => (
                <div
                  className="flex justify-between items-center text-gray-800 mt-[10px]"
                  key={index}
                >
                  <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/2">
                    {item.answer === "yes" ? "Multi Venue" : "Unique Venue"}
                  </p>
                  <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/6 text-center">
                    $
                    {user?.onBoardingDetails?.selectedChips?.length === 1 &&
                    user?.onBoardingDetails?.selectedChips[0] ===
                      "Kids Play Area"
                      ? 1000
                      : item.answer === "yes"
                      ? 2000
                      : 1000}
                  </p>
                  <div className="border border-[#FFEBEB] w-[45px] h-[27px] flex items-center justify-center rounded-md w-1/6 text-center">
                    <p className="text-[#222222] font-normal text-[12px]">
                      {item.amount}
                    </p>
                  </div>
                  <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/6 text-right">
                    $
                    {user?.onBoardingDetails?.selectedChips?.length === 1 &&
                    user?.onBoardingDetails?.selectedChips[0] ===
                      "Kids Play Area"
                      ? 1000 * item.amount
                      : (item.answer === "yes" ? 2000 : 1000) * item.amount}
                  </p>
                </div>
              ))}

          {/* Row for Hotel + Stays */}
          {user?.onBoardingDetails?.type === "HotelStayandVenue" && (
            <div className="flex justify-between items-center text-gray-800 mt-[10px]">
              <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/2">
                Hotel + Stays
              </p>
              <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/6 text-center">
                $2000
              </p>
              <div className="border border-[#FFEBEB] w-[45px] h-[27px] flex items-center justify-center rounded-md w-1/6 text-center">
                <p className="text-[#222222] font-normal text-[12px]">1</p>
              </div>
              <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/6 text-right">
                $2000
              </p>
            </div>
          )}

          {user?.onBoardingDetails?.type === "HotelStayandVenue" && (
            <div className="flex justify-between items-center text-gray-800 mt-[10px]">
              <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/2">
                Venues (Free)
              </p>
              <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/6 text-center">
                0
              </p>
              <div className="border border-[#FFEBEB] w-[45px] h-[27px] flex items-center justify-center rounded-md w-1/6 text-center">
                <p className="text-[#222222] font-normal text-[12px]">
                  {user?.onBoardingDetails?.VenuesCount}
                </p>
              </div>
              <p className="text-[#717171] font-normal text-[10px] md:text-[12px] w-1/6 text-right">
                $0
              </p>
            </div>
          )}

          {/* border */}

          {/* badge free trial */}
          <div className="mt-3">
            <img src="/freetrial.svg" alt="" draggable="false" />
          </div>

          <div className="flex justify-between mt-4 text-[12px] md:text-[14px] font-normal text-[#717171]">
            <p>Sub Total</p>
            <p>${subTotal}</p>
          </div>
          <div className="flex justify-between mt-4 text-[12px] md:text-[14px] font-normal text-[#717171]">
            <p>VAT service fee (5%)</p>
            <p>${vat}</p>
          </div>
          {user?.specialDiscount > 0 && (
            <div className="flex justify-between mt-4 text-[12px] md:text-[14px] font-normal text-[#717171]">
              <p>Discount</p>
              <p>{user?.specialDiscount}%</p>
            </div>
          )}
          {/* discountPercentage */}
          {/* border */}
          <div className="border border-t border-[#DDDDDD] mt-3"></div>

          <div className="flex justify-between text-black font-bold mt-4">
            <p className="text-[#5E5E5E] font-normal">Grand Total</p>
            <p>
              ${Math.round(grandTotal)}{" "}
              <span className="text-[#B0B0B0] font-normal">/year</span>
            </p>
          </div>
          <div className="flex flex-col md:flex-row md:justify-between items-end md:items-start">
            <p className="text-xs text-gray-600 mt-3 w-[70%]">
              Enjoy a zero-dollar payment today till{" "}
              {moment(user?.trialEnd).format("MMMM D, YYYY")} , with your{" "}
              <br className="hidden md:inline" /> subscription plan starting
              after six months, <br className="hidden md:inline" /> when
              you&apos;ll be charged.
            </p>
            <p className="text-[16px] text-black font-medium mt-2">
              Pay now: $0
            </p>
          </div>
        </div>

        {/* Right Section: Plan Benefits */}
        <PlanBenefits />
      </div>
    </div>
  );
}

export default SubscriptionsPlans;
