import { useRef, useEffect } from "react";
import { useDeleteTeamMember } from "../../queries/team-members";

const DeleteUserModal = ({ showDeleteModal, userId, setSnackbar }) => {
  const modalRef = useRef();
  const deleteMutation = useDeleteTeamMember(userId);

  const onClose = () => {
    showDeleteModal(false);
  };

  const handleDelete = async () => {
    await deleteMutation.mutateAsync();
    if (deleteMutation.isSuccess) {
      setSnackbar({
        open: true,
        message: "User deleted successfully!",
        severity: "success",
      });
    }
    onClose();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="fixed z-[999] inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div
        className="bg-white p-6 rounded-lg shadow-lg md:w-1/3 w-[90%] flex flex-wrap justify-between"
        ref={modalRef}
      >
        <div className="w-full flex justify-end">
          <p className="cursor-pointer" onClick={() => onClose()}>
            X
          </p>
        </div>
        <h2 className="text-2xl w-full font-semibold mb-4 text-center">
          Delete User?
        </h2>

        <p className="text-center w-full">
          Are you sure you want to delete user?
        </p>

        <div className="flex flex-col items-center w-full gap-3 mt-3">
          <button
            className="bg-primary w-[50%] text-white px-4 py-2 rounded-md"
            onClick={handleDelete}
          >
            {deleteMutation.isPending ? "Deleting..." : "Delete"}
          </button>
          <p
            className="text-primary font-semibold cursor-pointer"
            onClick={() => onClose()}
          >
            Cancel
          </p>
        </div>
      </div>
    </div>
  );
};

export default DeleteUserModal;
