import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import CancellationDoneModal from "./CancellationDoneModal";
import ModifyModal from "./ModifyModal";
import moment from "moment";
import axios from "axios";
import { base_URL } from "../../../config/config";
import { toast } from "react-toastify";
import { getOnboarding } from "../../../api/signup.request";
import { useQuery } from "@tanstack/react-query";
function CurrentSubscription({
  setEditBilling,
  user,
  refetchUser,
  grandTotal,
  setShowVenues,
}) {
  const checkAdmin = localStorage.getItem("checkAdmin");
  // if it is super admin than  localStorage.getItem("vendorId"); will work
  // but if it is admin that was created by invitation than  localStorage.getItem("invitedBy"); will work
  // than the id will be used of that super admin to fetch venues (For admin show all venues of super admin)
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");
  const limiteduser = localStorage.getItem("vendorId");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModifyModalOpen, setIsModifyModalOpen] = useState(false);
  const [dueAmount, setDueAmount] = useState(0);
  const [proratedAmount, setProratedAmount] = useState(0);
  const [isCancellationDoneModalOpen, setIsCancellationDoneModalOpen] =
    useState(false);

  const {
    data: boardingData,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["onboardingData"],
    queryFn: async () => await getOnboarding(vendorId),
    refetchOnWindowFocus: false,
  });
  const openModal = () => setIsModalOpen(true);
  const openModifyModal = () => {
    setShowVenues(true);
  };
  const closeModal = () => setIsModalOpen(false);

  const openCancellationDoneModal = () => {
    const selectedReason = localStorage.getItem("selectedReason");
    const enterReason = localStorage.getItem("enterReason");

    axios
      .post(`${base_URL}/api/vendor/cancel-subs`, {
        selectedReason: selectedReason,
        enterReason: enterReason,
        email: user?.email,
      })
      .then((response) => {
        setIsCancellationDoneModalOpen(true); // Open the cancellation done modal
        refetchUser();
      })
      .catch((error) => {
        console.error("Error during cancellation", error);
        toast.error("No Subscription found");
      });

    localStorage.removeItem("selectedReason");
    localStorage.removeItem("enterReason");
    closeModal(); // Close the cancellation modal
  };

  const closeCancellationDoneModal = () => {
    setIsCancellationDoneModalOpen(false);
    refetch();
  };

  const closeModifyModal = () => {
    setIsModifyModalOpen(false);
  };
  //   due amount and pro amount
  useEffect(() => {
    if (user) {
      const getAmounts = async () => {
        try {
          const response = await axios.post(`${base_URL}/get-stripe-details`, {
            email: user?.email,
          });
          const { dueAmount, proratedAmount } = response.data;

          // Inverse conversion rate from AED to USD
          const AED_TO_USD_RATE = 1 / 3.68;
          // Convert AED to USD
          const dueAmountUSD = (dueAmount * AED_TO_USD_RATE).toFixed(2);
          // setDueAmountUSD(dueAmountUSD);
          setDueAmount(dueAmountUSD);

          setProratedAmount(proratedAmount);
        } catch (error) {
          console.error("Error fetching amounts: ", error);
        }
      };

      // Call the function to fetch the amounts
      getAmounts();
    }
  }, [user?.email]);
  return (
    <div className="flex flex-col border p-5 rounded-lg shadow-md bg-white">
      <ModifyModal
        isOpen={isModifyModalOpen}
        onClose={closeModifyModal}
        heading="Add Subscription"
        user={user}
        refetchOnboardingData={refetch}
        date={boardingData?.trialEnd}
      />
      <div className="flex justify-between items-center mb-1">
        <p className="text-[#222222] font-bold text-[16px] lg:text-[24px]">
          Venue Plan
        </p>
        <div className="cursor-pointer active:opacity-55 flex items-center justify-center bg-[#D03660] rounded-full h-[30px] w-[84px]">
          {user?.paymentStatus == "Active" && (
            <span className="text-[14px]  text-white  font-semibold">
              ACTIVE
            </span>
          )}
          {user?.paymentStatus == "Cancelled" && (
            <span className="text-[14px]  text-white  font-semibold">
              Cancelled
            </span>
          )}
        </div>
      </div>
      <p className="text-[#5E5E5E] mb-3 text-[12px] sm:text-[16px]">
        {user?.paymentStatus == "Cancelled" &&
        user?.trialStatus == "finished" ? (
          <>
            You have access to your account until{" "}
            {moment(user?.trialEnd).format("MMMM D, YYYY")}
          </>
        ) : user?.paymentStatus == "Cancelled" &&
          user?.trialStatus == "remaining" ? (
          <>
            {" "}
            You have access to your account until{" "}
            {moment(user?.trialEnd).format("MMMM D, YYYY")}
          </>
        ) : (
          <>
            {/* Next charge is ${Math.round(grandTotal)} on{" "} */}
            Next charge is ${dueAmount} on{" "}
            {moment(user?.trialEnd).format("MMMM D, YYYY")}
            {/* {dueAmount > 0 && "(including due amount)"}
            {proratedAmount > 0 && "(After managing proration)"}
            {proratedAmount > 0 &&
              dueAmount > 0 &&
              "(After managing proration & due amount)"} */}
          </>
        )}
      </p>

      {/* border */}
      <div className="border border-t border-[#D3D3D3]"></div>

      {/* options */}
      <div className="flex flex-col gap-y-4 gap-2 mt-4">
        {/* edit billing information */}
        <div className="flex justify-between items-center">
          <div className="flex gap-x-2 items-center justify-center">
            <img src="/credit_card.svg" alt="" />
            <button
              onClick={() => setEditBilling(true)}
              className="text-[#212B36] text-[14px] sm:text-[20px]"
            >
              Edit Billing Information
            </button>
          </div>
          <div className="flex items-center justify-center cursor-pointer">
            <img
              src="/subright.svg"
              alt=""
              onClick={() => setEditBilling(true)}
            />
          </div>
        </div>
        {/* edit modify plan */}
        <div
          className="flex justify-between items-center cursor-pointer"
          onClick={openModifyModal}
        >
          <div className="flex gap-x-2 items-center justify-center">
            <img src="/credit_card.svg" alt="" />
            <button
              onClick={openModifyModal}
              className="text-[#212B36] text-[14px] sm:text-[20px]"
            >
              Modify Plan
            </button>
          </div>
          <div className="flex items-center justify-center cursor-pointer">
            <img src="/subright.svg" alt="" />
          </div>
        </div>
        {/* Cancel Section */}
        <div className="flex justify-between items-center">
          <div className="flex gap-x-2 items-center justify-center">
            <img src="/cancel_credit.svg" alt="Cancel Subscription Icon" />

            {user?.paymentStatus == "Cancelled" ? (
              <div className="flex flex-col">
                <div>
                  <Link to="/onboarding">
                    <button className="text-[#212B36] text-[14px] sm:text-[20px]">
                      Add Subscription
                    </button>
                  </Link>
                </div>
                <div className="text-[#5E5E5E] text-[12px] sm:text-[16px]">
                  {user?.trialStatus == "finished" ? (
                    <p>
                      You have access to your account until{" "}
                      {moment(user?.trialEnd).format("MMMM D, YYYY")}.
                    </p>
                  ) : (
                    <p>
                      {" "}
                      You have access to your account until{" "}
                      {moment(user?.trialEnd).format("MMMM D, YYYY")}.
                    </p>
                  )}
                </div>
              </div>
            ) : (
              <div className="flex flex-col">
                <div>
                  <button
                    onClick={openModal}
                    className="text-[#212B36] text-[14px] sm:text-[20px]"
                  >
                    Cancel Subscription
                  </button>
                </div>
                <div className="text-[#5E5E5E] text-[12px] sm:text-[16px]">
                  {user?.trialStatus == "remaining" ? (
                    <p>
                      Your subscription will be cancelled immediately as you are
                      in the trial period.
                    </p>
                  ) : (
                    <p>
                      Ends on {moment(user?.trialEnd).format("MMMM D, YYYY")}{" "}
                      when cancelled.
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
          {user?.paymentStatus == "Cancelled" ? (
            <>
              <Link to="/onboarding">
                <div className="flex items-center justify-center cursor-pointer">
                  <img
                    src="/subright.svg"
                    alt="Next Subscription Step"
                    className="active:opacity-55"
                  />
                </div>
              </Link>
            </>
          ) : (
            <>
              <div
                onClick={openModal}
                className="flex items-center justify-center cursor-pointer"
              >
                <img
                  src="/subright.svg"
                  alt="Next Subscription Step"
                  className="active:opacity-55"
                />
              </div>
            </>
          )}
        </div>
      </div>

      <CancelSubscriptionModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onContinue={openCancellationDoneModal}
      />
      <CancellationDoneModal
        isOpen={isCancellationDoneModalOpen}
        onClose={closeCancellationDoneModal}
        user={user}
        refetchOnboardingData={refetch}
      />
    </div>
  );
}

export default CurrentSubscription;
