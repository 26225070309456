import React, { useContext, useState } from "react";
import { HotelContext } from "../../../context/HotelContext";
import Modal from "../../shared/custom-modal/custom-modal";
import OfferModal from "./offerModal";
import moment from "moment/moment";

const OfferForm = () => {
  const { formData, setFormData } = useContext(HotelContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [offers, setOffers] = useState(formData?.offerPackage || []);
  const [offerToEdit, setOfferToEdit] = useState(null);


  const addOffer = (newOffer) => {
    const updatedOffers = offerToEdit
      ? offers.map((offer) =>
        offer.title === offerToEdit.title ? newOffer : offer
      )
      : [...offers, newOffer];

    setOffers(updatedOffers);
    setFormData({ ...formData, offerPackage: updatedOffers });
    setIsModalOpen(false);
    setOfferToEdit(null);
  };

  const handleEdit = (offer) => {
    setOfferToEdit(offer);
    setIsModalOpen(true);
  };

  const handleDelete = (index) => {
    const updatedOffers = offers.filter((_, i) => i !== index);
    setOffers(updatedOffers);
    setFormData({ ...formData, offerPackage: updatedOffers });
  };

  // const getCurrentDate = () => {
  //   const today = new Date();
  //   const year = today.getFullYear();
  //   const month = (today.getMonth() + 1).toString().padStart(2, "0");
  //   const day = today.getDate().toString().padStart(2, "0");
  //   return `${year}-${month}-${day}`;
  // };

  // const filteredOffers = offers?.filter((offer) => {
  //   const currentDate = getCurrentDate(); // Get the current date dynamically

  //   // If there is an endDate, compare it to the current date
  //   if (offer?.endDate) {
  //     // Ensure endDate is in the same format as currentDate
  //     const endDate = offer.endDate;
  //     return endDate >= currentDate; // Include offers where endDate is today or in the future
  //   }

  //   // Include offers without an endDate
  //   return true;
  // });

  return (
    <div className="bg-white p-6 rounded-lg">
      <h2 className="text-2xl font-semibold text-neutralBlack mb-4">
        Offers & Packages
      </h2>
      <div className="w-full flex items-center justify-between">
        <p className="text-darkGray text-sm mb-2">Offers & Packages</p>
        {offers?.length > 0 ? (
          <button
            className="text-primary px-4 py-2 rounded-md underline font-medium transition"
            onClick={() => {
              setOfferToEdit(null);
              setIsModalOpen(true);
            }}
          >
            Add More Offers
          </button>
        ) : null}
      </div>
      {offers?.length > 0 ? null : (
        <div className="flex justify-between items-center mb-4 w-full border border-dashed border-offGray py-2 px-4 bg-[#F7F7F7] rounded-lg">
          <p className="text-secondary text-sm">
            {offers?.length === 0 ? "No Offer added" : "Offers added"}
          </p>
          <button
            className="text-primary px-4 py-2 rounded-md underline font-medium transition"
            onClick={() => {
              setOfferToEdit(null);
              setIsModalOpen(true);
            }}
          >
            Add Offer
          </button>
        </div>
      )}
      <div>
        {offers?.length > 0 ? (

          <ul className="">
            {offers?.map((offer, index) => (
              <li
                key={index}
                className="py-4 px-4 relative bg-[#E4EFFF] border border-dashed border-[#4990FB] mb-4 rounded-lg"
              >
                <div className="flex justify-between sm:items-center mb-2">
                  <div className="">
                    <p className="text-secondary text-sm">Offer Title</p>
                    <h3 className="text-sm text-[#1252B1]">{offer.title}</h3>
                  </div>
                  <div className="grid sm:grid-cols-2 grid-cols-1 w-1/2 sm:gap-8 gap-4 mb-2">
                    {offer?.startDate && (
                      <div>
                        <p className="text-secondary text-sm">From</p>
                        <p className="text-sm text-[#1252B1]">
                          {moment(offer.startDate).format("DD MMM YYYY")}
                        </p>
                      </div>
                    )}
                    {offer?.endDate && (
                      <div>
                        <p className="text-secondary text-sm">To</p>
                        <p className="text-sm text-[#1252B1]">
                          {moment(offer.endDate).format("DD MMM YYYY")}
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex justify-between gap-2 items-center mb-5">
                  <div className="">
                    <p className="text-secondary text-sm">Description</p>

                    <p
                      className="text-sm text-[#1252B1] text-wrap break-words"
                      style={{ wordBreak: "break-all" }}
                    >
                      {offer.description}
                    </p>
                  </div>
                </div>
                <div className="flex absolute bottom-2 right-2">
                  <button
                    className="text-sm text-[#1252B1] hover:text-red-600"
                    onClick={() => handleEdit(offer)}
                  >
                    <img src="/edit.svg" alt='edit' />
                  </button>
                  <button
                    className="text-red-500 hover:text-red-600"
                    onClick={() => handleDelete(index)}
                  >
                    <img src="/trash.svg" alt='trash' />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : null}
      </div>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <OfferModal
          onClose={() => setIsModalOpen(false)}
          onSave={addOffer}
          offerToEdit={offerToEdit}
        />
      </Modal>
    </div>
  );
};

export default OfferForm;
