import React, { useEffect, useState } from "react";
import { Container, Box, Button } from "@mui/material";
import ResponsiveAppBar from "../../components/ResponsiveAppBar";
import LinearProgressBar from "./components/LinearProgressBar";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import backgroundIcon from "../../assets/images/background_icon.svg";
import { useMediaQuery } from "@mui/material";
import MultipleFlow from "./flows/MultipleFlow";
import TypeOfVenue from "./components/TypeOfVenue";
import GradientBlob from "../../components/GradientBlob";
import HotelFlow from "./flows/HotelFlow";
import VenueFlow from "./flows/VenueFlow";
import maxStepsList from "../../constants/maxStepsList";
import { useUser } from "../../context/user";

function Onboarding() {
  //   States for Rendering different Views for Multiple Flow
  const {
    setRestaurantsView,
    setRestaurantVenueView,
    setRestaurantotherView,
    setVenueView,
    setKidsVenueView,
    setItems,
    setWithAlcoholNumber,
    setWithoutAlcoholNumber,
    setHotelGroupName,
    setEntityName,
  } = useUser();
  const [activeStep, setActiveStep] = useState(0);
  const [maxStep, setMaxStep] = useState(maxStepsList.Hotel);
  const [showBackButton, setShowBackButton] = useState(true);
  const [selectedButton, setSelectedButton] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [isBackButtonClicked, setIsBackButtonClicked] = useState(false);

  // For Flows
  const [displayHotelFlow, setDisplayHotelFlow] = useState(false);
  const [displayMultipleFlow, setDisplayMultipleFlow] = useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setIsBackButtonClicked(true);
    console.log(activeStep, "show2");

    if (activeStep === 3) {
      setActiveStep(1);
    } else if (activeStep === 5) {
      setActiveStep(3);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  useEffect(() => {
    if (activeStep === 0) {
      setDisplayHotelFlow(false);
      setDisplayMultipleFlow(false);
    }
    if (activeStep === 1) {
      // remove amounts or any promo code if applied if back
      localStorage.removeItem("restaurantview");
      localStorage.removeItem("specialDiscount");
      localStorage.removeItem("kidsview");
      localStorage.removeItem("restaurantandvenuecombined");
      localStorage.removeItem("others");
      setRestaurantVenueView(false);
      setRestaurantsView(false);
      setRestaurantotherView(false);
      setVenueView(false);
      setKidsVenueView(false);
      // clearing venues yes/no logic so that it not appears on checkout
      setItems([]);
      // clearing alcoholic and non alcoholic numbers
      setWithoutAlcoholNumber(0);
      setWithAlcoholNumber(0);
      // clearing group name field
      setHotelGroupName("");
      // clearing "Name of Entity/Establishment/Hotel" during onbaording
      setEntityName("");
    }
  }, [activeStep]);

  const handleButton1Click = () => {
    setSelectedButton(1);
    setDisplayHotelFlow(true);
    setMaxStep(maxStepsList.Hotel);
    handleNext();
  };

  const setMaxStepsCallback = (steps) => {
    setMaxStep(steps);
  };

  const handleButton2Click = () => {
    setSelectedButton(2);
    setDisplayMultipleFlow(true);
    setMaxStepsCallback(maxStepsList.Multiple);
    handleContinue();
  };

  const handleContinue = () => {
    handleNext();
  };

  const toggleBackButton = (show) => {
    console.log(show, "show");

    setShowBackButton(show);
  };

  const shouldCenterContent = activeStep === 0 || activeStep === 1;

  return (
    <>
      <ResponsiveAppBar />
      <LinearProgressBar value={((activeStep + 4) / (maxStep + 4)) * 100} />
      <div
        style={{
          backgroundImage: `url(${backgroundIcon})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
          marginTop: "3rem",
          marginBottom: "3rem",
          minHeight: "70vh",
          display: shouldCenterContent ? "flex" : "block",
          justifyContent: shouldCenterContent === 0 ? "center" : "initial",
          alignItems: shouldCenterContent === 0 ? "center" : "initial",
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: { xs: "100%", md: "100%" },
          }}
        >
          {activeStep === 0 && (
            <Box sx={{ width: { xs: "100%", md: "35%" } }}>
              <TypeOfVenue
                title={"Type of Venue"}
                description={
                  "Select the type of venue you want to list. This will help us find a suitable plan for your needs."
                }
                buttonLabel1={"Hotel stay + Venues"}
                buttonLabel2={"Venue"}
                tooltip1={
                  "An establishment that offers lodging for group reservations, along with providing venue spaces such as ballrooms for guests to host events, and may also include an onsite restaurant managed by the hotel."
                }
                tooltip2={
                  "A venue includes single or multiple event spaces within the same locations"
                }
                handleButton1Click={handleButton1Click}
                handleButton2Click={handleButton2Click}
                selectedButton={selectedButton}
              />
            </Box>
          )}

          {displayHotelFlow && (
            <HotelFlow
              activeStep={activeStep}
              handleContinue={handleContinue}
              toggleBackButton={toggleBackButton}
              isBackButtonClicked={isBackButtonClicked}
              resetBackButtonClicked={() => setIsBackButtonClicked(false)}
            />
          )}
          {displayMultipleFlow && (
            <MultipleFlow
              activeStep={activeStep + 1}
              handleContinue={handleContinue}
              toggleBackButton={toggleBackButton}
              isBackButtonClicked={isBackButtonClicked}
              resetBackButtonClicked={() => setIsBackButtonClicked(false)}
              handleBack={handleBack}
            />
          )}
        </Container>
        {showBackButton && activeStep !== 0 && (
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              top: { xs: "6rem", md: "10rem" },
              left: { xs: "2rem", md: "8rem" },
            }}
          >
            <Button
              variant="text"
              startIcon={<KeyboardBackspaceIcon />}
              sx={{ color: "black.text" }}
              onClick={handleBack}
              className="relative bottom-[25px] text-[16px] z-[2]"
            >
              Back
            </Button>
          </Box>
        )}
        {!isMobile && (
          <>
            <GradientBlob positionHorizontal="left" positionVertical="top" />
            <GradientBlob positionHorizontal="left" positionVertical="bottom" />
            <GradientBlob
              positionHorizontal="right"
              positionVertical="bottom"
            />
          </>
        )}
      </div>
    </>
  );
}

export default Onboarding;
