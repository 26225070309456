import React, { useState, useEffect } from "react";
import { Card, Box, Typography, Button, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { MdScatterPlot } from "react-icons/md";

const MessageCard = ({
  loaderComponent,
  primaryText,
  secondaryText,
  buttonText,
  buttonHref,
  richText,
  richLinkText,
  richLinkHref,
  onRichLinkClick,
  processingTime,
}) => {
  const theme = useTheme();
  const [countdown, setCountdown] = useState(5); // Initial countdown set to 5 seconds

  useEffect(() => {
    if (primaryText === "Password Updated") {
      if (countdown > 0) {
        const timer = setTimeout(() => setCountdown(countdown - 1), 1000); // Decrement countdown every second
        return () => clearTimeout(timer); // Clean up the timer on unmount
      } else {
        // When countdown finishes, navigate to the href
        window.location.href = buttonHref; // Or use navigate(buttonHref) if you're using react-router
      }
    }
  }, [countdown, buttonHref, primaryText]);

  return (
    <Card
      sx={{
        width: { xs: "90%", md: "630px" },
        backgroundColor: theme.palette.common.white,
        borderRadius: "8px",
        p: 2,
        px: { xs: 2, md: 8 },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        mt: { xs: 10, md: 4 },
      }}
    >
      <Box>{loaderComponent}</Box>
      <Box
        sx={{
          width: { xs: "90%", md: "100%" },
          p: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {primaryText && (
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              fontWeight: "600",
              fontSize: { xs: "1.5rem", md: "1.6rem" },
            }}
          >
            {primaryText}
          </Typography>
        )}
        {secondaryText && (
          <Typography
            variant="body1"
            className="  "
            sx={{
              fontWeight: "400",
              color: theme.palette.grey.text,
              fontSize: "1.1rem",
              textAlign: "center",
              mb: 3,
              mt: 1,
            }}
          >
            {secondaryText}
          </Typography>
        )}
        {buttonText && buttonHref && (
          <Link sx={{ width: "90%", textDecoration: "none" }} href={buttonHref}>
            <Button
              variant="contained"
              size="large"
              sx={{
                width: "100%",
                borderRadius: "10px",
                height: "40px",
                textTransform: "none",
              }}
            >
              {`${buttonText} ${
                primaryText === "Password Updated" ? `(${countdown}s)` : ""
              }`}
            </Button>
          </Link>
        )}
        {processingTime && (
          <button className="h-10 text-base rounded-md">
            <MdScatterPlot className="animate-spin" />
          </button>
        )}
        {richText && !processingTime && (
          <Typography
            variant="body2"
            className="tracking-[1px] mx-auto"
            sx={{
              width: "100%",
              fontSize: "14px",
              color: theme.palette.grey.text,
              textAlign: "center", // Center text
              display: "inline", // Flex display
              // justifyContent: "center", // Center content within flex container
            }}
          >
            {richText}{" "}
            <Link
              href={richLinkHref}
              color="primary"
              className="cursor-pointer"
              sx={{ textDecoration: "none" }}
              onClick={(event) => {
                event.preventDefault(); // Prevent default link behavior
                if (onRichLinkClick) onRichLinkClick(); // Call the provided function
              }}
            >
              {richLinkText}
            </Link>
          </Typography>
        )}
      </Box>
    </Card>
  );
};

export default MessageCard;
