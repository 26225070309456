import React from "react";
import { IoIosArrowDown } from "react-icons/io";

import { IoIosCheckmarkCircle } from "react-icons/io";
import shadeRight from "../../assets/pics/shadeRight.svg";
import shadeLeft from "../../assets/pics/shadeLeft.svg";

import img1 from "../../assets/img/Auditorium.png";
import img2 from "../../assets/img/Ballroom.jpeg";
import img3 from "../../assets/img/Beach.jpg";
import img4 from "../../assets/img/Kids Play Area.jpeg";
import img5 from "../../assets/img/Museum.jpg";
import img6 from "../../assets/img/Restaurant.png";
import img7 from "../../assets/img/Yacht.jpg";

const Hero = () => {
  const names = [
    "Auditorium",
    "Ballroom",
    "Beach",
    "Kids Play Area",
    "Museum",
    "Restaurant",
    "Yacht",
  ];
  const images = [
    {
      name: "Auditorium",
      img: "https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730979090/WhatsApp_Image_2024-11-07_at_4.58.04_PM_1_uxxesc.jpg",
    },
    {
      name: "Ballroom",
      img: "https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730978955/WhatsApp_Image_2024-11-07_at_4.58.04_PM_bgekuh.jpg",
    },
    {
      name: "Beach",
      img: "https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730979091/WhatsApp_Image_2024-11-07_at_4.58.04_PM_2_lcdmmf.jpg",
    },
    {
      name: "Kids Play Area",
      img: "https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730979988/WhatsApp_Image_2024-11-07_at_5.15.52_PM_d4lplx.jpg",
    },
    {
      name: "Museum",
      img: "https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730979089/WhatsApp_Image_2024-11-07_at_4.59.51_PM_yoqh7g.jpg",
    },
    {
      name: "Restaurant",
      img: "https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730979651/Restaurant-min_rj6fvl.png",
    },
    {
      name: "Yacht",
      img: "https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730979360/WhatsApp_Image_2024-11-07_at_5.05.38_PM_unhkbl.jpg",
    },
  ];

  return (
    <div className="relative mx-auto lg:h-[100vh] lg:max-h-[750px] h-auto">
      <div className="flex justify-center w-full sm:pt-20 pt-12 mx-auto ">
        <div className="w-[90%]  flex items-center  gap-10 justify-between mt-5">
          <div className="lg:w-[60%] w-full flex flex-col sm:items-start items-center sm:gap-10 gap-4">
            <h1 className="xl:text-[65px] sm:text-5xl text-3xl sm:text-start text-center font-bold leading-none">
              Search & Compare
              <span className="text-[#FE4747]"> Venues Across UAE.</span>
            </h1>
            <p className="sm:text-lg text-sm sm:text-start text-center">
              The UAE&apos;s #1 venue search platform for discovering venues for
              meetings, corporates, parties, wedding & beyond.{" "}
            </p>
            <div className="bg-white py-3 px-5 flex md:gap-0 gap-4 justify-between md:flex-row w-full flex-col h-full rounded-lg shadow-lg">
              <div className="xl:w-[84%] w-full md:gap-0 gap-4 grid md:grid-cols-3 grid-cols-1">
                <div className="flex md:w-auto w-full">
                  <div className="md:w-auto w-full">
                    <h1 className="text-lg ">Event/Venue</h1>
                    <div className="flex items-center gap-2 w-full">
                      <input
                        type="text"
                        className="w-[90%]"
                        placeholder="Start typing..."
                        name=""
                        id=""
                      />
                      <IoIosArrowDown className="text-[#FE4747] w-[10%] text-xl" />
                    </div>
                  </div>
                  <div className="w-[2px] md:block hidden bg-[#CECECE] mx-8"></div>
                </div>

                <div className="flex md:w-auto w-full">
                  <div className="md:w-auto w-full">
                    <h1 className="text-lg ">No. of guests</h1>
                    <div className="flex items-center gap-2 w-full">
                      <input
                        type="text"
                        className="w-[90%]"
                        placeholder="Enter no. of guests"
                        name=""
                        id=""
                      />
                      <IoIosArrowDown className="text-[#FE4747] w-[10%] text-xl" />
                    </div>
                  </div>
                  <div className="w-[2px] md:block hidden bg-[#CECECE] mx-8"></div>
                </div>

                <div className="flex md:w-auto w-full">
                  <div className="md:w-auto w-full">
                    <h1 className="text-lg ">City</h1>

                    <div className="flex items-center gap-2 w-full">
                      <input
                        type="text"
                        className="w-[90%]"
                        placeholder="Enter Location"
                        name=""
                        id=""
                      />
                      <IoIosArrowDown className="text-[#FE4747] w-[10%] text-xl" />
                    </div>
                  </div>
                  <div className="w-[2px] md:block hidden  mx-8"></div>
                </div>
              </div>

              <div className="flex h-full  md:w-[16%] w-full justify-center items-center">
                <button className="bg-[#FE4747] md:w-auto w-full rounded-lg text-white py-2 px-4">
                  Search
                </button>
              </div>
            </div>
            <p className="underline text-[#FE4747]">
              Search options for Stay+Venue
            </p>
          </div>

          <div className="w-[30%] lg:flex hidden  justify-center">
            {/* <img src={img} alt='' /> */}
            <div className="relative w-full  h-auto min-h-[400px] mb-0 justify-center gap-5 flex flex-col items-center">
              {/* Hidden SVG mask definition */}
              <div className=" w-[354px] h-full flex -mt-8 mr-7 justify-center items-center relative">
                <svg className="absolute left-1/2 -translate-x-1/2 top-0 h-full flex justify-center">
                  <defs>
                    <mask
                      id="mask0_3556_4522"
                      maskUnits="userSpaceOnUse"
                      x="39"
                      y="0"
                      width="314"
                      height="392"
                    >
                      <path
                        d="M195.953 196C206.733 196 215.962 192.162 223.638 184.485C231.315 176.808 235.153 167.58 235.153 156.8C235.153 146.02 231.315 136.792 223.638 129.115C215.962 121.438 206.733 117.6 195.953 117.6C185.173 117.6 175.945 121.438 168.268 129.115C160.592 136.792 156.753 146.02 156.753 156.8C156.753 167.58 160.592 176.808 168.268 184.485C175.945 192.162 185.173 196 195.953 196ZM195.953 392C143.36 347.247 104.078 305.678 78.1083 267.295C52.1383 228.912 39.1533 193.387 39.1533 160.72C39.1533 111.72 54.915 72.6833 86.4383 43.61C117.962 14.5367 154.467 0 195.953 0C237.44 0 273.945 14.5367 305.468 43.61C336.992 72.6833 352.753 111.72 352.753 160.72C352.753 193.387 339.768 228.912 313.798 267.295C287.828 305.678 248.547 347.247 195.953 392Z"
                        fill="white"
                      />
                    </mask>
                  </defs>
                </svg>

                {/* Images masked with the defined SVG */}
                <div className="image-container w-full h-full flex justify-center relative">
                  {images?.map((e, index) => (
                    <img
                      key={index}
                      src={e?.img}
                      alt={`Masked ${index + 1}`}
                      className="w-full h-full object-cover absolute left-0 top-0"
                      style={{
                        mask: "url(#mask0_3556_4522)",
                        WebkitMask: "url(#mask0_3556_4522)",
                        animationDelay: `${index * 2}s`, // Staggered delay for each image
                      }}
                    />
                  ))}
                </div>
              </div>
              {/* Text container for image names */}
              <div className="text-container w-full h-auto relative flex justify-center items-center">
                {names.map((e, index) => (
                  <div
                    key={index}
                    className="lg:text-[40px] sm:text-3xl text-2xl text-[#FE4747] font-semibold tracking-wide	"
                  >
                    {e}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#FFF5F5] flex justify-center py-8 px-2 w-full mt-[100px]">
        <div className="sm:flex hidden items-center gap-2">
          <IoIosCheckmarkCircle className="text-[#FE4747] text-2xl" />
          <h1 className="text-[#222222] text-lg font-semibold">
            No Booking fees, No commission - Just Connections.
          </h1>
        </div>
        <div className="sm:hidden flex items-center justify-between gap-5">
          <div className="flex items-center gap-2">
            <IoIosCheckmarkCircle className="text-[#FE4747] text-xl" />
            <h1 className="text-[#222222] text-xs font-semibold">
              No Booking fees
            </h1>
          </div>
          <div className="w-[2px] h-full bg-[#FE4747]"></div>
          <div className="flex items-center gap-2">
            <IoIosCheckmarkCircle className="text-[#FE4747] text-xl" />
            <h1 className="text-[#222222] text-xs font-semibold">
              No commission - Just Connections.
            </h1>
          </div>
        </div>
      </div>
      <img src={shadeRight} className="absolute -top-[20%] right-0" alt="" />
      <img src={shadeLeft} className="absolute -top-[20%] left-0" alt="" />
    </div>
  );
};

export default Hero;
