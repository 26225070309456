import React, { useContext, useState, useEffect } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { base_URL } from "../../config/config";
import { VenueContext } from "../../context/VenueContext";
import Cookies from "js-cookie";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Add carousel styles
import { MdClose, MdFullscreen } from "react-icons/md";
import Gallery from "../hostel-preview/gallery";

const HeroSection = ({ data = [], data2, videos, id }) => {
  const { formData, resetForm, setCurrentStep } = useContext(VenueContext);
  const path = useLocation();

  const handleBackToPreview = () => {
    setCurrentStep(10); // Navigate to step 10 (Preview)
    // window.history.back()
    // if (path.pathname?.includes("restaurant-preview")) {
    //   navigate(`/venu-restaurant-Form/Restaurant/${id}`);
    // } else {
    //   window.history.back();
    // }
    if (path.pathname?.includes("restaurant-preview")) {
      navigate(`/venu-restaurant-Form/Restaurant/${id}`); // Programmatically navigate to the Preview page
    } else {
      navigate(`/create-Listing/Venue/${id}`);
    }
  };

  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal

  const vendorId = localStorage.getItem("vendorId");
  useEffect(() => {
    if (!vendorId) {
      alert("Vendor Id is not available");
      // window.location.href = '/hotel-listing';
    }
  }, []);
  const errors = localStorage.getItem("Errors");
  // Handle form submission
  const handleSubmit = async () => {
    if (formData?.imagesAndVideos?.images?.length === 0) {
      toast.error("Images are missing");
      return;
    }
    if (formData?.location?.addressLine1 === "") {
      toast.error("Data is missing in location");
      return;
    }
    if (errors) {
      toast.error(errors.trim());
    } else {
      const updatedItemStatus =
        formData?.itemStatus === "Approved"
          ? "Edit Pending"
          : formData?.itemStatus === "Rejected"
          ? "ReSubmitting"
          : "Pending";

      // Manually construct the updated form data
      const updatedFormData = {
        ...formData,
        itemStatus: updatedItemStatus,
        vendorId: vendorId,
        status: "completed",
        venueName: formData?.basicDetails?.venueName,
      };

      // Log the updated form data for verification

      if (path.pathname?.includes("venue-preview")) {
        const response = await axios.post(
          `${base_URL}/api/venueform/updateVenueForm`,
          updatedFormData
        );

        if (response.data.success) {
          // Assuming `success` indicates a successful registration
          toast.success("Venue created successfully!");
          localStorage.removeItem("Errors");
          Cookies.remove("uploadedFiles");
          resetForm();
          setTimeout(() => {
            navigate("/dashboard?venueRegister=true"); // Redirect to the dashboard
          }, 1000);
        } else {
          // Handle unsuccessful venue creation
          toast.error("Failed to create the venue.");
        }
      } else {
        const updatedItemStatus =
          formData?.itemStatus === "Approved"
            ? "Edit Pending"
            : formData?.itemStatus === "Rejected"
            ? "ReSubmitting"
            : "pending";

        // Manually construct the updated form data
        const updatedFormData = {
          ...formData,
          itemStatus: updatedItemStatus,
          vendorId: vendorId,
          status: "completed",
        };
        const response = await axios.post(
          `${base_URL}/api/restaurantform/updateResForm`,
          updatedFormData
          // { ...formData, vendorId: vendorId, status: "completed" }
        );

        if (response.data.success) {
          toast.success("Restaurant created successfully!");
          Cookies.remove("uploadedFiles");
          resetForm();
          setTimeout(() => {
            navigate("/dashboard?restaurantRegister=true");
          }, 1000); // Delay to allow toast message to display
        } else {
          // Handle unsuccessful restaurant creation
          toast.error("Failed to create the restaurant.");
        }
      }
    }
  };

  // Toggle modal visibility
  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  // Open the modal with all photos
  const handleShowAllPhotos = () => {
    setIsModalOpen(true);
  };

  // Close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const toggleFullScreen = () => {
    const elem = document.querySelector(".custom-carousel");
    if (!document.fullscreenElement) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  return (
    <div className="p-6">
      <div className="flex flex-wrap lg:px-20 justify-between gap-5 items-center w-full mb-6">
        <div
          onClick={
            path.pathname?.includes("/restaurant-preview")
              ? () => {
                  setCurrentStep(10); // Navigate to step 10 (Preview)
                  navigate("/venu-restaurant-Form/Restaurant/" + id);
                }
              : () => {
                  navigate("/create-Listing/Venue/" + id);
                }
          }
          className="flex items-center text-gray-600 cursor-pointer"
        >
          <FaArrowLeft className="mr-2" />
          <span>Back</span>
        </div>

        <div className="flex gap-3 items-center">
          <div className="bg-black px-2 md:px-0 md:w-[200px] h-[40px] gap-x-2 flex items-center justify-center rounded-lg">
            <img src="/tick.svg" alt="" />
            <button className=" text-white  " onClick={handleSubmit}>
              Submit for review
            </button>
          </div>
          <Link
            to={
              path.pathname?.includes("/restaurant-preview")
                ? `/venu-restaurant-Form/Restaurant/${id}`
                : "/venu-Listing-Form"
            }
            className="bg-red-500 text-white px-2 md:px-0 md:w-[200px] h-[40px] justify-center rounded-lg flex items-center"
          >
            <AiOutlineEdit className="mr-2" /> Edit
          </Link>
        </div>
      </div>
      {/* venue name and previewlisting card button */}
      <div className="md:flex justify-between lg:px-20 mb-5">
        <div>
          <h2 className=" text-[24px] md:text-[32px] font-bold flex gap-4 items-center">
            Venue Detail Page
          </h2>
        </div>
        {/* preview button */}

        <div
          className="active:opacity-30 mt-1 flex items-center justify-center text-[#222222] font-[500px] px-2 border border-[#222222] rounded-lg md:px-0 md:w-[250px] h-[40px] gap-x-3 cursor-pointer"
          onClick={handleBackToPreview}
        >
          <img src="/eye.svg" alt="eye" />
          <button>Preview Listing Card</button>
        </div>
      </div>
      {/* Main image and right side images */}
      <div className="flex justify-between flex-wrap gap-1 lg:gap-0 lg:px-16 px-0 mb-4">
        {/* Main image */}
        {data[0] && (
          <div className="relative lg:w-1/2 w-full">
            <img
              onClick={handleExpandClick}
              src={data[0]}
              alt="Main"
              className="rounded-lg w-full md:h-[585px] h-[228px] object-cover cursor-pointer"
            />
          </div>
        )}

        {/* Right side images */}
        <div className="flex flex-col justify-between lg:w-[49.5%] w-full">
          {data[1] && (
            <img
              src={data[1]}
              alt="2"
              className="rounded-lg mb-2 md:h-[351px] h-[228px] w-full object-cover cursor-pointer"
            />
          )}
          <div className="flex justify-between gap-1  flex-wrap">
            {data[2] && (
              <img
                src={data[2]}
                alt="3"
                className="rounded-lg mb-4 h-[228px] w-full sm:w-[50%] object-cover cursor-pointer"
              />
            )}
            {(data.length > 3 || videos.length > 0) && (
              <div
                className="h-[228px] w-full sm:w-[49%] overflow-hidden relative cursor-pointer"
                onClick={handleShowAllPhotos} // Show all photos click handler
              >
                {videos?.length > 0 ? (
                  <div>
                    {videos?.map((video, index) => (
                      <video
                        key={index}
                        className="rounded-lg mb-4 w-full h-[50%] object-cover cursor-pointer"
                        controls
                        onLoadedMetadata={(e) => (e.target.currentTime = 1)}
                        preload="metadata"
                      >
                        <source src={video} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ))}
                  </div>
                ) : (
                  <img
                    src={data[3]}
                    alt="3"
                    className="rounded-lg mb-4 w-full h-full object-cover"
                  />
                )}
                <div className="absolute top-0 h-[228px] rounded-lg bg-opacity-80 w-full bg-black text-white flex items-center justify-center cursor-pointer gap-x-3">
                  <img src="/puzzle.svg" alt="" />
                  Show all photos
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modal for showing all photos */}
      {isModalOpen && (
        <div className="custom-carousel z-[5000] absolute inset-0 bg-black/80 flex items-center justify-center">
          <div className="text-[16px] text-white flex gap-x-2 absolute top-2 z-[5000] ">
            <p>{`${activeIndex + 1} / ${data?.length + videos?.length} `}</p>
            <button
              className="text-white mr-3 text-lg cursor-pointer"
              onClick={toggleFullScreen}
            >
              <MdFullscreen />
            </button>
            <button
              className="text-white text-lg cursor-pointer"
              onClick={handleCloseModal}
            >
              <MdClose />
            </button>
          </div>

          <Carousel
            selectedItem={activeIndex}
            showArrows
            onChange={(index) => setActiveIndex(index)}
            showThumbs={true}
            showStatus={false}
            infiniteLoop
            className=" custom-carousel"
            showIndicators={false}
          >
            {data?.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`${index}`}
                className="object-contain max-w-full max-h-[566px]"
              />
            ))}
            {videos?.map((video, index) => {
              // Check if the video is a YouTube link or .mp4 file
              const isYouTubeLink =
                video.includes("youtube.com") || video.includes("youtu.be");

              return isYouTubeLink ? (
                <div
                  key={index + data?.length + 1}
                  className="w-full sm:w-[69%] m-auto h-[590px] relative mb-1"
                >
                  <iframe
                    className="rounded-lg w-full h-full object-cover cursor-pointer"
                    src={`https://www.youtube.com/embed/${
                      video.split("v=")[1] || video.split("/").pop()
                    }`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded YouTube Video"
                    onLoadedMetadata={(e) => (e.target.currentTime = 1)}
                    preload="metadata"
                  />
                </div>
              ) : (
                <video
                  key={index + data?.length + 1}
                  className="rounded-lg mb-4 sm:w-[69%] h-[590px] w-full  object-cover cursor-pointer mx-auto"
                  controls
                  onLoadedMetadata={(e) => (e.target.currentTime = 1)}
                  preload="metadata"
                >
                  <source src={video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              );
            })}
          </Carousel>
        </div>
      )}
    </div>
  );
};

export default HeroSection;
