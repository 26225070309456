import * as React from "react";
import { useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import ResponsiveAppBar from "../../components/ResponsiveAppBar";
import OutlinedLabelledTextField from "../../components/OutlinedLabelledTextfield";
import backgroundIcon from "../../assets/images/background_icon.svg";
import { useTheme } from "@mui/material/styles";
import { login } from "../../api/login.request";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchLoggedUser } from "../../api/loggedUser";

function Login() {
  const [user, setUser] = useState();

  React.useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetchLoggedUser();
        setUser(response.vendor);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, []);

  const theme = useTheme();
  const [workMail, setWorkMail] = useState("");
  const [createPassword, setCreatePassword] = useState("");
  const [error, setError] = useState(""); // New state for error message
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const loginData = {
        email: workMail.toLowerCase(),
        password: createPassword,
      };

      // Perform login request
      const response = await login(loginData);

      // Store tokens in localStorage
      const { accessToken, refreshToken, vendor } = response;
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("vendorId", vendor?.id);
      localStorage.setItem("payment", vendor?.confirmPayment);
      // Set secure cookie for refresh token
      if (refreshToken) {
        setRefreshTokenCookie(refreshToken);
      }

      // Handle navigation based on vendor role and data
      handleNavigation(vendor);
    } catch (error) {
      handleError(error);
    }
  };

  // Helper function to set refresh token cookie
  const setRefreshTokenCookie = (refreshToken) => {
    const secureOptions = {
      sameSite: "strict",
      httpOnly: true,
      secure: true,
      path: "/",
    };

    document.cookie = `refreshToken=${refreshToken}; ${Object.entries(
      secureOptions
    )
      .map(([key, value]) => `${key}=${value}`)
      .join("; ")}`;
  };

  // Helper function to handle navigation based on vendor data
  const handleNavigation = (vendor) => {
    if (vendor?.confirmPayment === false) {
    navigate("/onboarding");
    return;
    }
    const hasAdminRights = vendor?.isAdmin == true;
    const hasFilledForms =
      vendor?.RestaurantForm?.length > 0 || vendor?.venueForms?.length > 0;
    const hasHotels = vendor?.hotel?.length > 0;

    if (hasAdminRights && hasFilledForms && hasHotels) {
      navigate("/myVenue");
    } else if (vendor?.allowDashboard) {
      navigate("/myVenue");
    } else {
      vendor?.payment ? navigate("/dashboard") : navigate("/onboarding");
    }
  };

  // Helper function to handle errors
  const handleError = (error) => {
    const errorMessage =
      error.response?.data?.message || "An error occurred. Please try again.";
    setError(errorMessage);
    toast.error(error.message);
  };

  const isFormValid = () => {
    return workMail.trim() !== "" && createPassword.trim() !== "";
  };

  return (
    <>
      <ResponsiveAppBar />
      <div
        style={{
          backgroundImage: `url(${backgroundIcon})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
          minHeight: "75vh",
        }}
      >
        <Container>
          <Box
            sx={{
              mt: 6,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                mt: { xs: 7, md: 10 },
                fontWeight: "bold",
                fontSize: { xs: "1.6rem" },
              }}
            >
              Log in as{" "}
              <span style={{ color: theme.palette.primary.main }}>
                Venue Owner
              </span>
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ mb: { md: 4 }, color: "grey.text" }}
            >
              Welcome back! Log in and continue your journey.
            </Typography>
          </Box>
        </Container>
        <Container
          maxWidth="sm"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box
            sx={{
              mt: 7,
              width: {
                xs: "95%",
                md: "80%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              },
            }}
          >
            <form onSubmit={handleSubmit}>
              <OutlinedLabelledTextField
                id="workMail"
                label="Work Email"
                placeholder="user@workapp.com"
                type="email"
                value={workMail}
                onChange={(e) => setWorkMail(e.target.value)}
                error={!!error} // Set error state for workMail field
                helperText={error && error.includes("Vendor") ? error : ""} // Display error if it contains "Vendor"
              />

              <OutlinedLabelledTextField
                id="createPassword"
                label="Password"
                placeholder="Enter your password"
                type={showPassword ? "text" : "password"}
                value={createPassword}
                onChange={(e) => setCreatePassword(e.target.value)}
                error={!!error} // Set error state for createPassword field
                helperText={error && !error.includes("Vendor") ? error : ""} // Display non-"Vendor" error message\
                eye={true}
                showPassword={showPassword}
                togglefunction={togglePasswordVisibility}
              />

              <Box sx={{ mb: 2, textAlign: "right" }}>
                <Link
                  href="/reset"
                  color="primary"
                  sx={{ textDecoration: "none", fontWeight: "500" }}
                >
                  Forgot Password?
                </Link>
              </Box>

              <Button
                type="submit"
                variant="contained"
                fullWidth
                size="large"
                sx={{
                  mb: { xs: 1, md: 3 },
                  borderRadius: "8px",
                  mt: 2,
                  backgroundColor: isFormValid()
                    ? "primary.main"
                    : "grey.border",
                  color: isFormValid() ? "white" : "text",
                }}
                disabled={!isFormValid()}
              >
                Log In
              </Button>
            </form>

            <Typography
              variant="body2"
              sx={{
                fontSize: "1rem",
                color: "grey.text",
                marginInline: "auto",
              }}
            >
              Don't have an account?{" "}
              <Link
                href="/register"
                color="primary"
                sx={{ textDecoration: "none", fontWeight: "500" }}
              >
                Register
              </Link>
            </Typography>
          </Box>
        </Container>
      </div>
    </>
  );
}

export default Login;
