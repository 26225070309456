"use client";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from "../../assets/pics/Dubai.jpg";
import img2 from "../../assets/pics/Ajman.jpg";
import img3 from "../../assets/pics/Sharjah.jpg";
import img4 from "../../assets/pics/Umm Al Quwain.jpg";
import img5 from "../../assets/pics/Abu Dhabi.jpg";
import img6 from "../../assets/pics/Fujairah.jpg";
import img7 from "../../assets/pics/Ras Al Khaimah.jpg";

const ImageCarousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 2.05,
    slidesToScroll: 1,
  };

  const slidesData = [
    {
      img: "https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730980056/WhatsApp_Image_2024-11-07_at_5.16.19_PM_1_mgljnw.jpg",
      name: "Dubai",
      venus: "2829 Venues",
    },
    {
      img: "https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730980342/Abu_Dhabi.7b61c879dfa310c3699c_ffeeyl.jpg",
      name: "Abu Dhabi",
      venus: "2829 Venues",
    },
    {
      img: "https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730980169/Ras_Al_Khaimah.603afa2691ff6d90c1c7_acx8bq.jpg",
      name: "Ras Al Kaimah",
      venus: "2829 Venues",
    },
    {
      img: "https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730980099/WhatsApp_Image_2024-11-07_at_5.16.19_PM_2_blujzd.jpg",
      name: "Sharjah",
      venus: "2829 Venues",
    },
    {
      img: "https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730980230/Fujairah.d85bd089364414199871_iyrvlw.jpg",
      name: "Fujairah",
      venus: "2829 Venues",
    },
    {
      img: "https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730980022/WhatsApp_Image_2024-11-07_at_5.16.19_PM_mzjrhj.jpg",
      name: "Ajman",
      venus: "2829 Venues",
    },
    {
      img: "https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730980284/Umm_Al_Quwain.b8a4c5cfb96c1b95bd7c_fpxc17.jpg",
      name: "Umm Al Quwain",
      venus: "2829 Venues",
    },
  ];

  return (
    <div className="">
      <Slider {...settings}>
        {slidesData.map((e, i) => (
          <div key={i} className="px-2 w-full">
            <div className="bg-white border rounded-lg  relative flex-col  w-full h-full flex items-center ">
              <img src={e.img} alt="" className="rounded-lg h-40 w-full" />

              <div className=" flex flex-col items-center justify-end pb-2 bg-shadow-gradient bottom-0 w-full h-[5rem] absolute rounded-b-lg  z-30">
                <h1 className=" text-lg font-medium">{e.name}</h1>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageCarousel;
