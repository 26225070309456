import { useState } from "react";

const BrochureModal = ({ brochures, isOpen, onClose, title }) => {
  if (!isOpen) return null;

  const isImage = (url) => {
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "webp"];
    const ext = url.split(".").pop().toLowerCase();
    return imageExtensions.includes(ext);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg max-w-2xl w-full p-6 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-xl font-bold"
        >
          &times;
        </button>
        <h2 className="text-2xl font-bold mb-6">{title} Preview</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {/* Change to 3 columns */}
          {brochures?.map((url, index) => (
            <div key={index} className="mb-4">
              {isImage(url) ? (
                // Display image preview
                <img
                  src={url}
                  alt={`Brochure ${index + 1}`}
                  className="h-[150px] w-[150px] object-cover cursor-pointer"
                  onClick={() => {
                    const newWindow = window.open();
                    newWindow.document.write(
                      `<embed src="${url}" width="100%" height="100%">`
                    );
                  }}
                />
              ) : (
                // Display PDF preview
                <div
                  onClick={() => {
                    const newWindow = window.open();
                    newWindow.document.write(
                      `<embed src="${url}" width="100%" height="100%">`
                    );
                  }}
                  className="h-[150px] w-[150px] cursor-pointer bg-gray-100 flex items-center justify-center"
                >
                  <p className="text-gray-500">PDF Document</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BrochureModal;
