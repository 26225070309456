import React from "react";
import logo from "../assets/pics/logoMain.svg";
// import DashboardIcon from "../assets/pics/DashboardIcon.svg";
// import CrownIcon from "../assets/pics/CrownIcon.svg";
// import TeamIcon from "../assets/pics/TeamIcon.svg";
// import descriptionIcon from "../assets/pics/descriptionIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import CrownIcon from "./shared/common/icons/crown-icon";
import DashboardIcon from "./shared/common/icons/dashboard-icon";
import DescriptionIcon from "./shared/common/icons/description-icon";
import TeamIcon from "./shared/common/icons/team-icons";

const MobileMenu = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (to) => {
    navigate(to);
    props.toggleSidebar();
  };

  return (
    <div
      className={`min-h-screen z-[999] top-0 left-0 pl-8 h-full w-full bg-gray-50 border-r shadow-md`}
    >
      <div
        style={{ marginLeft: "-85px" }}
        className="p-4 hidden lg:flex items-center justify-center"
      >
        <img src={logo} alt="Logo" className="h-15" />
      </div>
      <nav className="mt-10 lg:mt-4">
        <ul className="space-y-2">
          <li>
            <div
              role="button"
              onClick={() => handleClick("/dashboard")}
              style={{
                backgroundColor:
                  location.pathname === "/dashboard"
                    ? props.colour.backgroundcolour
                    : "",
                marginRight: "7px",
                borderRadius: "9px",
              }}
              className="flex items-center p-2 text-gray-700 hover:bg-primary8 hover:text-primary hover:rounded-lg"
            >
              {/* <img src={DashboardIcon} alt="Dashboard" className="h-6 w-6" /> */}
              <DashboardIcon
                color={
                  location.pathname === "/dashboard"
                    ? props.colour.colour
                    : "#B0B0B0"
                }
              />
              <span
                className="ml-3"
                style={{
                  color:
                    location.pathname === "/dashboard"
                      ? props.colour.colour
                      : "",
                }}
              >
                Dashboard
              </span>
            </div>
          </li>

          <li>
            <div
              role="button"
              style={{
                backgroundColor:
                  location.pathname === "/team"
                    ? props.colour.backgroundcolour
                    : "",
              }}
              onClick={() => handleClick("/team")}
              className="flex items-center p-2 text-gray-700 hover:bg-primary8 hover:text-primary hover:rounded-lg mr-2"
            >
              {/* <img src={TeamIcon} alt="My Team" className="h-6 w-6" /> */}
              <TeamIcon
                color={
                  location.pathname === "/team"
                    ? props.colour.colour
                    : "#B0B0B0"
                }
              />
              <span
                className="ml-3"
                style={{
                  color:
                    location.pathname === "/team" ? props.colour.colour : "",
                }}
              >
                My Team
              </span>
            </div>
          </li>
          <li>
            <div
              role="button"
              style={{
                backgroundColor:
                  location.pathname === "/myVenue"
                    ? props.colour.backgroundcolour
                    : "",
              }}
              onClick={() => handleClick("/myVenue")}
              className="flex items-center p-2 text-gray-700 hover:bg-primary8 hover:text-primary hover:rounded-lg mr-2"
            >
              {/* <img src={descriptionIcon} alt="My Venues" className="h-6 w-6" /> */}
              <DescriptionIcon
                color={
                  location.pathname === "/myVenue"
                    ? props.colour.colour
                    : "#B0B0B0"
                }
              />
              <span
                className="ml-3"
                style={{
                  color:
                    location.pathname === "/myVenue" ? props.colour.colour : "",
                }}
              >
                My Venues
              </span>
            </div>
          </li>

          <li>
            <div
              role="button"
              style={{
                backgroundColor:
                  location.pathname === "/mysubscriptions"
                    ? props.colour.backgroundcolour
                    : "",
              }}
              onClick={() => handleClick("/mysubscriptions")}
              className="flex items-center p-2 text-gray-700 hover:bg-primary8 hover:text-primary hover:rounded-lg mr-2"
            >
              <CrownIcon
                color={
                  location.pathname === "/mysubscriptions"
                    ? props.colour.colour
                    : "#B0B0B0"
                }
              />
              <span
                className="ml-3"
                style={{
                  color:
                    location.pathname === "/mysubscriptions"
                      ? props.colour.colour
                      : "",
                }}
              >
                My Subscription
              </span>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default MobileMenu;
