/* eslint-disable no-unused-vars */
"use client";
import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layout/dashboard-layout";
import Profile from "../../assets/icons/user.svg";
import { ImPencil } from "react-icons/im";
import { RiDeleteBin6Line } from "react-icons/ri";
import icons from "../../assets/pics/icons.svg";
import AddUserModal from "../../components/user-management/add-user-modal";
import DeleteUserModal from "../../components/user-management/delete-user";
import {
  useTeamMembers,
  useTeamMemberSearch,
} from "../../queries/team-members";
import { Alert, Snackbar } from "@mui/material";
import { Link } from "react-router-dom";
import EditUserModal from "../../components/user-management/edit-user-modal";
import UserCard from "../../components/user-management/user-card";
import { getVenueDetails } from "../../api/venue-api";
import { fetchLoggedUser, fetchUserById } from "../../api/loggedUser";
import { getRestaurant } from "../../api/restaurant-api";
import { getHotelById } from "../../api/hotel-api";
import { VenuePopup } from "./VenuePopup";

export default function Team() {
  const [popupVisible, setPopupVisible] = useState(false);
  const [currentUserVenues, setCurrentUserVenues] = useState([]);
  const [user, setUser] = useState(null);
  const [superAdmin, setSuperAdmin] = useState(null);
  const [teamList, setTeamList] = useState([]);
  const [addmodal, showAddModal] = useState(false);
  const [editmodal, setShowEditModal] = useState(false);
  const [deleteModal, showDeleteModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [userId, setUserId] = useState(null);
  const [userData, setUserData] = useState(null);
  const invitedBy = localStorage.getItem("invitedBy");
  const checkAdmin = localStorage.getItem("checkAdmin");
  const vendorId = invitedBy || localStorage.getItem("vendorId");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Fetching team members
  const {
    data: teamMembers,
    isPending,
    isLoading,
  } = useTeamMembers(vendorId, searchQuery);

  const { data: search, refetch } = useTeamMemberSearch(searchQuery);
  const [venueNames, setVenueNames] = useState({});

  const getVenuDetailsByVenueId = async (venueId) => {
    try {
      const response = await getVenueDetails(venueId);
      return (
        response?.venue?.basicDetails?.venueName ||
        response?.venue?.basicDetails?.hotelName
      );
    } catch (error) {
      console.log(error);
      return null;
    }
  };
  const getRestaurantDetailsById = async (venueId) => {
    try {
      const response = await getRestaurant(venueId);
      return (
        response?.basicDetails?.venueName || response?.basicDetails?.hotelName
      );
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const getHotelDetailsById = async (hotelId) => {
    try {
      const response = await getHotelById(hotelId);
      return response?.hotel?.overview?.basicDetails?.hotelName;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  useEffect(() => {
    if (teamMembers?.users) {
      const fetchVenueNames = async () => {
        const venueNamesMap = {};
        for (const user of teamMembers.users) {
          if (user.assignedVenues && user.assignedVenues.length > 0) {
            for (const venueId of user.assignedVenues) {
              const venueName = await getVenuDetailsByVenueId(venueId);
              if (venueName) {
                venueNamesMap[venueId] = venueName;
              }
            }
          }
          if (user.RestaurantForm && user.RestaurantForm.length > 0) {
            for (const venueId of user.RestaurantForm) {
              const venueName = await getRestaurantDetailsById(venueId);
              if (venueName) {
                venueNamesMap[venueId] = venueName;
              }
            }
          }
          if (user.Hotel && user.Hotel.length > 0) {
            for (const hotelId of user.Hotel) {
              const hotelName = await getHotelDetailsById(hotelId);
              if (hotelName) {
                venueNamesMap[hotelId] = hotelName;
              }
            }
          }
        }
        setVenueNames(venueNamesMap);
      };
      fetchVenueNames();
    }
  }, [teamMembers]);

  const callModal = () => {
    showAddModal(true);
  };

  const deleteModalFun = (userId) => {
    setUserId(userId);
    showDeleteModal(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleEdit = (user) => {
    setUserData(user);
    setShowEditModal(true);
  };

  // getting super admin
  // for primary vendors means super admin run else condition
  // for sub admin members and limited ones run if condition
  // invited by is super admin basically
  useEffect(() => {
    const fetchUser = async () => {
      try {
        let response;
        if (invitedBy) {
          // Fetch the super admin based on invitedBy ID
          response = await fetchUserById(vendorId);
        } else {
          // Fetch the logged-in vendor as the super admin
          response = await fetchLoggedUser();
        }
        setUser(response.vendor || response.user);
        setSuperAdmin(response.vendor || response.user);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, []);

  const handleSearchClick = () => {
    setSearchQuery(searchValue);
    refetch(); // Refetches the search results
  };
  // Combining super admin with team members
  useEffect(() => {
    if (superAdmin && teamMembers?.users) {
      const combinedTeam = [superAdmin, ...teamMembers.users];
      setTeamList(combinedTeam);
    }
  }, [superAdmin, teamMembers]);

  // Filter out emails which are not in teamlist (So that user cannot search other content)
  const filteredSearchResults = search?.filter((searchItem) =>
    teamList.some((teamMember) => teamMember.email === searchItem.email)
  );

  // Determine whether to use search results or default team list
  const identify = searchQuery !== "" ? filteredSearchResults : teamList;

  return (
    <DashboardLayout>
      {addmodal && (
        <AddUserModal
          setSnackbar={setSnackbar}
          showAddModal={showAddModal}
          setShowEditModal={setShowEditModal}
          setUserData={setUserData}
        />
      )}
      {editmodal && (
        <EditUserModal
          setSnackbar={setSnackbar}
          setShowEditModal={setShowEditModal}
          data={userData}
        />
      )}
      {deleteModal && (
        <DeleteUserModal
          setSnackbar={setSnackbar}
          showDeleteModal={showDeleteModal}
          userId={userId}
        />
      )}
      <div className="flex w-full py-4 justify-center">
        <div className="w-[90%] flex flex-col gap-5">
          <div className="flex w-full justify-between items-center">
            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-2">
                <Link to="/dashboard" className="text-[#FE4747]">
                  Dashboard
                </Link>{" "}
                <img src="/ar.svg" alt="" />
                <p>My Team</p>
              </div>
              <h2 className="text-xl font-medium md:text-2xl md:font-semibold">
                My Team
              </h2>
            </div>
            {checkAdmin == "true" && (
              <button
                style={{ width: "120px" }}
                onClick={() => callModal()}
                className="mt-8 bg-red-500 text-white py-2 px-4 rounded"
              >
                + Add User
              </button>
            )}
          </div>
          <div className="relative w-full max-w-md z-10">
            <input
              type="text"
              placeholder="Search name, email, phone.."
              className="w-full pl-4 pr-10 py-2 border rounded-full focus:outline-none"
              value={searchValue}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearchClick();
                }
              }}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <div
              onClick={handleSearchClick}
              role="button"
              className="absolute inset-y-0 right-2 flex items-center pr-3"
            >
              <img src={icons} alt="Search Icon" className="h-5 w-5" />
            </div>
            {/* Cancel Button to reset search .*/}
            {searchQuery && (
              <button
                onClick={() => {
                  setSearchValue("");
                  setSearchQuery("");
                }}
                className="absolute inset-y-0 right-12 flex items-center pr-3 text-gray-500 hover:text-black"
              >
                Cancel
              </button>
            )}
          </div>

          {/* Table without search and wehn query is empty*/}
          <div className="w-full hidden md:block">
            <div
              className="grid grid-cols-8 h-[57px] bg-[#e4e4e4] items-center px-5"
              style={{
                borderTopLeftRadius: "6px",
                borderTopRightRadius: "6px",
              }}
            >
              <p className="font-medium">Name</p>
              <p className="font-medium ml-2">Access</p>
              <p className="font-medium col-span-2">Email</p>
              <p className="font-medium ml-2">Phone</p>
              <p className="font-medium col-span-2 ml-2">Venues Assigned</p>
              {checkAdmin === "true" && (
                <p className="font-medium text-end">Actions</p>
              )}
            </div>

            {isPending
              ? null
              : identify?.map((e) => {
                  const isSuperAdmin = e?._id === superAdmin?._id;

                  return (
                    <div
                      key={e?._id}
                      className="grid grid-cols-8 items-center h-auto bg-white px-2 py-2"
                    >
                      <div className="flex gap-2">
                        <img
                          src={Profile}
                          alt="profile-image"
                          className="lg:block hidden"
                        />
                        <div className="  text-ellipsis block overflow-hidden whitespace-nowrap">
                          <Link
                            to={`/team/team-details/${e?._id}`}
                            className="text-[#222222] 
                            text-ellipsis block overflow-hidden whitespace-nowrap "
                          >
                            {e?.name}
                          </Link>
                          <p className="text-[#717171] text-xs  text-ellipsis block overflow-hidden whitespace-nowrap">
                            {e?.role}
                          </p>
                        </div>
                      </div>
                      <div className="">
                        <p
                          className={`${
                            e?.isAdmin
                              ? "bg-[#E4EFFF] text-[#4A49D9]"
                              : "bg-[#FFF6E6] text-[#FAA90E]"
                          } ml-2 px-2 py-1 w-fit rounded-[6px]`}
                        >
                          {e?.isAdmin ? "Admin" : "Limited"}
                        </p>
                      </div>
                      <div className="col-span-2">
                        <p className="text-[#222222]  text-ellipsis block overflow-hidden whitespace-nowrap">
                          {e?.email}
                        </p>
                      </div>
                      <div>
                        {e?.phone && (
                          <p className="text-[#222222] ml-2">+{e?.phone}</p>
                        )}
                      </div>
                      <div className="col-span-2 ml-2">
                        {e?.isAdmin ? (
                          <p className="bg-[#E4EFFF] px-2 py-1 w-fit rounded-[6px] text-[#1252B1]">
                            Full-Access
                          </p>
                        ) : (
                          <>
                            <div className="flex gap-x-2">
                              {e?.assignedVenues?.length > 0 ||
                              e?.RestaurantForm?.length > 0 ||
                              e?.Hotel?.length > 0 ? (
                                <>
                                  {[
                                    ...(e?.assignedVenues || []),
                                    ...(e?.RestaurantForm || []),
                                    ...(e?.Hotel || []),
                                  ]
                                    .slice(0, 2) // Display only the first two venues
                                    .map((venueId) => (
                                      <p
                                        key={venueId}
                                        className="bg-[#E4EFFF] px-2 py-1 w-fit rounded-[6px] text-[#1252B1]"
                                      >
                                        {venueNames[venueId] || "Loading..."}
                                      </p>
                                    ))}
                                  {[
                                    ...(e?.assignedVenues || []),
                                    ...(e?.RestaurantForm || []),
                                    ...(e?.Hotel || []),
                                  ].length > 2 && (
                                    <p
                                      className="cursor-pointer bg-[#EBEBEB] px-2 py-1 w-fit rounded-full text-[#5E5E5E]"
                                      // show all venues on clicking +1,+2 so on
                                      onClick={() => {
                                        setCurrentUserVenues([
                                          ...(e?.assignedVenues || []),
                                          ...(e?.RestaurantForm || []),
                                          ...(e?.Hotel || []),
                                        ]);
                                        setPopupVisible(true);
                                      }}
                                    >
                                      +
                                      {[
                                        ...(e?.assignedVenues || []),
                                        ...(e?.RestaurantForm || []),
                                        ...(e?.Hotel || []),
                                      ].length - 2}
                                    </p>
                                  )}
                                </>
                              ) : (
                                <p>Limited</p>
                              )}
                            </div>
                          </>
                        )}
                      </div>

                      <div className="flex items-center justify-end gap-3">
                        {checkAdmin === "true" && !isSuperAdmin && (
                          <ImPencil
                            color="#222222"
                            onClick={() => handleEdit(e)}
                            role="button"
                          />
                        )}

                        {!isSuperAdmin && checkAdmin === "true" && (
                          <RiDeleteBin6Line
                            color="#222222"
                            onClick={() => deleteModalFun(e?._id)}
                            role="button"
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
          </div>

          {/* Mobile View */}
          <div className="w-full flex flex-col gap-y-2 md:hidden bg-white rounded-md p-2">
            {isPending
              ? null
              : identify?.map((user) => (
                  <UserCard
                    key={user?._id}
                    data={user}
                    handleEdit={handleEdit}
                    deleteModalFun={deleteModalFun}
                  />
                ))}
          </div>
        </div>
      </div>
      {/* on click show all venues of user */}
      {popupVisible && (
        <VenuePopup
          venues={currentUserVenues?.map(
            (id) => venueNames[id] || "Loading..."
          )}
          onClose={() => setPopupVisible(false)}
        />
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </DashboardLayout>
  );
}
