import costStructure from "../constants/costStructure";

function calculateRestaurantPayment(
  withAlcoholNumber,
  withoutAlcoholNumber,
  quantityNumber
) {
  // const baseCharge = 2000;
  let withAlcoholRate = 0;
  let withoutAlcoholRate = 0;
  let withAlcoholCost = 0;
  let withoutAlcoholCost = 0;
  let withAlcoholSaving = 0;
  let withoutAlcoholSaving = 0;

  if (withAlcoholNumber === 1) {
    withAlcoholRate = 1500;
  } else if (withAlcoholNumber >= 2 && withAlcoholNumber <= 5) {
    withAlcoholRate = 1300;
  } else if (withAlcoholNumber > 5) {
    withAlcoholRate = 1200;
  }

  if (withoutAlcoholNumber === 1) {
    withoutAlcoholRate = 1000;
  } else if (withoutAlcoholNumber >= 2 && withoutAlcoholNumber <= 5) {
    withoutAlcoholRate = 900;
  } else if (withoutAlcoholNumber > 5) {
    withoutAlcoholRate = 800;
  }

  if (withAlcoholNumber > 0) {
    withAlcoholSaving = (1500 - withAlcoholRate) * withAlcoholNumber;
  }
  if (withoutAlcoholNumber > 0) {
    withoutAlcoholSaving = (1000 - withoutAlcoholRate) * withoutAlcoholNumber;
  }

  withAlcoholCost = withAlcoholRate * withAlcoholNumber;
  withoutAlcoholCost = withoutAlcoholRate * withoutAlcoholNumber;
  
  const subTotal = withAlcoholCost + withoutAlcoholCost;
  const vatPercentage = costStructure.vat;
  const vat = (subTotal * vatPercentage) / 100;
  const grandTotal = subTotal + vat;


  const data = {
    withAlcoholRate: withAlcoholRate,
    withoutAlcoholRate: withoutAlcoholRate,
    withAlcoholCost: withAlcoholCost,
    withoutAlcoholCost: withoutAlcoholCost,
    withAlcoholSaving: withAlcoholSaving,
    withoutAlcoholSaving: withoutAlcoholSaving,
    subTotal: subTotal,
    vat: vat,
    grandTotal: grandTotal,
  };



  return data;
}

export default calculateRestaurantPayment;
