import React, { useState } from "react";
import { Chip, Box, Snackbar, Alert, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const mainChips = [
  { key: "Restaurants, Cafe's & Bar", value: "Restaurants, Cafe's & Bar" },
  { key: "Kids Venue", value: "Kids Play Area" },
  { key: "Other Venue", value: "Other" }
];

// Define tooltip text for each chip label
const tooltipTexts = {
  "Restaurants, Cafe's & Bar": "A venue that offers restaurant, cafe, or bar services.",
  "Kids Venue": "A venue that is designed as a kids play area or activity centre, offering space for hosting kids events. This would exclude any areas designated for restaurant services.",
  'Other Venue': "Other types of venues."
};

export default function CustomIconChipsVenue({
  onChipsChange,
  isSelectOneChip = false,
}) {
  const theme = useTheme();
  const [selectedChips, setSelectedChips] = useState(new Set());
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [showAllChips, setShowAllChips] = useState(false);

  const handleClick = (label) => {
    setSelectedChips((prevSelectedChips) => {
      const updatedSelectedChips = new Set(prevSelectedChips);

      if (isSelectOneChip) {
        updatedSelectedChips.clear();
        updatedSelectedChips.add(label);
      } else {
        if (updatedSelectedChips.has(label)) {
          updatedSelectedChips.delete(label);
        } else {
          updatedSelectedChips.add(label);
        }
      }

      onChipsChange(Array.from(updatedSelectedChips));
      return updatedSelectedChips;
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };
  return (
    <>
      <Box>
        {mainChips.map((chip) => (
          <button
            key={chip.value}
            onClick={() => handleClick(chip.value)}
            className={`w-[400px] mb-3 mx-auto py-3 px-5 flex border justify-between rounded-xl ${selectedChips.has(chip.value)
              ? "border-[#039F8D] text-[#039F8D]"
              : "border-gray-300"
              }`}
          >
            {chip.key}
            <Tooltip title={tooltipTexts[chip.key]} arrow>
              <img src="/Info.svg" width={24} height={24} alt="Info" />
            </Tooltip>
          </button>
        ))}
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}



// import React, { useState } from "react";
// import { Chip, Box, Snackbar, Alert, Button, Tooltip } from "@mui/material";
// import DoneIcon from "@mui/icons-material/Done";
// import AddIcon from "@mui/icons-material/Add";
// import { useTheme } from "@mui/material/styles";
// import Questions from "./Questions";

// const mainChips = ["Restaurants, Cafe's & Bar", "Kids Play Area", "Other"];
// // const allChips = [
// //   "Therapy room",
// //   "Yoga Studio",
// //   "Spa",
// //   "Beach",
// //   "Desert",
// //   "Farm",
// //   "Helipad",
// //   "Lawn",
// //   "Park",
// //   "Boats & Yachts",
// //   "Adventure venue",
// //   "Escape Rooms",
// //   "Golf courses",
// //   "Sports club",
// //   "Stadium",
// //   "Theme Park",
// //   "Entertainment venue",
// //   "Sports venue",
// //   "Zoo",
// //   "Aquarium",
// //   "Limousine",
// //   "Party bus",
// //   "Conference center",
// //   "Ballroom",
// //   "Co-working space",
// //   "EXPO",
// //   "Meeting Center",
// //   "Meeting room",
// //   "Team activity",
// //   "Training room",
// //   "Exhibitions",
// //   "Dance Studio",
// //   "Photo studio",
// //   "Recording studio",
// //   "Streaming studio",
// //   "TV film studio",
// //   "Art studio",
// //   "Art Gallery",
// //   "Church",
// //   "Gallery",
// //   "Museum",
// //   "Temple",
// // ];

// export default function CustomIconChipsVenue({
//   onChipsChange,
//   isSelectOneChip = false,
// }) {
//   const theme = useTheme();
//   const [selectedChips, setSelectedChips] = useState(new Set());
//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: "",
//     severity: "success",
//   });

//   const [showAllChips, setShowAllChips] = useState(false);
//   const [activeStep, setActiveStep] = useState(0);

//   const handleClick = (label) => {
//     setActiveStep(1);
//     setSelectedChips((prevSelectedChips) => {
//       const updatedSelectedChips = new Set(prevSelectedChips);
//       if (label === "Other") {
//         // setShowAllChips(!showAllChips);
//         return prevSelectedChips;
//       }

//       if (isSelectOneChip) {
//         updatedSelectedChips.clear();
//         updatedSelectedChips.add(label);
//       } else {
//         if (updatedSelectedChips.has(label)) {
//           updatedSelectedChips.delete(label);
//         } else {
//           updatedSelectedChips.add(label);
//         }
//       }

//       onChipsChange(Array.from(updatedSelectedChips));
//       return updatedSelectedChips;
//     });
//   };

//   const handleCloseSnackbar = (event, reason) => {
//     if (reason === "clickaway") {
//       return;
//     }
//     setSnackbar({ ...snackbar, open: false });
//   };

//   return (
//     <>
//       <Box>
//         {activeStep === 0 &&
//           mainChips.map((label, index) => (
//             <button
//               key={index}
//               variant={selectedChips.has(label) ? "contained" : "outlined"}
//               onClick={() => handleClick(label)}
//               className={`w-[400px] mb-3 mx-auto py-3 px-5 flex border justify-between rounded-xl ${
//                 selectedChips.has(label)
//                   ? "border-[#039F8D] text-[#039F8D]"
//                   : "border-gray-300"
//               }`}
//             >
//               {label}
//               <Tooltip title="Select" arrow>
//                 <img src="/Info.svg" width={24} height={24} alt="Info" />
//               </Tooltip>
//             </button>
//           ))}
//         {activeStep === 1 && <Questions />}
//         {/* {showAllChips &&
//                     allChips.map((label, index) => (
//                         <Chip
//                             key={index + mainChips.length}
//                             label={label}
//                             onClick={() => handleClick(label)}
//                             deleteIcon={selectedChips.has(label) ? <DoneIcon /> : <AddIcon />}
//                             onDelete={() => handleClick(label)}
//                             sx={{
//                                 borderRadius: "24px",
//                                 border: `2px solid ${selectedChips.has(label) ? theme.palette.success.main : theme.palette.grey[400]}`,
//                                 color: selectedChips.has(label) ? theme.palette.success.main : theme.palette.grey[700],
//                                 backgroundColor: theme.palette.common.white,
//                                 height: { md: "38px" },
//                                 margin: { xs: "3px", md: "6px" },
//                                 "&.MuiChip-clickable:hover": {
//                                     backgroundColor: theme.palette.common.white,
//                                 },
//                                 "& .MuiChip-deleteIcon": {
//                                     color: selectedChips.has(label) ? theme.palette.success.main : theme.palette.grey[700],
//                                     fontSize: { xs: "0.7rem", md: "1rem" },
//                                 },
//                             }}
//                         />
//                     ))} */}
//       </Box>
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={handleCloseSnackbar}
//       >
//         <Alert
//           onClose={handleCloseSnackbar}
//           severity={snackbar.severity}
//           sx={{ width: "100%" }}
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </>
//   );
// }
