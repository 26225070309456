import React, { useEffect, useState } from "react";

const Gallery = ({
  photos,
  videos,
  handleExpandClick,
  handleShowAllPhotos,
}) => {
  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    const renderGallery = () => {
      let galleryArray = [];

      if (photos.length > 0 && videos.length > 0) {
        galleryArray.push({ src: photos[0], type: "image" });
        galleryArray.push({ src: videos[0], type: "video" });
        let p = 1;
        let v = 1;

        while (galleryArray.length < 4) {
          if (p < photos.length) {
            galleryArray.push({ src: photos[p], type: "image" });
            p++;
          } else if (v < videos.length) {
            galleryArray.push({ src: videos[v], type: "video" });
            v++;
          } else {
            break;
          }
        }
      } else if (photos.length > 0) {
        galleryArray = photos
          .slice(0, 4)
          .map((photo) => ({ src: photo, type: "image" }));
      } else if (videos.length > 0) {
        galleryArray = videos
          .slice(0, 4)
          .map((video) => ({ src: video, type: "video" }));
      }

      setGallery(galleryArray);
    };

    if (photos.length > 0 || videos.length > 0) {
      renderGallery();
    }
  }, [photos, videos]);

  // checking weatherits a youtuber video or other video
  const isYouTubeLink = (url) =>
    url.includes("youtube.com") || url.includes("youtu.be");

  const getYouTubeEmbedUrl = (url) =>
    `https://www.youtube.com/embed/${
      url.split("v=")[1] || url.split("/").pop()
    }`;

  return (
    <div className="flex justify-between flex-wrap gap-1 lg:gap-0 lg:px-16 px-0 mb-4">
      {gallery[0] && (
        <div className="relative lg:w-1/2 w-full">
          {gallery[0].type === "image" ? (
            <img
              onClick={handleExpandClick}
              src={gallery[0].src}
              alt="Cover"
              className="rounded-lg w-full md:h-[585px] h-[228px] object-cover cursor-pointer"
            />
          ) : isYouTubeLink(gallery[0].src) ? (
            <iframe
              className="rounded-lg w-full md:h-[585px] h-[228px] object-cover cursor-pointer"
              src={getYouTubeEmbedUrl(gallery[0].src)}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded YouTube Video"
              onLoadedMetadata={(e) => (e.target.currentTime = 1)}
              preload="metadata"
            />
          ) : (
            <video
              className="rounded-lg w-full md:h-[585px] h-[228px] object-cover cursor-pointer"
              controls
              onLoadedMetadata={(e) => (e.target.currentTime = 1)}
              preload="metadata"
            >
              <source src={gallery[0].src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      )}

      {/* Additional Items */}
      <div className="flex flex-col justify-between lg:w-[49.5%] w-full">
        {gallery[1] &&
          (gallery[1]?.type === "image" ? (
            <img
              src={gallery[1].src}
              alt="1"
              className="rounded-lg mb-2 md:h-[351px] h-[228px] w-full object-cover cursor-pointer"
            />
          ) : isYouTubeLink(gallery[1]?.src) ? (
            <iframe
              className="rounded-lg mb-2 md:h-[351px] h-[228px] w-full object-cover cursor-pointer"
              src={getYouTubeEmbedUrl(gallery[1]?.src)}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded YouTube Video"
              onLoadedMetadata={(e) => (e.target.currentTime = 1)}
              preload="metadata"
            />
          ) : (
            <video
              className="rounded-lg mb-2 md:h-[351px] h-[228px] w-full object-cover cursor-pointer"
              controls
              onLoadedMetadata={(e) => (e.target.currentTime = 1)}
              preload="metadata"
            >
              <source src={gallery[1]?.src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ))}

        <div className="flex justify-between gap-1 flex-wrap relative">
          {gallery[2] &&
            (gallery[2]?.type === "image" ? (
              <img
                src={gallery[2]?.src}
                alt="2"
                className="rounded-lg mb-4 h-[228px] w-full sm:w-[50%] object-cover cursor-pointer"
              />
            ) : isYouTubeLink(gallery[2]?.src) ? (
              <iframe
                className="rounded-lg mb-4 h-[228px] w-full sm:w-[50%] object-cover cursor-pointer"
                src={getYouTubeEmbedUrl(gallery[2]?.src)}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded YouTube Video"
                onLoadedMetadata={(e) => (e.target.currentTime = 1)}
                preload="metadata"
              />
            ) : (
              <video
                className="rounded-lg mb-4 h-[228px] w-full sm:w-[50%] object-cover cursor-pointer"
                controls
                onLoadedMetadata={(e) => (e.target.currentTime = 1)}
                preload="metadata"
              >
                <source src={gallery[2]?.src} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ))}

          {gallery[3] && (
            <div
              className="h-[228px] w-full sm:w-[49%] overflow-hidden relative cursor-pointer"
              onClick={handleShowAllPhotos}
            >
              {gallery[3]?.type === "image" ? (
                <img
                  src={gallery[3]?.src}
                  alt="3"
                  className="rounded-lg mb-4 w-full h-full object-cover"
                />
              ) : isYouTubeLink(gallery[3]?.src) ? (
                <iframe
                  className="rounded-lg mb-4 w-full h-full object-cover cursor-pointer"
                  src={getYouTubeEmbedUrl(gallery[3]?.src)}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded YouTube Video"
                  onLoadedMetadata={(e) => (e.target.currentTime = 1)}
                  preload="metadata"
                />
              ) : (
                <video
                  className="rounded-lg mb-4 w-full h-full object-cover cursor-pointer"
                  controls
                  onLoadedMetadata={(e) => (e.target.currentTime = 1)}
                  preload="metadata"
                >
                  <source src={gallery[3]?.src} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
              <div className="absolute top-0 h-[228px] rounded-lg bg-opacity-80 w-full bg-black text-white flex items-center justify-center cursor-pointer gap-x-3">
                <img src="/puzzle.svg" alt="" />
                Show all photos
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
