const DashboardIcon = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.041 6.74314V3.67416C11.041 3.45678 11.1134 3.27625 11.2582 3.13256C11.4031 2.98885 11.5825 2.91699 11.7966 2.91699H16.3304C16.5445 2.91699 16.7234 2.98885 16.8671 3.13256C17.0108 3.27625 17.0826 3.45678 17.0826 3.67416V6.74314C17.0826 6.96053 17.0102 7.14107 16.8654 7.28476C16.7206 7.42846 16.5411 7.50031 16.327 7.50031H11.7932C11.5791 7.50031 11.4003 7.42846 11.2566 7.28476C11.1129 7.14107 11.041 6.96053 11.041 6.74314ZM2.91602 9.66693V3.66685C2.91602 3.45435 2.98843 3.27625 3.13327 3.13256C3.2781 2.98885 3.45756 2.91699 3.67166 2.91699H8.20541C8.41951 2.91699 8.59841 2.98887 8.7421 3.13264C8.88579 3.27639 8.95764 3.45451 8.95764 3.66701V9.6671C8.95764 9.8796 8.88523 10.0577 8.74041 10.2014C8.59558 10.3451 8.41611 10.417 8.20202 10.417H3.66824C3.45416 10.417 3.27527 10.3451 3.13158 10.2013C2.98787 10.0576 2.91602 9.87943 2.91602 9.66693ZM11.041 16.3336V10.3335C11.041 10.121 11.1134 9.94292 11.2582 9.79922C11.4031 9.65551 11.5825 9.58366 11.7966 9.58366H16.3304C16.5445 9.58366 16.7234 9.65554 16.8671 9.7993C17.0108 9.94306 17.0826 10.1212 17.0826 10.3337V16.3338C17.0826 16.5463 17.0102 16.7244 16.8654 16.8681C16.7206 17.0118 16.5411 17.0836 16.327 17.0836H11.7932C11.5791 17.0836 11.4003 17.0117 11.2566 16.868C11.1129 16.7242 11.041 16.5461 11.041 16.3336ZM2.91602 16.3265V13.2575C2.91602 13.0401 2.98843 12.8595 3.13327 12.7158C3.2781 12.5722 3.45756 12.5003 3.67166 12.5003H8.20541C8.41951 12.5003 8.59841 12.5722 8.7421 12.7158C8.88579 12.8595 8.95764 13.0401 8.95764 13.2575V16.3265C8.95764 16.5438 8.88523 16.7244 8.74041 16.8681C8.59558 17.0118 8.41611 17.0836 8.20202 17.0836H3.66824C3.45416 17.0836 3.27527 17.0118 3.13158 16.8681C2.98787 16.7244 2.91602 16.5438 2.91602 16.3265ZM4.16599 9.16697H7.70768V4.16697H4.16599V9.16697ZM12.291 15.8336H15.8327V10.8336H12.291V15.8336ZM12.291 6.25033H15.8327V4.16697H12.291V6.25033ZM4.16599 15.8336H7.70768V13.7503H4.16599V15.8336Z"
        fill={color}
      />
    </svg>
  );
};

export default DashboardIcon;
