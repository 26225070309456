/* eslint-disable react-hooks/rules-of-hooks */
export const validateBasicForm = (formData, setFormData) => {
	const basicDetails = formData.overview?.basicDetails || {};
	const errors = {};

	// Basic Details Validation
	if (!basicDetails?.hotelName.trim()) {
		errors.hotelName = 'Hotel name is required';
	}

	const text = basicDetails?.hotelDescription;
	const words = text?.trim().split(/\s+/); // Regular expression to handle multiple spaces
	const wordCount = words.length;

	if (!basicDetails?.hotelDescription || wordCount > 300) {
		errors.hotelDescription = 'Write at least 100 words';
	}

	if (
		!basicDetails?.hotelWebsite ||
		!/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,63})(\/[\w .-]*)*\/?$/i.test(
			basicDetails?.hotelWebsite
		)
	) {
		errors.hotelWebsite =
			'A valid hotel website URL with .com domain is required';
	}

	if (!basicDetails?.hotelRating) {
		errors.hotelRating = 'Hotel rating is required';
	}

	if (!basicDetails?.selectedTags) {
		basicDetails.selectedTags = {};
	}

	if (
		basicDetails?.selectedTags.types &&
		(basicDetails?.selectedTags.types.length < 1 ||
			basicDetails?.selectedTags.types.length > 5)
	) {
		errors.hotelTypes = 'Select between 1 and 5 hotel types';
	}

	if (
		basicDetails?.selectedTags.views &&
		(basicDetails?.selectedTags.views.length < 1 ||
			basicDetails?.selectedTags.views.length > 5)
	) {
		errors.hotelViews = 'Select between 1 and 5 hotel views';
	}

	if (basicDetails?.selectedTags.styles) {
		const totalStyles = Object.values(
			basicDetails?.selectedTags.styles
		).flat().length;
		if (totalStyles < 1 || totalStyles > 5) {
			errors.hotelStyles = 'Select between 1 and 5 hotel styles';
		}
	}

	setFormData((prevData) => ({
		...prevData,
		overview: {
			...prevData.overview,
			basicDetails: {
				...prevData.overview.basicDetails,
				errors,
			},
		},
	}));

	return Object.keys(errors).length === 0;
};

export const validatePriceForm = (formData, setFormData) => {
	const pricing = formData.pricing || {};
	const errors = {};

	// Validate base price
	if (!pricing.basePrice) {
		errors.basePrice = 'Price is required';
	}

	// Calculate the total number of selected months
	const totalSelectedMonths =
		(pricing.highDemandMonths?.length || 0) +
		(pricing.midDemandMonths?.length || 0) +
		(pricing.lowDemandMonths?.length || 0);

	if (pricing.mentionSeasons && totalSelectedMonths !== 12) {
		errors.monthsTotal =
			'A total of 12 months must be selected across all demand types';
	}

	// Ensure no overlap between high, mid, and low demand months
	const overlapMonths = [
		...(pricing.highDemandMonths || []),
		...(pricing.midDemandMonths || []),
		...(pricing.lowDemandMonths || []),
	];

	const monthCounts = overlapMonths.reduce((acc, month) => {
		acc[month] = (acc[month] || 0) + 1;
		return acc;
	}, {});

	const overlappingMonths = Object.keys(monthCounts).filter(
		(month) => monthCounts[month] > 1
	);
	if (overlappingMonths.length > 0) {
		errors.overlap =
			'A month cannot be selected in multiple demand categories';
	}

	// Set errors in form data
	setFormData((prevData) => ({
		...prevData,
		pricing: {
			...prevData.pricing,
			errors,
		},
	}));

	return Object.keys(errors).length === 0;
};

export const validateAdditionForm = (formData, setFormData) => {
	const additionalDetails =
		formData.overview?.additionalDetails || {};
	const errors = {};

	// Check if eventType is an array and if at least one event type is selected
	if (
		!additionalDetails.selectedEventTypes ||
		additionalDetails.selectedEventTypes.length === 0
	) {
		errors.selectedEventTypes = 'Select at least one event type';
	}

	// Validation for other fields (example: trade license file)
	if (!additionalDetails.tradeLicenseFile) {
		errors.tradeLicenseFile = 'Trade license file is required';
	}

	// Validation for hotel built year
	if (!additionalDetails.hotelBuiltYear) {
		errors.hotelBuiltYear = 'Hotel built year is required';
	}

	// Set the form data with errors
	setFormData((prevData) => ({
		...prevData,
		overview: {
			...prevData.overview,
			additionalDetails: {
				...prevData.overview.additionalDetails,
				errors,
			},
		},
	}));

	// Return true if there are no validation errors
	return Object.keys(errors).length === 0;
};

export const validateLocationForm = (formData, setFormData) => {
	const locationDetails = formData.overview?.locationDetails || {};
	const errors = {};

	if (!locationDetails.addressLine1) {
		errors.addressLine1 = 'Address Line 1 is required';
	}
	if (!locationDetails.city) {
		errors.city = 'City is required';
	}
	if (!locationDetails.area) {
		errors.area = 'Area is required';
	}
	// if (!locationDetails?.poBox) {
	//   errors.poBox = "PO Box is required";
	// }
	if (!locationDetails?.mapLink) {
		errors.mapLink = 'Map Link is required';
	}

	setFormData((prevData) => ({
		...prevData,
		overview: {
			...prevData.overview,
			locationDetails: {
				...prevData.overview.locationDetails,
				errors,
			},
		},
	}));

	return Object.keys(errors).length === 0;
};

export const validateAccoumentionForm = (formData, setFormData) => {
	const accommodations = formData?.accommodation?.accommodations || 0; // Default to 0 if undefined
	const errors = {};

	if (accommodations <= 0) {
		errors.accommodations =
			'Accommodations are required and must be greater than 0';
	}

	setFormData((prevData) => ({
		...prevData,
		accommodation: {
			...prevData.accommodation,
			errors,
		},
	}));

	return Object.keys(errors).length === 0;
};

// Validate Room Features
export const validateRoomFeatures = (formData, setFormData) => {
	const amenities = formData?.amenities || {};
	const roomFeatures = amenities?.roomFeatures || {};
	const errors = {};

	// Check if at least one room feature is selected (i.e., at least one value is true)
	const hasSelectedFeature = Object.values(roomFeatures).some(
		(isEnabled) => isEnabled
	);

	if (!hasSelectedFeature) {
		errors.roomFeatures =
			'At least one room feature must be selected.';
	}

	// Set errors in formData state
	setFormData((prevData) => ({
		...prevData,
		amenities: {
			...prevData.amenities,
			errors,
		},
	}));

	// Return true if there are no errors, otherwise return false
	return Object.keys(errors).length === 0;
};

// Validate Business Features
export const validateBusinessFeatures = (formData, setFormData) => {
	const amenities = formData?.amenities || {};
	const businessFeatures = amenities?.businessFeatures || {};
	const errors = {};

	// Check if at least one room feature is selected (i.e., at least one value is true)
	const hasSelectedFeature = Object.values(businessFeatures).some(
		(isEnabled) => isEnabled
	);

	if (!hasSelectedFeature) {
		errors.businessFeatures =
			'At least one business features must be selected.';
	}

	// Set errors in formData state
	setFormData((prevData) => ({
		...prevData,
		amenities: {
			...prevData.amenities,
			errors,
		},
	}));

	// Return true if there are no errors, otherwise return false
	return Object.keys(errors).length === 0;
};

// Validate Recreational Features
export const validateRecreationalFeatures = (
	formData,
	setFormData
) => {
	const amenities = formData?.amenities || {};
	const recreationalFeatures = amenities?.recreationalFeatures || {};
	const errors = {};

	// Check if at least one room feature is selected (i.e., at least one value is true)
	const hasSelectedFeature = Object.values(recreationalFeatures).some(
		(isEnabled) => isEnabled
	);

	if (!hasSelectedFeature) {
		errors.recreationalFeatures =
			'At least one business features must be selected.';
	}

	// // Set errors in formData state
	// setFormData((prevData) => ({
	//   ...prevData,
	//   amenities: {
	//     ...prevData.amenities,
	//     errors,
	//   },
	// }));

	// Return true if there are no errors, otherwise return false
	return Object.keys(errors).length === 0;
};

// export const validateAmenitiesForm = (formData, setFormData) => {
//   const amenities = formData?.amenities || {};
//   const roomFeatures = amenities?.roomFeatures || {};
//   const errors = {};

//   // Check if at least one room feature is selected (i.e., at least one value is true)
//   const hasSelectedFeature = Object.values(roomFeatures).some((isEnabled) => isEnabled);

//   if (!hasSelectedFeature) {
//     errors.roomFeatures = "At least one room feature must be selected.";
//   }

//   // Set errors in formData state
//   setFormData((prevData) => ({
//     ...prevData,
//     amenities: {
//       ...prevData.amenities,
//       errors,
//     },
//   }));

//   // Return true if there are no errors, otherwise return false
//   return Object.keys(errors).length === 0;
// };

export const validatePhotoVideoForm = (formData, setFormData) => {
	const photoVideo = formData?.photoVideo || {
		images: [],
		videos: [],
		videoLinks: [],
	}; // Default to empty arrays if undefined
	const errors = {};

	if (!photoVideo.images.length) {
		errors.images = 'At least one image is required';
	}
	// if (!photoVideo.videos.length && !photoVideo.videoLinks.length) {
	//     errors.videos = 'At least one video or video link is required';
	// }

	setFormData((prevData) => ({
		...prevData,
		photoVideo: {
			...prevData.photoVideo,
			errors,
		},
	}));
	return Object.keys(errors).length === 0;
};
