import React, { useState, useContext, useCallback } from "react";
import { HotelContext } from "../../context/HotelContext";
import Loader from "../shared/common/loader";
import AWS from "aws-sdk";
import { toast } from "react-toastify";
import { Divider } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "../venu-listing-form/StrictModeDroppable";
// Configure AWS SDK
const S3_BUCKET = "st-findmyvenue";
const REGION = "ap-south-1";

AWS.config.update({
  accessKeyId: "AKIATCKAPHDJJECWAMJF",
  secretAccessKey: "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",
  region: REGION,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const UploadPhotosVideos = () => {
  const { formData, setFormData } = useContext(HotelContext);
  const [videoLink, setVideoLink] = useState("");
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [coverPhoto, setCoverPhoto] = useState(
    formData.photoVideo?.coverPhoto || ""
  );

  const handleFileUpload = useCallback(
    (file, type) => {
      setUploading(true);

      const params = {
        ACL: "public-read",
        Body: file,
        Bucket: S3_BUCKET,
        Key: `${type}/${file.name}`,
      };

      myBucket
        .upload(params, (err, data) => {
          if (err) {
            console.error("Error uploading file:", err.message);
            setUploading(false);
            setProgress(0);
            return;
          }
          // Clear errors on successful upload
          setFormData((prevFormData) => ({
            ...prevFormData,
            photoVideo: {
              ...prevFormData.photoVideo,
              errors: {}, // Resetting errors here
              [type]: [
                ...(prevFormData.photoVideo?.[type] || []),
                data.Location,
              ],
            },
          }));
          setUploading(false);
          setProgress(0);
        })
        .on("httpUploadProgress", (evt) => {
          setProgress(Math.round((evt.loaded / evt.total) * 100));
        });
    },
    [setFormData]
  );
  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const allowedTypes = ["image/jpeg", "image/png"];

    // Reset errors before processing uploads
    setFormData((prevFormData) => ({
      ...prevFormData,
      photoVideo: {
        ...prevFormData.photoVideo,
        errors: {}, // Resetting errors at the start of the upload process
      },
    }));

    files.forEach(async (file) => {
      if (file) {
        if (file.size > 5 * 1024 * 1024) {
          toast.error("File size should not exceed 5 MB.");
          return;
        }
        if (!allowedTypes.includes(file.type)) {
          toast.error("Only PNG and JPG files are allowed.");
          return;
        }
        await handleFileUpload(file, "images");
      }
    });
  };

  const handleVideoUpload = (event) => {
    const files = Array.from(event.target.files);
    const allowedTypes = ["video/mp4"];

    files.forEach((file) => {
      if (file) {
        if (!allowedTypes.includes(file.type)) {
          toast.error("Please upload a valid MP4 video file.");
          return;
        }
        if (file.size > 150 * 1024 * 1024) {
          toast.error("File size should not exceed 150 MB.");
          return;
        }
        handleFileUpload(file, "videos");
      }
    });
  };

  const handleFileDrop = (event, type) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    if (type === "images") {
      handleImageUpload({ target: { files } });
    } else if (type === "videos") {
      handleVideoUpload({ target: { files } });
    }
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  // set cover photo
  const handleCoverPhotoSelection = (image, index) => {
    // Allow only the first image to be set as the cover photo
    if (index === 0) {
      setCoverPhoto(image);
      setFormData((prevFormData) => ({
        ...prevFormData,
        photoVideo: {
          ...prevFormData.photoVideo,
          coverPhoto: image,
        },
      }));
    } else {
      toast.error("Only the first image can be set as the cover photo.");
    }
  };

  const handleVideoLinkUpload = async () => {
    const urlDetails = extractVideoDetails(videoLink);
    if (videoLink && isValidVideoLink(videoLink) && urlDetails) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        photoVideo: {
          ...prevFormData.photoVideo,
          videoLinks: [
            ...(prevFormData.photoVideo?.videoLinks || []),
            videoLink,
          ],
        },
      }));
      setVideoLink("");
    } else {
      toast.error("Please enter a valid YouTube link.");
    }
  };

  const extractVideoDetails = (url) => {
    let match;
    const videoDetails = {
      id: "",
      title: "",
      url: "",
      thumbnail: "",
    };

    const youtubePattern =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    if ((match = url.match(youtubePattern))) {
      videoDetails.id = match[1];
      videoDetails.url = `https://www.youtube.com/watch?v=${videoDetails?.id}`;
      videoDetails.thumbnail = `https://img.youtube.com/vi/${videoDetails?.id}/0.jpg`;
      videoDetails.title = "YouTube Video";
      return videoDetails;
    }
    return null;
  };

  const isValidVideoLink = (url) => {
    const canvaPattern = /(?:https?:\/\/)?(?:www\.)?(canva\.com\/[\w-]+)/;
    const vimeoPattern = /(?:https?:\/\/)?(?:www\.)?(vimeo\.com\/\d+)/;
    const youtubePattern =
      /(?:https?:\/\/)?(?:www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[\w-]+/;

    return (
      canvaPattern.test(url) ||
      vimeoPattern.test(url) ||
      youtubePattern.test(url)
    );
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(formData.photoVideo.images);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFormData((prevFormData) => ({
      ...prevFormData,
      photoVideo: {
        ...prevFormData.photoVideo,
        images: items,
      },
    }));
  };
  const removeImage = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      photoVideo: {
        ...prevFormData.photoVideo,
        images: (prevFormData.photoVideo?.images || []).filter(
          (_, i) => i !== index
        ),
      },
    }));
  };

  const removeVideo = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      photoVideo: {
        ...prevFormData.photoVideo,
        videos: (prevFormData.photoVideo?.videos || []).filter(
          (_, i) => i !== index
        ),
      },
    }));
  };

  const removeVideoLink = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      photoVideo: {
        ...prevFormData.photoVideo,
        videoLinks: (prevFormData.photoVideo?.videoLinks || []).filter(
          (_, i) => i !== index
        ),
      },
    }));
  };

  const errors = formData.photoVideo.errors || {};
  return (
    <div className="bg-white rounded-lg p-6 w-full mx-auto">
      <h2 className="text-2xl font-semibold text-neutralBlack mb-4">
        Upload Photos & Videos
      </h2>
      <p className="text-sm text-gray-500 mb-6">
        Upload up to 30 photos & videos
      </p>

      <div className="mb-6">
        <label className="block text-secondary text-sm mb-2">
          Image Upload <span className="text-red-500 ml-1 text-[15px]">*</span>
        </label>
        <p className="text-sm text-offGray mb-4">
          Make sure the size of the photos should not be more than 5MB (PNG,
          JPG). Select a photo so you can edit it, or drag it to change the
          order,
        </p>

        <DragDropContext onDragEnd={handleOnDragEnd}>
          <StrictModeDroppable droppableId="characters" direction="horizontal">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="flex flex-wrap items-end gap-4 mb-4"
                onDrop={(e) => handleFileDrop(e, "images")}
                onDragOver={handleDragOver}
              >
                {(formData.photoVideo?.images || []).map((image, index) => (
                  <Draggable
                    key={index}
                    draggableId={`image-${index}`}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`relative ${
                          index === 0
                            ? "w-[160px] h-[160px]" // Larger size for the first image
                            : "w-[100px] h-[100px]"
                        }`}
                      >
                        <img
                          src={image}
                          alt={`uploaded-${index}`}
                          className="w-full h-full object-cover rounded-xl"
                          onClick={() => handleCoverPhotoSelection(image)}
                        />
                        <div className="flex items-center justify-start ml-2">
                          <span className="absolute bottom-3  bg-black/65 text-white text-[14px] px-2  rounded-full">
                            {index + 1}
                          </span>
                        </div>

                        {index === 0 && (
                          <div className="flex items-center justify-center">
                            <span className="absolute bottom-3 mx-auto bg-black/65 text-white text-[14px] px-2 py-1 rounded-2xl">
                              Cover
                            </span>
                          </div>
                        )}
                        <button
                          className="absolute top-1 right-1 bg-[#BDBDBD] rounded-[4px] p-1 text-white w-[24px] h-[24px] flex items-center justify-center"
                          onClick={() => removeImage(index)}
                        >
                          <img src="/close.svg" alt="close" />
                        </button>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                <div className="relative w-[100px] h-[100px]">
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    className="hidden"
                    onChange={handleImageUpload}
                    id="imageUpload"
                    multiple
                  />
                  <label
                    htmlFor="imageUpload"
                    className="absolute inset-0 flex justify-center items-center cursor-pointer bg-[#FFF5F5] border border-dashed border-primary rounded-lg p-4"
                  >
                    <img src="/plus-primary.svg" alt="primary" />
                  </label>
                </div>
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>

        {errors.images && (
          <p className="text-red-500 text-base pt-1">{errors.images}</p>
        )}
      </div>

      <div className="mb-6">
        <label className="block text-secondary text-sm mb-2">
          Video Upload
        </label>
        <p className="text-sm text-offGray mb-2">
          Make sure the size of the Videos should not be more than 150MB. (MP4)
        </p>
        <div
          className="flex flex-wrap gap-4 mb-4"
          onDrop={(e) => handleFileDrop(e, "videos")}
          onDragOver={handleDragOver}
        >
          {(formData.photoVideo?.videos || []).map((video, index) => (
            <div key={index} className="relative w-24 h-24">
              <video
                src={video}
                className="w-full h-full object-cover rounded-lg"
                controls={false}
              />
              <button
                className="absolute top-1 right-1 bg-[#BDBDBD] rounded-[4px] p-1 text-white w-[24px] h-[24px] flex items-center justify-center"
                onClick={() => removeVideo(index)}
              >
                <img src="/close.svg" alt="close" />
              </button>
            </div>
          ))}
          <div className="relative border border-dashed border-red-500 rounded-lg p-4 flex justify-center items-center cursor-pointer w-24 h-24 bg-[#FFF5F5]">
            <input
              type="file"
              accept="video/*"
              className="hidden"
              onChange={handleVideoUpload}
              id="videoUpload"
            />
            <label
              htmlFor="videoUpload"
              className="absolute inset-0 flex flex-col items-center justify-center cursor-pointer"
            >
              <img src="/plus-primary.svg" alt="primary" />
            </label>
          </div>
        </div>
      </div>

      <Divider className="text-secondary text-sm">or</Divider>

      <div className="mb-10">
        <label className="block text-secondary text-sm mb-2">Video Link</label>
        <div className="flex items-center border border-offGray rounded-lg overflow-hidden w-full max-w-sm">
          <input
            value={videoLink}
            onChange={(e) => setVideoLink(e.target.value)}
            type="text"
            className="flex-grow p-2 border-none focus:outline-none text-sm"
            placeholder="Paste video link"
          />
          <button
            onClick={handleVideoLinkUpload}
            className="p-2 text-primary font-medium text-base underline focus:outline-none"
          >
            Upload
          </button>
        </div>
        <div className="flex flex-wrap gap-4 mt-5">
          {(formData.photoVideo?.videoLinks || []).map((link, index) => {
            const video = extractVideoDetails(link);
            return (
              <div
                key={index}
                className="relative border border-borderShade rounded-lg flex space-x-2 w-[329.5px] overflow-x-auto"
              >
                <div className="w-[150px] h-full">
                  <img
                    src={video?.thumbnail}
                    alt="Video Thumbnail"
                    className="w-[150px] h-full object-cover rounded-l-lg"
                  />
                </div>
                <div className="flex flex-col space-y-2 p-2 w-[174px]">
                  <h3 className="text-sm text-darkGray">{video?.title}</h3>
                  <p className="text-offGray text-sm line-clamp-2 text-ellipsis">
                    {video?.url}
                  </p>
                </div>
                <button
                  className="absolute top-2 right-2 bg-transparent p-1"
                  onClick={() => removeVideoLink(index)}
                >
                  <img src="/close-gray.svg" alt="close" />
                </button>
              </div>
            );
          })}
        </div>
      </div>

      {/* {uploading && <Loader progress={progress} />} */}
    </div>
  );
};

export default UploadPhotosVideos;
