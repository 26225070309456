// GoogleMapComponent.jsx
import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";
import MapContainer from "../hostel_listing_form/overview-form/googleMap";
import extractMapCoordinates from "../../utils/extractMapCoordinates";
import { useQuery } from "@tanstack/react-query";
import { getVendorHotel } from "../../api/hotel-api";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: 0,
  lng: 0,
};

const GoogleMapComponent = ({ data }) => {
  const vendorId = localStorage?.getItem("vendorId");

  const [mapLocation, setMapLocation] = useState({
    lat: 0,
    lng: 0,
  });
  const [locationDetails, setLocationDetails] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    area: "",
    lat: 0,
    lng: 0,
    poBox: "",
    country: "",
    mapLink: "",
  });

  const { data: hotel, isPending } = useQuery({
    queryKey: ["vendor-hotel"],
    queryFn: async () => {
      const res = await getVendorHotel(vendorId);
      setLocationDetails({
        ...locationDetails,
        addressLine1: res?.overview?.locationDetails?.addressLine1,
        addressLine2: res?.overview?.locationDetails?.addressLine2,
        city: res?.overview?.locationDetails?.city,
        area: res?.overview?.locationDetails?.area,
        poBox: res?.overview?.locationDetails?.poBox,
        country: res?.overview?.locationDetails?.country,
        mapLink: res?.overview?.locationDetails?.mapLink,
        lat: res?.overview?.locationDetails?.lat,
        lng: res?.overview?.locationDetails?.lng,
      });
      return res;
    },
  });

  useEffect(() => {
    if (!isPending) {
      if (locationDetails?.lat && locationDetails?.lng) {
        setMapLocation({
          lat: locationDetails?.lat,
          lng: locationDetails?.lng,
        });
      } else if (locationDetails?.mapLink && locationDetails?.mapLink !== "") {
        const coords = extractMapCoordinates(locationDetails?.mapLink);
        if (coords) {
          setMapLocation(coords);
        } else {
          console.log("Invalid map link");
        }
      } else {
        if (
          locationDetails?.city &&
          locationDetails?.area &&
          locationDetails?.country &&
          typeof window !== "undefined"
        ) {
          const address = `${locationDetails?.city}, ${locationDetails?.area}, ${locationDetails?.country}`;
          geocodeAddress(address);
        }
      }
    }
  }, [locationDetails, isPending]);

  const geocodeAddress = (address) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === "OK" && results[0]) {
        const location = results[0]?.geometry?.location;
        const coords = {
          lat: location?.lat(),
          lng: location?.lng(),
        };
        setMapLocation(coords);
      } else {
        console.log(
          "Geocode was not successful for the following reason: " + status
        );
      }
    });
  };

  return (
    <div className="py-5 lg:w-3/4 w-full border-t mx-auto">
      <h2 className="text-2xl font-bold mb-4">Where We Are Located</h2>
      <MapContainer
        isHotel={false}
        isVenue={true}
        lat={mapLocation?.lat}
        lng={mapLocation?.lng}
        mapLocation={mapLocation}
        setMapLocation={setMapLocation}
        draggable={false}
        showHeader={false}
      />
    </div>
  );
};

export default GoogleMapComponent;
