import React from "react";
import "./Popup.css";
import fileloader from "../../assets/pics/Fileloader.svg";
import canel from "../../assets/pics/cancel 1.svg";
const Popup = ({ onClose }) => {
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <img src={fileloader} alt="file loader" />
          </div>
          <div>
            <img
              style={{ cursor: "pointer" }}
              src={canel}
              alt="cancel"
              onClick={onClose}
            />
          </div>
        </div>
        <div>
          <h1 className="font-bold text-xl mt-3 mb-1 ml-0 text-left">
            Documents to Keep In Handy!
          </h1>
          <p className="text-left">1. Trade License</p>
          <p className="text-left">2. Floor Plans</p>
          <p className="text-left">3. Photos & Videos</p>
          <p className="text-left">4. Brochures</p>
          <p className="text-left">5. Menu</p>
          <p className="text-left">6. Awards</p>
        </div>
        <button
          style={{ width: "300px" }}
          onClick={onClose}
          className="mt-3 bg-red-500 text-white py-2 px-4 rounded"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default Popup;
