import React, { useState } from "react";
import img1 from "../../assets/pics/Step-1.png";
import img2 from "../../assets/pics/Step-2.png";
import img3 from "../../assets/pics/Step-3.png";
import rope from "../../assets/pics/workRope.svg";
import { BsArrowRight } from "react-icons/bs";

const HowWorks = () => {
  const [showVideo, setShowVideo] = useState(false);
  const data = [
    {
      img: img1,
      step: "Step 1",
      name: "Sign-up",
      des: "Select a suitable subscription plan for your venue type",
    },
    {
      img: img2,
      step: "Step 2",
      name: "List Your Venue",
      des: "Add detailed information about your venues, including descriptions and photos",
    },
    {
      img: img3,
      step: "Step 3",
      name: "Get enquires",
      des: "Communicate directly with potential customers and event planners to receive enquiries",
    },
  ];

  return (
    <div className="flex flex-col items-center  sm:px-24 px-4">
      <div className="flex flex-col items-center">
        <h1 className="text-2xl font-semibold">How it works</h1>
        <p className="mt-2 w-full text-center">
          Connect with potential customers in three simple steps
        </p>
      </div>
      <div className="relative flex justify-center ">
        <div className="grid md:grid-cols-3 grid-cols-1  gap-8 mt-8 md:w-[95%] w-full ">
          {data.map((e, i) => (
            <div key={i} className="flex flex-col items-center gap-6">
              <img src={e.img} alt="" className="w-60" />
              <h1 className="text-[#7B7B7B] font-semibold">{e.step}</h1>
              <h1 className="text-[#252525] text-center font-semibold">
                {e.name}
              </h1>
              <p className="text-[#5B5B5B] text-center z-30">{e.des}</p>
            </div>
          ))}
        </div>
        <img
          src={rope}
          className="lg:block hidden w-full  absolute top-0 left-5"
          alt=""
        />
      </div>
      <div className="flex flex-col items-center gap-3 my-10">
        <h1 className="text-lg text-center">Learn more about our process</h1>
        {!showVideo ? (
          <button
            onClick={() => setShowVideo(true)}
            className="text-white flex bg-black py-3 items-center justify-center rounded-lg gap-2 w-[170px]"
          >
            Watch Video <BsArrowRight />
          </button>
        ) : (
          <video
            src="https://st-findmyvenue.s3.ap-south-1.amazonaws.com/FindMyVenue+Intro+-+High+Res+.mp4"
            controls
            autoPlay
            className="w-full max-w-xl lg:max-w-5xl rounded-lg"
          />
        )}
      </div>
    </div>
  );
};

export default HowWorks;
